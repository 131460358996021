import AttachFile2 from '@common/AttachFile2';
import MenuTooltip from '@common/MenuTooltip';
import PageLayout from '@common/PageLayout';
import RaisedButton from '@common/RaisedButton';
import Store from '@data/Store';
import {
  Button as ButtonUI,
  Chip,
  Divider,
  InputBase,
  Select as MuiSelect,
  Slider,
  Typography,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import randomcolor from 'randomcolor';
import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import DownloadFiles from '../../util/DownloadFiles';
import { NewTask } from '../taskmanagement/Calendar/NewTask';
import TaskFormTarefa from './../task/TaskFormTarefa';

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { observer } from 'mobx-react-lite';
import 'react-alice-carousel/lib/alice-carousel.css';
import Sla from './Sla';

import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import Header from '@common/Header';
import Progress from '@common/Progress';
import Tag from '@common/Tag';
import KnowledgeBase from '@images/svg-icon/KnowledgeBase';
import { Avatar, Card, Grid, Menu, MenuItem } from '@material-ui/core';
import classnames from 'classnames';
import { concatDateHour, moment } from '../../util/dateTime';
import NumberFormat from 'react-number-format';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Tooltip } from 'react-tippy';
import TicketManagement from '../TicketManagement';
import AssessmentTerminal from '../assessment/AssessmentTerminal';
import GuacamoleView from '../guaca/index';
import SolutionContainerTicket from '../solution/SolutionContainerTicket';
import TicketComment from './../tickets/TicketComment';
import ControllerTicket from './ControllerTicket';
import DescTicket from './DescriptionTicket';
import './Detail.css';
import DialogFormEdit from './DialogFormEdit';
import Historic from './Historic';
import HistoricSub from './HistoricSub';
//pointer-events: none
const crypto = require('crypto');
// --------------------------icons----------------------------------
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DevicesIcon from '@material-ui/icons/Devices';
import EditIcon from '@material-ui/icons/Edit';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import PersonIcon from '@material-ui/icons/Person';
// ------------------------------------------------------------
import {
  MuiThemeProvider,
  createTheme,
  makeStyles,
  styled,
  withStyles,
} from '@material-ui/core/styles';

import { ArrowDownward } from '@material-ui/icons';
import { applyTimezoneOnHTML } from '../../common/Timezone';
import { translateFromPtBR } from '../../common/languages/Dictionary';
import CIPage from './CIPage';
import RelationshipTicketsPage from './RelationshipTicketsPage';

const { language } = Store;

const theme = createTheme({
  palette: {
    primary: { main: '#f0b002' },
  },
});

const type_ = (ticket, language) => {
  let st;
  // if (
  //   sessionStorage.getItem('sensr_lang') == 'PT_BR' ||
  //   sessionStorage.getItem('sensr_lang') == null
  // ) {
  //   if (ticket.type_tickets == 'Incident') {
  //     st = language.TICKET_TYPES.INCIDENT;
  //   } else if (ticket.type_tickets == 'Problem') {
  //     st = language.TICKET_TYPES.PROBLEM;
  //   } else if (ticket.type_tickets == 'Solicitation') {
  //     st = language.TICKET_TYPES.SOLICITATION;
  //   } else if (ticket.type_tickets == 'Event') {
  //     st = language.TICKET_TYPES.EVENT;
  //   } else if(ticket.type_tickets == 'Crisis') {
  //     st = language.TICKET_TYPES.CRISIS;
  //   } else {
  //     st = language.TICKET_TYPES.CRITICAL_INCIDENT;
  //   }
  // } else {
  //   st = ticket.type_tickets;
  // }

  if (ticket.type_tickets == 'Incident') {
    st = language.TICKET_TYPES.INCIDENT;
  } else if (ticket.type_tickets == 'Problem') {
    st = language.TICKET_TYPES.PROBLEM;
  } else if (ticket.type_tickets == 'Solicitation') {
    st = language.TICKET_TYPES.SOLICITATION;
  } else if (ticket.type_tickets == 'Event') {
    st = language.TICKET_TYPES.EVENT;
  } else if(ticket.type_tickets == 'Crisis') {
    if (ticket.documented_crisis) {
      st = language.TICKET_TYPES.DOCUMENTED_CRISIS; 
    } else {
      st = language.TICKET_TYPES.CRISIS;
    } 
  } else {
    st = language.TICKET_TYPES.CRITICAL_INCIDENT;
  }

  return st;
};

const taskLabelId = (id, type) => {
  const labels = {
    Incident: 'INCTASK',
    Problem: 'PRBTASK',
    Solicitation: 'SOLTASK',
    Event: 'EVETASK',
    Crisis: 'CRITASK'
  };
  return `${labels[type]}${id}`;
};

const typesla = (cond, status, language) => {
  const isInProgressOrReopened = ['In Progress', 'Reopened'].includes(status);

  const statusMap = {
    start: isInProgressOrReopened ? language.TYPE_SLA_STATUS_IN_PROGRESS : language.TYPE_SLA_STATUS_START,
    pause: language.TYPE_SLA_STATUS_PAUSE,
    restart: language.TYPE_SLA_STATUS_IN_PROGRESS,
    stop: language.TYPE_SLA_STATUS_CONCLUDED,
    cancel: language.TYPE_SLA_STATUS_CANCELED
  };

  return statusMap[cond] || cond;
};

function decryp(aMsg) {
  var aSecret = 'Orch3str0!@forever!';
  try {
    aMsg = aMsg.replace(/\s/g, '');
    let decipher, tRet;
    decipher = crypto.createDecipher('aes-256-cbc', aSecret);

    tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');

    tRet += decipher.final('utf8');
    return tRet;
  } catch (e) {
    return {};
  }
}

function nameOccupationUser(users, occupations, id) {
  try {
    let user = users.filter((user) => user.id_user == id);
    let userOccupation = occupations.filter(
      (occ) => occ.id_occupation == user[0].occupation
    );

    return userOccupation[0].name;
  } catch (e) {
    return '';
  }
}

const createRandomToken = (text) => {
  const aSecret = 'Orch3str0!@forever!';

  try {
    const crypto = require('crypto');

    let cipher, tRet;

    cipher = crypto.createCipher('aes-256-cbc', aSecret);
    tRet = cipher.update(text.toString(), 'utf8', 'base64');
    tRet += cipher.final('base64');
    return tRet;
  } catch (e) {
    return {};
  }
};

const GC = ({ items, labelProps = {}, valueProps = {} }) => (
  <div className="grid-container">
    {(items || []).map((e, i) => (
      <Fragment>
        <div
          {...labelProps}
          {...e.labelProps}
          className={classnames(
            'grid-columns label',
            labelProps.className,
            (e.labelProps || {}).className
          )}
        >
          {e.label}
        </div>
        <div
          {...valueProps}
          {...e.valueProps}
          className={classnames(
            'grid-columns value',
            valueProps.className,
            (e.valueProps || {}).className
          )}
        >
          {e.value}
        </div>
      </Fragment>
    ))}
  </div>
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    border: '1px solid #DEDEDE',
    height: '100%',
  },
  textSubTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#a2a2a2',
    marginBottom: 25,
  },
  textTitle: {
    color: '#33ACA0',
    fontSize: 14,
    fontWeight: 'bold',
  },
  description: {
    color: '#707070',
    fontSize: 12,
  },
  spacing: {
    padding: 5,
  },
  icon: {
    color: (props) => props.color || '#019FC2',
  },
  text: {
    color: '#707070',
    textAlign: 'justify',
    marginLeft: 45,
  },
  fullHeightCard: {
    height: '100%',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemTask: {
    padding: 13,
    border: `1px solid ${randomcolor()}`,
  },
  textDescription: {
    fontWeight: 700,
    color: 'var(--success)',
    fontSize: 16,
  },
  subtitle_solicitation: {
    color: '#019FC2',
    fontWeight: 'bold',
  },
  button: {
    color: '#FFF',
    background: '#58A9A0',
    padding: '5px 73px',
    float: 'right',
    '&:hover:': {
      background: '#58A9A0',
    },
  },
  atend: {
    display: 'flex',
  },
}));

const CustomSelect = styled(MuiSelect)({
  '& .MuiSelect-select': {
    borderColor: '#039FC0',
    height: 36,
  },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    background: '#FFF',
    border: '1px solid #019FC2',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#019FC2',
      background: '#FFF',
    },
  },
}))(InputBase);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: '40%',
  },
  colorPrimary: {
    backgroundColor: '#e9ecef',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#34ACA1',
  },
}))(LinearProgress);

const T1 = ({
  action,
  APIEndpoints,
  language,
  ticket,
  onNotesTicket,
  statusTicket,
  url,
  type_tickets,
  user,
  toggleAlert,
  setLocalFiles,
  answer,
  params,
  isReadonly,
  setPage,
  hasTasksPending
}) => {
  let [formRef, setFormRef] = useState(null);
  let [valiationOptions, setValiationOptions] = useState([]);
  let [submitTechEvaluation, setsubmitTechEvaluation] = useState([]);
  let [pResolution, setPResolution] = useState(false);
  let [pResolution100, setPResolution100] = useState(false);
  let [general_params, setGeneral_params] = useState([]);
  let observation = ticket.user_sponsor[0];
  let [formPrimaryRef, setFormPrimaryRef] = useState(null);
  const [n1Options, setN1Options] = useState([])
  const [n2Options, setN2Options] = useState([])
  const [n3Options, setN3Options] = useState([])
  const [n4Options, setN4Options] = useState([])

  const [selectedN1, setSelectedN1] = useState(0)
  const [selectedN2, setSelectedN2] = useState(0)
  const [selectedN3, setSelectedN3] = useState(0)
  const [selectedN4, setSelectedN4] = useState(0)
  const [openaiSolutionNotesLocalState, setOpenaiSolutionNotesLocalState] = useState('');

  const [resolutionCode, setResolutionCode] = useState([])
  const [resoluionCategoryLevelCompany, setResolutionCategoryLevelCompany] = useState(0)
  const classes = useStyles();
  const [timeNoTimeSheet, setTimeNoTimeSheet] = useState('')
  let [time_, SetTime_] = useState('00:00:00');
  let [btv, setBtv] = useState(true);
  let [btv_, setBtv_] = useState(true);
  let [formavaliation, setformavaliation] = useState([]);

  const onNotesTicket_ = (id_tickets, values, index, time_) => {
    setBtv_(false);
    setTimeout(() => {
      setBtv_(true);
    }, 8000);

    if (values.dt_end_crisis) {
      values.dt_end_crisis = concatDateHour(values.dt_end_crisis, values.dt_endtime_crisis);
    }      

    onNotesTicket(id_tickets, values, index, time_);
    if (pResolution100) {
      setPResolution(true);
    }
  };

  const calculateTimeIn = (cur, timeSheet) => {
    let days,
      hours,
      minutes,
      seconds = 0;

    days = Math.floor(cur / 86400);
    cur -= days * 86400;
    hours = Math.floor(cur / 3600) % 24;
    cur -= hours * 3600;
    minutes = Math.floor(cur / 60) % 60;
    cur -= minutes * 60;
    seconds = Math.floor(cur) % 60;
    hours = days * 24 + hours;

    if (hours < 0) hours = 0;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    return hours + ':' + minutes + ':' + seconds;
  };

  useEffect(() => {

    setOpenaiSolutionNotesLocalState(ticket.openai_solution_notes);

    // Applying on mount
    document.body.style.overflow = "hidden";

    action
      .execute(
        'get',
        APIEndpoints.TECH_EVALUATION_REGISTRATION,
        'tech_evaluation_registration'
      )
      .then((e) => {
        setValiationOptions(
          (e.data || []).map((t) => ({
            value: t.id_tech_evaluation_registration,
            label: t.status,
          }))
        );
      });

      action
      .execute(
        'get',
        APIEndpoints.TECH_EVALUATION_REGISTRATION,
        'tech_evaluation_registration'
      )
      .then((e) => {
        setValiationOptions(
          (e.data || []).map((t) => ({
            value: t.id_tech_evaluation_registration,
            label: t.status,
          }))
        );
      });

    // Applying on unmount
    return () => {
      document.body.style.overflow = "auto";
    }
  }, []);

  useEffect(() => {
    if (formRef) {
      formRef.handleValue(0, 'progress');
    }
    setPage(1);
  }, [formRef, ticket.id_tickets]);

  useEffect(() => {
    if (params[0].time_sheet == true) {
      if (
        ticket.config[0].enable_time_sheet == true &&
        ticket.config[0].fk_id_tech == Store.getUserLoged().id
      ) {
        setBtv(true);
      } else {
        setBtv(false);
      }

      if (
        ticket.config[0].enable_time_sheet == true &&
        ticket.config[0].fk_id_tech == Store.getUserLoged().id &&
        ticket.status != 'Closed' &&
        ticket.status != 'Resolved'
      ) {
        Store.socket.on('timeserver', (time) => {
          let ini = moment(ticket.config[0].dt_start_sheet).unix();
          let cur = moment(time).unix() - ini;
          let cur_ = 0;
          SetTime_(calculateTimeIn(cur));
        });
      } else {
        SetTime_(ticket.config[0].time_sheet);
      }
    }
    if (ticket.config[0]) {
      setTimeout(() => {
        var items = (ticket.config[0].filesrequest || [])
          .map((f) => ({
            name:
              f.file +
              ' - ' +
              moment(f.dt_cad).format('DD/MM/YYYY HH:mm') +
              ' - ' +
              ticket.user_solicit[0].name_person,
            size: f.size,
            type: f.type,
            nn: f.file,
            id_tickets_file: f.id_request_file,
            fk_id_request: f.id_request,
            user_cad: f.dt_cad,
            id_user: ticket.fk_id_user,
          }))
          .concat(
            (ticket.config[0].files || []).map((f) => ({
              name:
                f.file +
                ' - ' +
                moment(f.dt_cad).format('DD/MM/YYYY HH:mm') +
                ' - ' +
                f.user_cad,
              size: f.size,
              type: f.type,
              nn: f.file,
              id_tickets_file: f.id_tickets_file,
              id_tickets: f.id_tickets,
              user_cad: f.user_cad,
              id_user: f.id_user,
            }))
          );

        (ticket.config[0].files || []).map((f) => { });

        setLocalFiles(items);
      }, 3000);
    }

    if (params[0].use_resolution_category) {
      action.execute('get', APIEndpoints.RESOLUTION_CATEGORY + '/bycompany/' + ticket.fk_id_company + '/1' + '/' + (ticket.id_type == 1 ? ticket.id_type : 2), '').then(res => {
        const options = res.data.map((n) => ({
          value: n.id_resolution_category,
          label: n.name,
        }))
        setN1Options(options)
      })
      action.execute('get', APIEndpoints.RESOLUTION_CODE + '/bycompany/' + ticket.fk_id_company + '/' + (ticket.id_type == 1 ? ticket.id_type : 2), '').then(res => {
        setResolutionCode(res.data)
      })

      action.execute('post', APIEndpoints.COMPANY + '/filter?limit=1&offset=0', '', { id_company: ticket.fk_id_company }).then(res => {
        setResolutionCategoryLevelCompany(parseInt(res.data[0].resolution_category_level || 0))
      })
    }
  }, [ticket, ticket.config[0].enable_time_sheet, ticket.config[0].fk_id_tech]);

  const getNOptions = (level, n1_level = 0, n2_level = 0, n3_level = 0) => {
    const data = {
      n1: n1_level,
      n2: n2_level,
      n3: n3_level,
      n4: selectedN4
    }

    action.execute('post', APIEndpoints.RESOLUTION_CATEGORY + '/byresolutioncategory/' + ticket.fk_id_company + '/' + level, '', data).then(res => {
      const options = res.data.map((n) => ({
        value: n.id_resolution_category,
        label: n.name,
      }))

      if (level == 1) {
        setN2Options(options)
      } else if (level == 2) {
        setN3Options(options)
      } else {
        setN4Options(options)
      }
    })
  }

  useEffect(() => {
    action
      .execute('post', APIEndpoints.TASK + '/formsrel', '', {
        id_catalog_task: ticket.fk_id_catalog_task,
        avaliation: true,
        id_tickets: ticket.id_tickets,
      })
      .then((e) => {
        if (e.data.length > 0) setformavaliation(e.data[0]);
      });
  }, [ticket]);

  const submitValiation = (/*field,*/ values) => {
    let data = {
      fk_id_tech_evaluation_registration:
        values.fk_id_tech_evaluation_registration,
      fk_id_tickets: ticket.id_tickets,
      html: html,
    };

    saveTechEvaluation(data);
  };

  const saveTechEvaluation = (data) => {
    action
      .execute('put', APIEndpoints.TICKET + '/techvaliation', '', data)
      .then((v) => {
        let url =
          APIEndpoints.TECH_EVALUATION_REGISTRATION +
          '/id/' +
          data.fk_id_tech_evaluation_registration;
        action
          .execute('get', url, '')
          .then((e) => {
            Store.ticket.tech_evaluation_registration_status = e.data[0].status;
          })
          .catch((error) => {
            console.log('error', error);
          });
        // setValiationOptions(v.data[0]);
      });
  };

  const percentResolution = (value) => {
    if (value == 100 && params[0].technical_evaluation_registration == true) {
      // setPResolution(true);
      ///setPResolution100(true);
    } else {
      // setPResolution(false);
      //setPResolution100(false);
    }
  };

  const saveObservation = (values) => {
    values.action = 'description';
    values.id_tickets = ticket.id_tickets;
    action
      .execute(
        'post',
        APIEndpoints.TICKET + '/' + ticket.id_tickets,
        '',
        values
      )
      .then((res) => {
        action
          .execute(
            'get',
            APIEndpoints.TICKET + '/' + ticket.id_tickets,
            'ticket'
          )
          .then((g) => {
            toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');

            //  setLoad(true);
          });
      });
  };

  const saveFormTech = () => {
    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;
    let campossize = false;
    let fields = [];
    let _values = {};
    $('#formavaliation').html();
    if ($('#formavaliation').html() != undefined) {
      $('#formavaliation textarea').each(function () {
        $(this).attr('value', $(this).val());

        $(this).html($(this).val());
        fields.push({ name: $(this).attr('name'), value: $(this).val() });
      });

      $('#formavaliation input').each(function () {
        if ($(this).attr('type') == 'radio') {
          if (
            $('input[name=' + $(this).attr('name') + ']:checked').val() ==
            $(this).val()
          ) {
            $(this).attr('checked', true);
            let value = undefined;
            try {
              value = $(this).parent().children()[1].textContent;
            } catch (e) { }
            fields.push({
              name: $(this).attr('name'),
              value: value || $(this).val(),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);

          $('#formavaliation  label').each(function () {
            var for_ = '';
            try {
              for_ = $(this).attr('for');
            } catch (e) { }

            if (for_ === ip.attr('name')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatoriosradio = true;
                  $('[name=' + for_ + ']').each(function () {
                    try {
                      if ($(this).is(':checked')) {
                        camposobrigatoriosradio = false;
                      }
                    } catch (e) { }
                  });

                  $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);
          $('#formavaliation  label').each(function () {
            var for_ = ' ';
            try {
              for_ = $(this).attr('for');
            } catch (e) { }

            if (ip.attr('name').indexOf(for_) > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatorioscheck = true;
                  $('#formavaliation  input').each(function () {
                    try {
                      if ($(this).attr('name').indexOf(for_) > -1) {
                        try {
                          if ($(this).attr('checked')) {
                            camposobrigatorioscheck = false;
                          }
                        } catch (e) { }
                      }
                    } catch (e) { }
                  });

                  $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          $(this).attr('value', $(this).val());
          let ip = $(this);
          $('#template  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    ip.css('border-color', 'red');
                    camposobrigatorios = true;
                  } else {
                    ip.css('border-color', 'var(--primary)');
                  }
                }
              }
            }
          });
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });
          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${min || 0
                } á ${siz || 0} caracteres</label>`
              );

              campossize = true;
            }
          }

          let ip = $(this);
          $('#formavaliation  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    ip.css('border-color', 'red');
                    camposobrigatorios = true;
                  } else {
                    ip.css('border-color', 'var(--primary)');
                  }
                }
              }
            }
          });
        }
      });

      $('#formavaliation select').each(function () {
        const opt = $('#' + $(this).children('option:selected').attr('id')).attr(
          'selected',
          'selected'
        );

        fields.push({
          name: $(this).attr('name') || $(this).attr('id'),
          value: $(this).find(':selected').text(),
        });

        if (!$(this).find(':selected').text()) {
          opt.parent().css('border-color', 'red');
          camposobrigatorios = true;
        } else {
          opt.parent().css('border-color', 'var(--primary)');
        }
      });

      $('#formavaliation label').each(function () {
        try {
          if ($(this).attr('for').indexOf('textarea') > -1) {
            if ($(this).text().split('*')[1] != undefined) {
              if ($(this).text().split('*')[1].length >= 0) {
                // alert($('#'+$(this).attr('for')).text())

                let node_ = $(this)
                  .parent()
                  .parent()
                  .nextAll()
                  .slice(2, 3)
                  .children()[0];
                if (
                  (node_ &&
                    node_.firstChild.tagName == 'P' &&
                    node_.firstChild.textContent.length == 0) ||
                  (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                ) {
                  camposobrigatorios = true;
                }
              }
            }
          }
        } catch (e) { }
      });
    }

    let html = formavaliation.html; // ticket.config[0].arraypr.form_avaliation;
    //fields
    _values.htmlavaliation = html;
    let id = 0;
    if (id > 0) {
      _values.html = $('#ID1').html();
      _values.id_template_ticket = id;
    } else {
      _values.html = '';
      _values.id_template_ticket = 0;
    }
    if (campossize == false) {
      if (
        camposobrigatorios == false &&
        camposobrigatorioscheck == false &&
        camposobrigatoriosradio == false
      ) {
        _values.fields = fields;
        _values.id_tickets = ticket.id_tickets;
        _values.action = 'saveform';
        _values.template = formavaliation.html;
        action
          .execute(
            'post',
            APIEndpoints.TICKET + '/' + ticket.id_tickets,
            'ticket',
            _values
          )
          .then((g) => { });
        //  onNotesTicketTab(id_ticket, _values, url, type_tickets);
        return true;
      } else {
        toggleAlert(true, language.FILL_REQUIRED, 'error');
        return false;
      }
    } else {
      toggleAlert(true, language.CHECK_FIELD_SIZES, 'error');
      return false;
    }
  };
  //console.log("RENDER_T1");
  return (
    <Grid container spacing={1} id="block-2">
      {user.role != 'solicitant' ? (
        <Grid item xs={12}>
          <h3 className="title-aba mb-4">{language.REQUEST_RESOLUTION}</h3>
          <span id="descriptionTicket___">
            <CommonForm
              clean
              onRef={(r) => setFormPrimaryRef(r)}
              childFields={
                (formRef || {}).getFields
                  ? (formRef.getFields() || []).map((e) => ({
                    name: e.name,
                    required: e.required,
                  }))
                  : []
              }
              values={{
                notes: ticket.status == 'Resolved' && ticket.resolution_notes,
                openai_solution_notes: openaiSolutionNotesLocalState, //ticket.openai_solution_notes
              }}
              fields={[
                {
                  col: 3,
                  type: 'element',
                  name: 'group-field',

                  content: (v, handleValue) => (
                    <div className="mt-2">
                      <CommonForm
                        onRef={(r) => setFormRef(r)}
                        individual
                        values={{
                           dt_end_crisis: ticket.dt_end_crisis,
                           dt_endtime_crisis: ticket.dt_end_crisis
                        }}
                        onChangeField={(f, v) => {
                          handleValue(v, f.name);
                          if (f.name == 're') {
                            formPrimaryRef.handleValue(
                              Store.getUserLoged().integration == true
                                ? answer
                                  .filter((g) => g.id_answer === v)[0]
                                  .description.replace(/<.*?>/g, '')
                                : answer.filter((g) => g.id_answer === v)[0]
                                  .description,
                              'notes'
                            );
                          } else if (f.name == 'notes') {
                            formPrimaryRef.handleValue(undefined, 'notes');
                          }
                          if (f.name == 'time') {
                            setTimeNoTimeSheet(v)
                          }
                        }}
                        clean
                        fields={[
                          {
                            type: 'element',
                            name: 'progress',
                            required: true,
                            content: (value, handleValue) => (
                              <div className="py-3 d-flex aling-items-center">
                                <MuiThemeProvider theme={theme}>
                                  <Slider
                                    value={value ? value : ''}
                                    min={ticket.config[0].progress}
                                    max={100}
                                    step={5}
                                    aria-labelledby="label"
                                    disabled={
                                      (ticket.config[0].progress >= 100 &&
                                        statusTicket != 'Job') ||
                                      isReadonly
                                    }
                                    onChange={(evt, v) => {
                                      handleValue(v, 'progress');
                                      percentResolution(v);
                                      setPResolution(v);
                                    }}
                                  />
                                </MuiThemeProvider>
                                <span
                                  className="ml-1"
                                  style={{ marginTop: -9, fontSize: 13 }}
                                >
                                  {parseInt(value) || ticket.config[0].progress}
                                  %
                                </span>
                              </div>
                            ),
                          },

                          params[0].time_sheet == true
                            ? {
                              type: 'element',
                              content: () =>
                                ticket.config[0].enable_time_sheet == true ? (
                                  <h3
                                    id="timesheet_ticketdetail"
                                    name="timesheet_ticketdetail"
                                    className="title-aba mb-4"
                                  >
                                    {time_}
                                  </h3>
                                ) : null,
                            }
                            : {
                              name: 'time',
                              label: language.TIME_SPENT,
                              type: 'text',
                              required: true,
                              format: '##:##',
                              change(v) {
                                return v.formattedValue;
                              },
                            },
                          {
                            type: 'select',
                            name: 're',
                            label: language.AUTOMATIC_ANSWER,
                            options: ((answer && answer) || []).map((h) => ({
                              value: h.id_answer,
                              label: h.name,
                            })),
                          },
                          {
                            type: 'element',
                            name: 'openai_solution_evaluation_percentage',
                            required: ticket.is_openai_solution == true ? true : false,
                            visible: ticket.is_openai_solution == true ? true : false,
                            content: (value, handleValue) => (
                              <>
                                <h3 className="title-aba mt-4">Solução OPENAI</h3>
                                <div className="py-3 d-flex aling-items-center mb-2">
                                  <MuiThemeProvider theme={theme}>
                                    <Slider
                                      value={value ? value : ''}
                                      min={ticket.openai_solution_evaluation_percentage}
                                      max={100}
                                      step={1}
                                      aria-labelledby="label"
                                      disabled={
                                        (ticket.openai_solution_evaluation_percentage >= 100 &&
                                          statusTicket != 'Job') ||
                                        isReadonly
                                      }
                                      onChange={(evt, value) => {
                                        handleValue(value, 'openai_solution_evaluation_percentage');
                                      }}
                                    />
                                  </MuiThemeProvider>
                                  <span
                                    className="ml-1"
                                    style={{ marginTop: -9, fontSize: 13 }}
                                  >
                                    {parseInt(value) || ticket.openai_solution_evaluation_percentage}
                                    %
                                  </span>
                                </div>
                              </>
                            ),
                          },
                          {
                            col: 6,
                            type: 'date',
                            name: 'dt_end_crisis',
                            label: language.DATE_NORMALIZATION,    
                            visible: (pResolution == 100 || ticket.status == 'Resolved') && ticket.type_tickets === 'Crisis',
                            disabled: ticket.status == 'Resolved',
                            required: true
                          },
                          {
                            col: 6,
                            type: 'time',
                            name: 'dt_endtime_crisis',
                            label: language.HOUR_NORMALIZATION,    
                            visible: (pResolution == 100 || ticket.status == 'Resolved') && ticket.type_tickets === 'Crisis',
                            disabled: ticket.status == 'Resolved',
                            required: true
                          },

                        ]}
                      />
                    </div>
                  ),
                },

                (Store.getUserLoged().integration == true
                  ? {
                    col: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 0 ? 6 : 9,
                    type: 'simplesTextEditor',
                    name: 'notes',
                    label: language.RESOLUTION,
                    required: true,
                    style: { height: '200px' },
                    disabled: ticket.status == 'Resolved'
                  }
                  : {
                    col: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 0 ? 6 : 9,
                    type: 'textEditor',
                    contentProps: { style: { marginTop: -29 } },
                    className: 't1-description',
                    name: 'notes',
                    label: language.RESOLUTION,
                    required: true,
                    disabled: ticket.status == 'Resolved'
                  }),
                {
                  col: 3,
                  type: 'element',
                  name: 'group-field',
                  content: (v, handleValue) => (
                    <div style={{ marginTop: '2rem' }}>
                      <CommonForm
                        onRef={(r) => setFormRef(r)}
                        individual
                        clean
                        onChangeField={(f, v) => {
                          if (f && f.name) {
                            handleValue(v, f.name);
                          }

                          if (f && f.name == 'level_n1') {
                            getNOptions(1, v)
                            setSelectedN1(v)
                            setN3Options([])
                            setN4Options([])
                          }

                          if (f && f.name == 'level_n2') {
                            getNOptions(2, selectedN1, v, 0)
                            setSelectedN2(v)
                            setN4Options([])
                          }

                          if (f && f.name == 'level_n3') {
                            getNOptions(3, selectedN1, selectedN2, v)
                            setSelectedN3(v)
                          }
                          if (f && f.name == 'level_n4') {
                            setSelectedN4(v)
                          }

                        }}
                        fields={[
                          {
                            col: 12,
                            name: 'level_n1',
                            label: language.TOWER,
                            type: 'select',
                            options: n1Options,
                            visible: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 0 ? true : false,
                            required: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 0 ? true : false,
                          }, {
                            col: 12,
                            name: 'level_n2',
                            label: language.IMPACTED_SYSTEM,
                            type: 'select',
                            options: n2Options,
                            visible: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 1 ? true : false,
                            required: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 1 ? true : false,
                          }, {
                            col: 12,
                            name: 'level_n3',
                            label: language.MENU,
                            type: 'select',
                            options: n3Options,
                            visible: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 2 ? true : false,
                            required: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 2 ? true : false,
                          }, {
                            col: 12,
                            name: 'level_n4',
                            label: language.SUBMENU,
                            type: 'select',
                            options: n4Options,
                            visible: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 3 ? true : false,
                            required: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 3 ? true : false,
                          },
                          {
                            col: 12,
                            name: 'resolution_code',
                            label: language.RESOLUTION_CODE,
                            type: 'select',
                            options: resolutionCode.map(r => ({ value: r.id_resolution_code, label: r.code_resolution_name })),
                            visible: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 0 ? true : false,
                            required: params[0].use_resolution_category && pResolution == 100 && resoluionCategoryLevelCompany > 0 ? true : false,
                          }
                        ]}

                      />
                    </div>
                  )
                },
                {
                  col: 12,
                  type: 'simplesTextEditor',
                  name: 'openai_solution_notes',
                  label: 'Observações da solução OPENAI',
                  style: { height: '200px' },
                  visible: ticket.is_openai_solution == true ? true : false,
                },

              ]}
              button={{
                md: 2,
                offset: { md: 10 },
                label: language.SAVE,
                primary: true,
                style: { margin: 10 },
                disabled: hasTasksPending == true ? true : false
              }}
              beforeButtonElement={
                <Button
                  variant="normal"
                  color="warning"
                  icon={`fas fa-trash-restore-alt`}
                  onClick={() => formPrimaryRef.handleValue(undefined, 'notes')}
                  label={language.CLEAR}
                />
              }
              onChangeField={(f, v) => {
                if (!!f) {
                  if (!!f.name) {
                    if (f.name == 'openai_solution_notes') {
                      setOpenaiSolutionNotesLocalState(v);
                    }
                  }
                }
              }}
              onSubmit={
                statusTicket == 'In Progress' ||
                  statusTicket == 'Reopened' ||
                  statusTicket == 'Job'
                  ? (values) => {

                    const isProgress100 = values.progress == 100 ? true : false;
                    const isFormTechOK = isProgress100 ? saveFormTech() : true;
                    if (!isFormTechOK) {
                      return;
                    }

                    values.time = values.time == undefined ? timeNoTimeSheet : values.time
                    if (values.notes == undefined || values.notes == '') {
                      toggleAlert(true, language.ENTER_DESCRIPTION, 'error');
                    } else if (values.progress == undefined) {
                      toggleAlert(true, language.ENTER_EVOLUTION, 'error');
                    } else {
                      if (btv_ == true) {
                        if (btv == true) {
                          formRef.submit();
                          if (ticket.config[0].stop_ticket > 0) {
                            values.aguardando = true;
                          }
                          setTimeout(() => {
                            if (
                              time_ != '00:00:00' &&
                              params[0].time_sheet == true
                            ) {
                              values.time = time_;
                            }

                            onNotesTicket_(
                              ticket.id_tickets,
                              values,
                              url,
                              type_tickets
                            );
                          }, 1000);
                          // if(submitTechEvaluation && pResolution100){
                          //     saveTechEvaluation(submitTechEvaluation);
                          // }
                        } else {
                          toggleAlert(true, language.RESUME_CALL, 'error');
                        }
                      }
                    }
                  }
                  : null
              }
            />
          </span>
          {hasTasksPending == false && (
            <CommonForm
              fields={[
                {
                  col: 12,
                  label: language.OBSERVATIONS,
                  name: 'observation',
                  type: 'textEditor',
                },
              ]}
              values={observation}
              onSubmit={saveObservation}
            />
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};

const _T2 = ({
  index,
  label,
  language,
  values,
  item,
  field,
  action,
  APIEndpoints,
  ticket,
  toggleAlert,
  onNotesTicket,
  params,
}) => {
  let fields = [];
  const calculateTimeIn = (cur) => {
    let days,
      hours,
      minutes,
      seconds = 0;

    days = Math.floor(cur / 86400);
    cur -= days * 86400;
    hours = Math.floor(cur / 3600) % 24;
    cur -= hours * 3600;
    minutes = Math.floor(cur / 60) % 60;
    cur -= minutes * 60;
    seconds = Math.floor(cur) % 60;
    hours = days * 24 + hours;

    if (hours < 0) hours = 0;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    return hours + ':' + minutes + ':' + seconds;
  };

  if (typeof values['time_' + field] == 'string') {
    let date = (values['time_' + field] || moment().format('HH:mm:ss')).split(
      ':'
    );
    values['time_' + field] = moment().set({ hour: date[0], minutes: date[1] });
  }
  let [time_, SetTime_] = useState('00:00:00');
  let [openAnswer, setOpenAnswer] = useState(false);
  let [asnwer, setasnwer] = useState(null);
  let [valuesAnswer, setValuesAnswer] = useState([]);
  let [btv, setBtv] = useState(true);
  let [btv_, setBtv_] = useState(true);

  const onNotesTicket_ = (id_tickets, values, index, time_) => {
    setBtv_(false);
    setTimeout(() => {
      setBtv_(true);
    }, 8000);
    onNotesTicket(id_tickets, values, index, time_);
  };

  const saveAsnwer = (v) => {
    v.fk_id_tickets = ticket.id_tickets;
    v.tipo = 'save';
    action.execute('post', APIEndpoints.TICKET + '/answer', '', v).then((h) => {
      delete v.tipo;
      action
        .execute('post', APIEndpoints.TICKET + '/answer', '', v)
        .then((h) => {
          setValuesAnswer(h.data[0]);
        });
    });
    setOpenAnswer(false);
  };

  useEffect(() => {
    if (params[0].time_sheet == true) {
      if (
        ticket.config[0].enable_time_sheet == true &&
        ticket.config[0].fk_id_tech == Store.getUserLoged().id
      ) {
        setBtv(true);
      } else {
        setBtv(false);
      }
    }

    let v = {};
    v.tipo = 'list';
    v.fk_id_tickets = ticket.id_tickets;

    if (params[0].time_sheet == true) {
      if (
        ticket.config[0].enable_time_sheet == true &&
        ticket.config[0].fk_id_tech == Store.getUserLoged().id &&
        ticket.status != 'Closed' &&
        ticket.status != 'Resolved'
      ) {
        Store.socket.on('timeserver', (time) => {
          let ini = moment(ticket.config[0].dt_start_sheet).unix();
          let cur = moment(time).unix() - ini;
          SetTime_(calculateTimeIn(cur));
        });
      } else {
        SetTime_(ticket.config[0].time_sheet);
      }
    }
  }, [
    ticket,
    openAnswer,
    ticket.config[0].enable_time_sheet,
    ticket.config[0].fk_id_tech,
  ]);

  if (asnwer != null) {
    values.problem_identification = asnwer;
  }

  let button = {
    md: 1,
    offset: { md: 8 },
    label: language.SAVE,
    primary: true,
    style: { float: 'right' },
  };
  fields = [
    {
      col: 12,
      name: field,
      label: '',
      type: 'textEditor',
      multiLine: true,
      required: true,
    },
    parseInt(ticket.config[0][field + '_percent']) == 100
      ? { type: 'element', content: '' }
      : {
        type: 'element',
        col: 6,
        name: 'progress',
        required: true,
        content: (value, handleValue) => (
          <div className="py-3 d-flex aling-items-center">
            <MuiThemeProvider theme={theme}>
              <Slider
                value={value ? value : ''}
                min={
                  isNaN(
                    parseInt(ticket.config[0][field + '_percent'] || 0)
                  ) == true
                    ? 0
                    : parseInt(ticket.config[0][field + '_percent'] || 0)
                }
                max={100}
                step={5}
                aria-labelledby="label"
                onChange={(evt, v) => handleValue(v, 'progress')}
              />
            </MuiThemeProvider>
            <span className="ml-1" style={{ marginTop: -9, fontSize: 13 }}>
              {isNaN(
                parseInt(value) ||
                parseInt(ticket.config[0][field + '_percent'])
              ) == true
                ? 0
                : parseInt(value) ||
                parseInt(ticket.config[0][field + '_percent'])}
              %
            </span>
          </div>
        ),
      },
    params[0].time_sheet == true
      ? {
        type: 'element',
        content: () => <h3 className="title-aba mb-4">{time_}</h3>,
      }
      : parseInt(ticket.config[0][field + '_percent']) == 100
        ? { type: 'element', content: '' }
        : {
          col: 6,
          name: 'time_' + field,
          label: language.TIME,
          type: 'time',
          required: true,
        },
  ];

  if (ticket['time_' + field] != null) {
    fields = [
      {
        span: 12,
        name: field,
        label: '',
        type: 'textEditor',
        multiLine: true,
        required: true,
        onKeyDown: (e, val) => {
          if (e.keyCode == 8 && val.length - 1 < values[field].length) {
            e.preventDefault();
          }
        },
      },
      parseInt(ticket.config[0][field + '_percent']) == 100
        ? { type: 'element', content: '' }
        : {
          type: 'element',
          col: 6,
          name: 'progress',
          required: true,
          content: (value, handleValue) => (
            <div className="py-3 d-flex aling-items-center">
              <MuiThemeProvider theme={theme}>
                <Slider
                  value={value ? value : ''}
                  min={
                    isNaN(
                      parseInt(ticket.config[0][field + '_percent'] || 0)
                    ) == true
                      ? 0
                      : parseInt(ticket.config[0][field + '_percent'] || 0)
                  }
                  max={100}
                  step={5}
                  aria-labelledby="label"
                  onChange={(evt, v) => handleValue(v, 'progress')}
                />
              </MuiThemeProvider>
              <span className="ml-1" style={{ marginTop: -9, fontSize: 13 }}>
                {isNaN(
                  parseInt(value) ||
                  parseInt(ticket.config[0][field + '_percent'])
                ) == true
                  ? 0
                  : parseInt(value) ||
                  parseInt(ticket.config[0][field + '_percent'])}
                %
              </span>
            </div>
          ),
        },
      params[0].time_sheet == true
        ? parseInt(ticket.config[0][field + '_percent']) == 100
          ? { type: 'element', content: '' }
          : {
            type: 'element',
            content: () => <h3 className="title-aba mb-4">{time_}</h3>,
          }
        : parseInt(ticket.config[0][field + '_percent']) == 100
          ? { type: 'element', content: '' }
          : {
            col: 6,
            name: 'time_' + field,
            label: language.TIME,
            type: 'time',
            required: true,
          },
    ];
  }

  if (ticket.status != 'In Progress' && ticket.status != 'Reopened') {
    button = {
      md: 1,
      offset: { md: 8 },
      label: language.SAVE,
      primary: true,
      disabled: true,
      style: { float: 'right' },
    };
  }

  button = {
    md: 1,
    offset: { md: 8 },
    label: language.SAVE,
    primary: true,
    disabled: false,
    style: { float: 'right' },
  };

  if (
    index == 2 &&
    (
      ticket.config[0]['problem_identification' + '_percent'] == null ||
      parseInt(ticket.config[0]['problem_identification' + '_percent']) < 100
    )
  ) {
    button.disabled = true;
  } else if (
    index == 3 &&
    (ticket.config[0]['analyze' + '_percent'] == null ||
      parseInt(ticket.config[0]['analyze' + '_percent']) < 100)
  ) {
    button.disabled = true;
  } else if (
    index == 4 &&
    (ticket.config[0]['solution_development' + '_percent'] == null ||
      parseInt(ticket.config[0]['solution_development' + '_percent']) < 100)
  ) {
    button.disabled = true;
  }

  if (
    parseInt(ticket.config[0][field + '_percent']) == 100 &&
    (ticket.status == 'In Progress' || ticket.status == 'Reopened')
  ) {
    button.label = language.EDIT;
    button.disabled = false;
  } else if( parseInt(ticket.config[0][field + '_percent']) == 100 ) {
    button.disabled = true;
  }
  //caso o ticket já esteja em 100% a variavel closed_problem sera adicionada ao
  //objeto
  //console.log("RENDER_T2");
  return (
    <Grid
      item
      xs={6}
      className={classnames({ 'mt-5': [3, 4].includes(index) })}
    >
      <Dialog
        open={openAnswer}
        onClose={() => setOpenAnswer(false)}
        title=""
        maxWidth="lg"
        monitor_scroll
      >
        <div className="unput_ss">{language.FIVE_WHYS_METHODOLOGY}</div>

        <CommonForm
          language={language}
          onSubmit={(c) => saveAsnwer(c)}
          onClose={() => setOpenAnswer(false)}
          button={{
            md: 3,
            offset: { md: 9 },
            label: language.SAVE,
            primary: true,
            style: { margin: 5 },
            disabled:
              ticket.config[0]['problem_identification' + '_percent'] == null ||
                parseInt(
                  ticket.config[0]['problem_identification' + '_percent']
                ) < 100
                ? false
                : true,
          }}
          values={valuesAnswer}
          fields={[
            {
              col: 6,
              label: `${language.WHY} 1?`,
              name: 'answer_1',
              type: 'text',

              className: 'input_s',
            },
            {
              col: 12,
              label: language.ANSWER,
              name: 'response_1',
              type: 'textEditor',
              className: 'input_s',
            },

            {
              col: 6,
              label: `${language.WHY} 2?`,
              name: 'answer_2',
              type: 'text',
              className: 'input_s',
            },
            {
              col: 12,
              label: language.ANSWER,
              name: 'response_2',
              type: 'textEditor',
            },

            {
              col: 6,
              label: `${language.WHY} 3?`,
              name: 'answer_3',
              type: 'text',
              className: 'input_s',
            },
            {
              col: 12,
              label: language.ANSWER,
              name: 'response_3',
              type: 'textEditor',
            },

            {
              col: 6,
              label: `${language.WHY} 4?`,
              name: 'answer_4',
              type: 'text',
              className: 'input_s',
            },
            {
              col: 12,
              label: language.ANSWER,
              name: 'response_4',
              type: 'textEditor',
            },
            {
              col: 6,
              label: `${language.WHY} 5?`,
              name: 'answer_5',
              type: 'text',
              className: 'input_s',
            },
            {
              col: 12,
              label: language.ANSWER,
              name: 'response_5',
              type: 'textEditor',
            },
          ]}
        />
      </Dialog>

      <div className="d-flex align-items-center mb-4">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            backgroundColor: '#ff6600',
            width: 17,
            height: 17,
            color: 'white',
            fontSize: 10,
            borderRadius: 4,
          }}
        >
          {index}
        </div>
        <h3 className="title-aba ml-2">
          {label}
          {index == 1 ? (
            <Button
              icon=""
              variant="normal"
              color="success"
              label={language.FIVE_WHYS_METHODOLOGY}
              size={0.9}
              onClick={() => setOpenAnswer(true)}
              zoom={0.9}
              style={{ fontSize: 14, marginLeft: 10 }}
            />
          ) : null}
        </h3>
      </div>
      <CommonForm
        values={{ ...values, key_edit: field }}
        fields={fields}
        button={button}
        onSubmit={(values) => {
          values[field] = values[field].replace(/&nbsp;/g, ' ').replace(/\s+(?=<\/p>)/g, '');
          if (parseInt(ticket.config[0][field + '_percent']) == 100) {
            values.closed_problem = true;
          }
          btv_ == true
            ? btv == true
              ? onNotesTicket_(ticket.id_tickets, values, index, time_)
              : toggleAlert(true, language.RESUME_CALL, 'error')
            : null;
        }}
      />
    </Grid>
  );
};

let arrayPriority = [
  language.TICKET_PRIORITY.SMALL,
  language.TICKET_PRIORITY.MEDIUM,
  language.TICKET_PRIORITY.HIGH,
  language.TICKET_PRIORITY.CRITICAL
];

export default observer(
  ({
    handleDetail,
    abas,
    openTicket,
    closeDetail,
    ticket,
    language,
    user,
    onCommentTicket,
    store,
    onNotesTicketTab,
    onChangeStatus_Tab,
    sendConnectTicketsNew,
    groups,
    groupstech,
    updateTicketTech,
    handleAwaitingTicket,
    onResolutionTab,
    type_solution,
    noBack,
    onChangeTicket,
    assessment,
    categories,
    solutions,
    UpdateFiles,
    redirect,
    redirect2,
    detail,
    ondonwFile,
    ondeletefile,
    onChangeStatusJust,
    toggleAlert,
    onFormSubmitTab,
    onUpdateTemplateHtml,
    action,
    APIEndpoints,
    answer,
    onupdateTicket,
    updateRecorrence,
    controlButtonTheme,
    onActiveRefresh,
    isSeeHistory = false,
    params,
    general_parameters,
    historico,
    // general_params,
  }) => {
    let arrayColors = [params[0].low_ticket_color,
    params[0].medium_ticket_color,
    params[0].high_ticket_color,
    params[0].critical_ticket_color];

    let [anchorEl, setAnchoEl] = useState(null);
    let [companyInformations, setCompanyInformations] = useState([]);
    let [saveTemplate, setSaveTemplate] = useState(false);
    let [ticketsConnect, setTicketsConnect] = useState([]);
    let [locationOptions, setLocationOptions] = useState([]);
    const [filesTask, setFilesTask] = useState([]);
    let [openAssociate, setOpenAssociate] = useState(false);
    let [modalLocation, setModalLocation] = useState(false);
    let [modaltech, setModaltech] = useState(false);
    let [sprintModal, setModalSprint] = useState(false);
    let [modalgroup, setModalgroup] = useState(false);
    let [modalWaiting, setModalWaiting] = useState(false);
    let [modalResolution, setModalResolution] = useState(false);
    let [openMigrateOS, setOpenMigrateOS] = useState(false);
    let [openMigrateCrisis, setOpenMigrateCrisis] = useState(false);
    let [page, setPage] = useState(1);
    let [sla, setSla] = useState([]);
    const [historyDesignation, setHistoryDesignation] = useState([])
    let [user_, setUser_] = useState(undefined);
    const [hasTasksPending, setHasTasksPending] = useState(false)
    const ondonwFile_task = (id, name) => {
      DownloadFiles(
        APIEndpoints.REQUESTS + '/api/downloadTasks/' + id + '&' + name,
        name
      );
    };
    let [categorize, setCategorize] = useState(false);
    const [categorizeWithSub, setCategorizeWithSub] = useState(false);
    const [subTicketCreated, setSubTicketCreated] = useState([]);
    let [openassessment, setOpenassessment] = useState(false);
    let [fornecedor, setFornecedor] = useState(false);
    let [solutionModal, setSolutionModal] = useState(false);
    let [openReopen, setOpenReopen] = useState(false);
    let [cancelModal, setcancelModal] = useState(false);
    let [registerTimePauseValue, setRegisterTimePauseValue] = useState();
    let [statusConfig, setStatusConfig] = useState(
      {
        time_pause_restart: false,
        time_pause_restart_required: false,
        select_provider: false,
        select_provider_required: false
      });
    let [openHistory, setOpenHistory] = useState(false);
    let [ticketHistory, setTicketHistory] = useState(null);
    let [SearchHistoric, setSearchHistoric] = useState('');
    let [localFiles, setLocalFiles] = useState(undefined);
    let [desc, setDesc] = useState(null);
    let [load, setLoad] = useState(true);
    let [stopSlaParams, setstopSlaParams] = useState(false);
    let [companyOptions, setCompanyOptions] = useState([]);
    let [openas, setOpenas] = useState(false);
    let [devices, setdevices] = useState([]);
    let [priorities, setPriorities] = useState([]);
    let [urgencies, setUrgencies] = useState([]);
    let [impactos, setImpactos] = useState([]);
    let [complexities, setComplexities] = useState([]);
    let [statusAwaiting, setStatusAwaiting] = useState([]);
    let [modalOb, setmodalOb] = useState(false);
    let [listSprint, setListSprint] = useState([]);
    const [associatedTicket, setAssociatedTicket] = useState(false);
    const [isReadonly, setIsReadonly] = useState(false);
    const [canAddTask, setCanAddTask] = useState(false);
    let [formavaliation, setformavaliation] = useState([]);
    let [valuesRelForm, setValuesRelForm] = useState([]);
    let [opentask, setopentask] = useState(false);
    let [general_params, setGeneral_params] = useState([]);
    let [subTicket, setSubTicket] = useState(false);
    const [anchorElMigrate, setAnchorElMigrate] = useState(null);
    const [ticketNotes, setTicketNotes] = useState([]);
    const [groupCrisisEdit, setGroupCrisisEdit] = useState(false);
    const [htmlValues, setHtmlValues] = useState([]);
    const [htmlFormAvaliationValues, setHtmlFormAvaliationValues] = useState([]);
    const [openSlaSeries, setOpenSlaSeries] = useState(false);
    const [showEditDescription, setShowEditDescription] = useState(false);
    const [confirmCrisis, setConfirmCrisis] = useState(!!ticket.confirm_crisis);
    const [modalConfirmCrisis, setModalConfirmCrisis] = useState(false);

    useEffect(() => {
      action.execute(
        'get',
        APIEndpoints.TICKET + '/template/html/' + ticket.id_tickets,
        ''
      ).then((e) => {
        try {
          setHtmlValues(JSON.parse(e.data.html_values));
          setHtmlFormAvaliationValues(JSON.parse(e.data.form_avaliation_values || '[]'));
        } catch { }
      });

      let is = JSON.parse(decryp(sessionStorage.getItem('priorities')));
      let iss = JSON.parse(decryp(sessionStorage.getItem('params')));
      let isu = JSON.parse(decryp(sessionStorage.getItem('urgencies')));
      let isus = JSON.parse(decryp(sessionStorage.getItem('impacts')));
      action
        .execute('get', APIEndpoints.STATUS_AWAITING, 'status_awaiting')
        .then((res) => {
          setStatusAwaiting(res.data);
        });

      action
         .execute(
            'get',
            APIEndpoints.TABLE_STRUCTURED + '/130',
            ''
         )
         .then((res) => {
            setComplexities(res.data);
         });

      setPriorities(is);
      setUrgencies(isu);
      setImpactos(isus);
      
      if (iss.stop_sla == true) {
        setstopSlaParams(true);
        setstopSlaParams(false);
      }
    }, []);

    const onDrop = (g) => {
      var files = filesTask.concat(g);
      setFilesTask(files);
    };
    const initialState = {};
    const [json_template_solicitation, updateState] = useReducer(
      (state, updates) => ({
        ...state,
        ...updates,
      }),
      initialState
    );

    const saveSubTicket = (v) => {
      action
        .execute('post', APIEndpoints.TICKET + '/sub/1', '', v)
        .then((res) => {
          setSubTicketCreated(res.data);
          setSubTicket(false);
          setCategorizeWithSub(true);
        });
    };

    const formshtml = (t, formValues, allowEditingAfterOpeningRecord, allFieldsReadOnly) => {
      const userLogin = Store.getUserLoged();
      const timezone = userLogin?.timezone?.value;
      /**
       * Added the timezone of the last user who updated the form.
       * This field is used to calculate the new time for the datetime field.
       */
      let data = [<input id="timezone-last-update-user" type="hidden" value={timezone} />];
      (String(t).split('<div') || []).map((i) => {
        const required = (i || '').indexOf('formbuilder-required') > -1;
        const labelMatch = (i || '').match(/label=\"([^\"]+)\"/);
        const label = labelMatch ? (labelMatch[1] || '').replace('<br>', '') : undefined;
        const readOnly = allFieldsReadOnly || allowEditingAfterOpeningRecord && !i.includes('allow-editing-after-opening-record');
        const addEmptyOption = i.indexOf('addemptyoption') > -1 || i.indexOf('add-empty-option') > -1;

        if( addEmptyOption && i.indexOf('<select') > -1 ) {
          i.replace(/>Selecione</, function(match) {
            let originalText = match.slice(1, -1);
            let newText = translateFromPtBR(originalText, language);
            return `>${newText}<`;
          });
        }

        if (i.indexOf('companies_var') > -1) {
          let textInit = '';
          try {
            textInit = formValues?.find((v) => v.name.includes('companies')).value;
          } catch (e) { }

          data.push(
            <div style={readOnly ? { pointerEvents: 'none' } : {}}>
              <div separator="true" id="companies_var" style={{ margin: '15px 0px 30px 0px' }}>
                <CommonForm
                  onChangeField={(field, value) => {
                    updateState({ companies_var: value });
                  }}
                  fields={[
                    {
                      col: 12,
                      type: 'autocomplete',
                      name: 'companies_var',
                      showEmptyFilter: addEmptyOption,
                      label: '',
                      method: 'POST',
                      route: `${APIEndpoints.COMPANY}/filter`,
                      fieldquery: 'search',
                      textlabel: label || language.COMPANY,
                      textinit: textInit,
                      fieldvaluedb: 'name',
                      fieldlabeldb: 'name',
                      idinit: 0,
                      routeAll: `${APIEndpoints.COMPANY}/all?`,
                      methodGetAll: 'POST',
                      routeGetCount: `${APIEndpoints.COMPANY}/count`,
                      detail: true,
                      visible: true,
                      required,
                      disablePortal: true,
                      disabled: readOnly,
                      showId: true,
                      idFieldToShow: 'id_company',
                    },
                  ]}
                />
              </div>
            </div>
          );
        } else if (i.indexOf('locations_var') > -1) {
          let textInit = '';
          try {
            textInit = formValues?.find((v) => v.name.includes('locations_var')).value;
          } catch (e) { }
          data.push(
            <div style={readOnly ? { pointerEvents: 'none' } : {}}>
              <div separator="true" id="locations_var" style={{ margin: '15px 0px 30px 0px' }}>
                <CommonForm
                  onChangeField={(field, value) => {
                    updateState({ locations_var: value });
                  }}
                  fields={[
                    {
                      col: 12,
                      type: 'autocomplete',
                      name: 'locations_var',
                      showEmptyFilter: addEmptyOption,
                      label: label || language.LOCATION,
                      method: 'POST',
                      route: `${APIEndpoints.LOCATION}/filter`,
                      fieldquery: 'search',
                      textlabel: label || language.LOCATION,
                      textinit: textInit,
                      fieldvaluedb: 'name',
                      fieldlabeldb: 'name',
                      routeAll: `${APIEndpoints.LOCATION}/filter?`,
                      methodGetAll: 'POST',
                      detail: true,
                      visible: true,
                      required,
                      disablePortal: true,
                      showId: true,
                      idFieldToShow: 'id_location',
                      // value:
                    },
                  ]}
                />
              </div>
            </div>
          );
        } else if (i.indexOf('allusers_var') > -1) {
          let textInit = '';
          try {
            textInit = formValues?.find((v) => v.name.includes('allusers')).value;
          } catch (e) { }
          data.push(
            <div style={readOnly ? { pointerEvents: 'none' } : {}}>
              <div separator="true" id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
                <CommonForm
                  onChangeField={(field, value) => {
                    updateState({ allusers_var: value });
                  }}
                  fields={[
                    {
                      col: 12,
                      type: 'autocomplete',
                      name: 'allusers_var',
                      showEmptyFilter: addEmptyOption,
                      label: '',
                      method: 'POST',
                      route: `${APIEndpoints.USER}/filter`,
                      fieldquery: 'search',
                      textlabel: label || language.USER,
                      textinit: textInit,
                      fieldvaluedb: 'name',
                      fieldlabeldb: 'name',
                      idinit: language.USER,
                      routeAll: `${APIEndpoints.USER}/filter/all?`,
                      methodGetAll: 'POST',
                      routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                      detail: true,
                      visible: true,
                      required,
                      disablePortal: true,
                      disabled: readOnly,
                      showId: true,
                      idFieldToShow: 'id_user',
                    },
                  ]}
                />
              </div>
            </div>
          );
        } else if (i.indexOf('hours') > -1) {
          let textInit = '';
          try {
            textInit = formValues?.find((v) => v.name.includes('hours')).value;
          } catch (e) { }
          let nameField = 'hours_var';
          data.push(
            <div style={readOnly ? { pointerEvents: 'none' } : {}}>
              <div separator="true" id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
                <CommonForm
                  onChangeField={(field, value) => {
                    updateState({ hours: value });
                  }}
                  values={{
                    [nameField]: textInit || null
                  }}
                  fields={[
                    {
                      col: 12,
                      name: nameField,
                      textlabel: language.HOURS,
                      type: 'time',
                      format: '##:##',
                    },
                  ]}
                />
              </div>
            </div>
          );
        } else if (i.indexOf('hidden_var') > -1) {
          let value_field = '';
          let name_field = '';
          try {
            const regex = /<p(.*?)<\/p>/;
            const textWithoutTags = i.match(regex);
            name_field = textWithoutTags[1].split('|')[1];
            value_field = textWithoutTags[1].split('|')[2];
          } catch (e) { }
          const html_field = `<div separator="true" hidden><input value = ${value_field} name = ${name_field} /></div>`;
          data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
        } else if (i.indexOf('subsdpt_var') > -1) {
          let textInit = '';
          try {
            textInit = formValues?.find((v) => v.name.includes('subdepartments')).value;
          } catch (e) { }
          data.push(
            <div style={readOnly ? { pointerEvents: 'none' } : {}}>
              <div separator="true" id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
                <CommonForm
                  onChangeField={(field, value) => {
                    updateState({ subdepartments_var: value });
                  }}
                  fields={[
                    {
                      col: 12,
                      type: 'autocomplete',
                      name: 'subdepartments',
                      showEmptyFilter: addEmptyOption,
                      label: '',
                      textlabel: label || language.SUBDEPARTMENT,
                      method: 'POST',
                      filterAll: { fk_id_department: 1 },
                      filter: { fk_id_department: 1 },
                      route: `${APIEndpoints.DEPARTMENT}/filter/`,
                      routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                      fieldquery: 'search',
                      textinit: textInit,
                      fieldvaluedb: 'name',
                      fieldlabeldb: 'name',
                      idinit: 0,
                      routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                      methodGetAll: 'POST',
                      required,
                      disablePortal: true,
                      disabled: readOnly,
                      showId: true,
                      idFieldToShow: 'id_department',
                    },
                  ]}
                />
              </div>
            </div>
          );
        } else if (i.indexOf('departments_var') > -1) {
          let textInit = '';
          try {
            textInit = formValues?.find((v) => v.name.includes('departments')).value;
          } catch (e) { }
          data.push(
            <div style={readOnly ? { pointerEvents: 'none' } : {}}>
              <div separator="true" id="departments_var" style={{ margin: '15px 0px 30px 0px' }}>
                <CommonForm
                  onChangeField={(field, value) => {
                    updateState({ departments_var: value });
                  }}
                  fields={[
                    {
                      col: 12,
                      type: 'autocomplete',
                      name: 'departments',
                      label: '',
                      textlabel: label || language.DEPARTMENTS,
                      method: 'POST',
                      showEmptyFilter: addEmptyOption,
                      route: `${APIEndpoints.DEPARTMENT}/filter/`,
                      routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                      fieldquery: 'search',
                      textinit: textInit,
                      fieldvaluedb: 'name',
                      fieldlabeldb: 'name',
                      idinit: 0,
                      routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                      methodGetAll: 'POST',
                      required,
                      disablePortal: true,
                      disabled: readOnly,
                      showId: true,
                      idFieldToShow: 'id_department',
                    },
                  ]}
                />
              </div>
            </div>
          );
        } else if (i.indexOf('contracts_var') > -1) {
          let textInit = '';
          try {
            textInit = formValues?.find((v) => v.name.includes('contracts')).value;
          } catch (e) { }
          data.push(
            <div style={readOnly ? { pointerEvents: 'none' } : {}}>
              <div separator="true" style={{ margin: '15px 0px 30px 0px' }}>
                <CommonForm
                  onChangeField={(field, value) => {
                    updateState({ contracts_var: value });
                  }}
                  fields={[
                    {
                      col: 12,
                      type: 'autocomplete',
                      name: 'contracts_var',
                      label: language.CONTRACTS,
                      method: 'POST',
                      showEmptyFilter: addEmptyOption,
                      route: `${APIEndpoints.CONTRACT}/filter`,
                      fieldquery: 'search',
                      textlabel: label || language.CONTRACT,
                      textinit: textInit,
                      fieldvaluedb: 'name',
                      fieldlabeldb: 'name',
                      idinit: 0,
                      routeAll: `${APIEndpoints.CONTRACT}/all?`,
                      methodGetAll: 'POST',
                      routeGetCount: `${APIEndpoints.CONTRACT}/count?`,
                      textlabel: language.CONTRACTS,
                      disablePortal: true,
                      showId: true,
                      required,
                      idFieldToShow: 'id_contract',
                    },
                  ]}
                />
              </div>
            </div>
          );
        } else if (i.indexOf('chieftdpt_var') > -1) {
          let textInit = '';
          try {
            textInit = formValues?.find((v) => v.name.includes('boss')).value;
          } catch (e) { }
          data.push(
            <div style={readOnly ? { pointerEvents: 'none' } : {}}>
              <div separator="true" id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
                <CommonForm
                  onChangeField={(field, value) => {
                    updateState({ chieftdpt_var: value });
                  }}
                  fields={[
                    {
                      col: 12,
                      type: 'autocomplete',
                      name: 'boss',
                      label: '',
                      showEmptyFilter: addEmptyOption,
                      textlabel: label || language.DEPARTMENT_BOSS,
                      method: 'POST',
                      filter: { fk_id_company: 1 },
                      route: `${APIEndpoints.USER}/filter`,
                      routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                      fieldquery: 'search',
                      textinit: textInit,
                      fieldvaluedb: 'name',
                      fieldlabeldb: 'name',
                      idinit: language.DEPARTMENT_BOSS,
                      routeAll: `${APIEndpoints.USER}/filter/all?`,
                      filterAll: 0,
                      methodGetAll: 'POST',
                      disabled: readOnly,
                      detail: true,
                      visible: true,
                      required,
                      disablePortal: true,
                    },
                  ]}
                />
              </div>
            </div>
          );
        } else {
          if (i.length > 0) {
            let g = '';
            if (i.indexOf('ql-tooltip ql-hidden') > -1) {
              g = `<div style = "display : none" ${i}`;
            } else if (
              i.indexOf('ql-preview') > -1 ||
              i.indexOf('ql-clipboard') > -1 ||
              i.indexOf('ql-snow') > -1
            ) {
              g = `<div  ${i}`;
            } else if (i.indexOf('ql-editor') > -1) {
              g = `<div style="border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
            } else {
              g = `<div class="template-dinamic" style="pointer-events: ${readOnly ? 'none' : 'auto'}" ${i}`;
            }

            const html = $('<div />', { html: g });
            const el = $(html).find('[name]');
            const elType = $(el).attr('type');
            const elName = $(el).attr('name');
            const elValue = formValues?.find((h) => h.name === elName)?.value;

            if (elType === 'time') {
              const hour = (elValue || '').split(':')[0];
              const min = (elValue || '').split(':')[1];
              const date = new Date(2000, 1, 1, hour, min, 0);

              if (isNaN(date) == false) {
                $(el).attr('value', moment(date).format('HH:mm'));
              }
            } else if (elType === 'datetime-local') {
              const date = new Date(elValue);
              elValue && $(el).attr('value', moment(date).format('YYYY-MM-DD HH:mm'));
            } else if (elType === 'radio') {
              const opt = $(el).parent().find('input[value="' + elValue + '"]');
              $(opt).attr('checked', true);
            } else if (elType === 'checkbox') {
              const valuesCheckbox = formValues?.filter((h) => h.name === elName);
              valuesCheckbox?.forEach((v) => {
                const opt = $(el).parent().find('input[value="' + v.value + '"]');
                $(opt).attr('checked', 'checked');
              })
            } else if ( $(el).is('select') ) {
              const valuesToSet = Array.isArray(elValue) ? elValue : [elValue];

              $(el).find('option').each(function() {
                const optionValue = $(this).val().replace(/'/g, "&apos;");
                const normalizedValuesToSet = valuesToSet?.map(value => {
                  return typeof value === 'string' ? value.replace(/'/g, "&apos;") : value;
                });

                if (normalizedValuesToSet.includes(optionValue)) {
                  $(this).attr('selected', 'selected');
                } else {
                  $(this).removeAttr('selected');
                }
              });
            } else if ($(el).is('textarea')) {
              let htmlString = $(el).prop('outerHTML');
              const valueToInsert = elValue !== undefined ? elValue : '';
              htmlString = htmlString.replace(/(<textarea[^>]*>)(<\/textarea>)/, `$1${valueToInsert}$2`);
              $(el).replaceWith(htmlString);
            } else {
              $(el).attr('value', elValue);
            }

            if (i.indexOf('fieldset') < 0 && i.indexOf('button') < 0) {
              data.push(<div style={{ pointerEvents: readOnly ? 'none' : 'auto' }} dangerouslySetInnerHTML={{ __html: $(html).html() }} />);
            }
          }
        }
      });
      return data;
    };

    const saveRelForm = (v) => {
      let { avaliation, templateform, id_template_task } = this.props;
      action
        .execute('post', APIEndpoints.TASK + '/formssaverel', null, {
          ...v,
          id_catalog_task: ticket.fk_id_catalog_task,
          avaliation,
          templateform,
          id_template_task,
        })
        .then((g) => {
          setValuesRelForm((g.data || []).map((b) => b.fk_id_catalog_task));
        });
    };

    const loadPage = (page) => {
      if (page == 3) {
        action
          .execute('get', APIEndpoints.TICKET + '/sla/' + ticket.id_tickets, '')
          .then((e) => {
            setSla(e.data);
          });
      }
      if (page == 6) {
        action
          .execute('get', APIEndpoints.TICKET + '/historydesignation/' + ticket.id_tickets, '')
          .then((e) => {
            setHistoryDesignation(e.data);
          });
      }
    }

    useEffect(() => {
      loadPage(page);
    }, [page]);

    useEffect(() => {
      action
        .execute('post', APIEndpoints.TASK + '/formsrel', '', {
          id_catalog_task: ticket.fk_id_catalog_task,
          avaliation: true,
          id_tickets: ticket.id_tickets,
        })
        .then((e) => {
          if (e.data.length > 0) setformavaliation(e.data[0]);
        });

      action
        .execute('get', APIEndpoints.TICKET + '/getnotes/' + ticket.id_tickets, '')
        .then((res) => {
          setTicketNotes(res.data)
        });

      action
        .execute(
          'get',
          APIEndpoints.LOCATION + `/company/${ticket.fk_id_company}`
        )
        .then((result) => {
          if (result.data.length > 0) {
            setLocationOptions(result.data.map(item => ({
              label: item.name,
              value: item.id_location
            })));
          } else {
            setLocationOptions([]);
          }
        }, (error) => {
          setLocationOptions([]);
        });

      action
        .execute(
          'get',
          APIEndpoints.TICKET + '/template/html/' + ticket.id_tickets,
          ''
        ).then((e) => {
          try {
            setHtmlValues(JSON.parse(e.data.html_values));
            setHtmlFormAvaliationValues(JSON.parse(e.data.form_avaliation_values || '[]'));
          } catch { }
        });

        setPage(null);
        setTimeout(() => {
          setPage(1);
        }, 10);

    }, [ticket]);

    const isRoleToEditDescription = user.role === 'tech' ||
      user.role === 'scm' || user.scm ||
      user.role === 'admin' || user.role === 'client';

    const isConfirmCrisisEditable = isRoleToEditDescription && (ticket.status == 'Open' || ticket.status == 'Reopened' || ticket.status == 'In Progress');

    useEffect(() => {
      let compInformations = [
        {
          label: language._CONTRACT,
          value: ticket.config[0].contractname,
        },
        {
          label: language.DEPARTMENT,
          value: ticket.config[0].name_depart_,
        },
        {
          label: language.REQUESTER,
          value:
            ( ticket.user_solicit[0].vip
              ? '<span style="color: #bb9702;">★ '
              : '' )
            + ticket.user_solicit[0].name_person
            + ( ticket.user_solicit[0].vip
                ? `</span><span style="color: #bb9702; font-style: italic; font-size: 12px;">(${language.REQUESTER} ${language.VIP})</span>`
                : '' ),
        },
        {
          label: language.CITY,
          value: ticket.user_solicit[0].city,
        },
        {
          label: language.EMAIL,
          value: ticket.user_solicit[0].email,
        },
      ]

      if( params[0].enable_location_in_opening_ticket ) {
        let locationField = {
          label: language.LOCATION_TITLE,
          value: (
            <div>
              {ticket.fk_id_location && locationOptions && locationOptions.some(location => location.value === ticket.fk_id_location) ?
                locationOptions.filter(location => location.value === ticket.fk_id_location).map(filteredLocation => filteredLocation.label) :
                language.UNINFORMED}
              {(!isReadonly && user.role != 'solicitant') && !hasTasksPending && locationOptions.length > 0 ? (
                <Button
                  variant="circle"
                  icon="fas fa-pencil-alt"
                  color="success"
                  outlined
                  label={language.CHANGE_LOCATION}
                  onClick={() => setModalLocation(true)}
                  style={{ border: 'none', fontSize: 14 }}
                />
              ) : null}
            </div>
          ),
        };

        compInformations.unshift(locationField);
      }

      if(ticket.type_tickets === 'Crisis' && isConfirmCrisisEditable){
        compInformations.push({
          label: language.CONFIRM_CRISES,
          value: (
            <div>
              { confirmCrisis ? language.YES : language.NO }
              <Button
                  variant="circle"
                  icon="fas fa-pencil-alt"
                  color="success"
                  outlined
                  label={language.EDIT_CRISIS_CONFIRMATION}
                  onClick={() => setModalConfirmCrisis(true)}
                  style={{ border: 'none', fontSize: 14 }}
                />
            </div>
          )
        });
      }

      setCompanyInformations(compInformations);
    }, [locationOptions, confirmCrisis]);

    const getIsAnAssociatedTicket = async (id_ticket) => {
      action
        .execute('get', `${APIEndpoints.TICKET}/connect/${id_ticket}`, '')
        .then((res) => {
          if (res.data.length > 0) {
            //    setAssociatedTicket(data[0].fk_id_tickets);
          }
        })
        .catch((error) => {
          console.error({ error });
        });
    };

    const addSprint = (values) => {
      values.action = 'notes';
      action
        .execute(
          'post',
          APIEndpoints.TICKET + '/' + ticket.id_tickets,
          'ticket',
          values
        )
        .then((h) => { });
    };

    const setModalWaitingFunc = (value) => {
      action
        .execute('get', `${APIEndpoints.COMPANY}/${ticket.fk_id_company}/suppliers`, 'companies')
        .then((item) => {
          let listCompanies = (item.data || []).map((itemc) => ({
            value: itemc.id_company,
            label: itemc.name,
          }));

          setCompanyOptions(listCompanies);
          setModalWaiting(value);
        })
        .catch((error) => {
          console.error({ error });
        });
    };

    const getSprintLow = () => {
      action
        .execute('get', `${APIEndpoints.PROJECT + '/sprintlow'}`, '')
        .then((res) => {
          let companyOptions = (
            res.data.filter(
              (b) =>
                b.status == 'In Progress' &&
                moment().format('YYMMDD') <= moment(b.dt_end).format('YYMMDD')
            ) || []
          ).map((c) => ({
            value: c.id_project_sprint,
            label: c.name,
          }));

          setListSprint(companyOptions);

          setModalSprint(true);
        })
        .catch((error) => {
          console.error({ error });
        });
    };

    let [refComments, setRefComments] = useReducer(
      (state, v) => [...state, v],
      []
    );

    let nameOccupation = nameOccupationUser(
      store.users,
      store.occupations,
      ticket.user_solicit[0].id_user
    );

    const onNotesTicket_1 = (id_ticket, values, index, time) => {
      values.action = 'notes';
      if (index == 1) {
        values.type = 'v1';
      } else if (index == 2) {
        values.type = 'v2';
      } else if (index == 3) {
        values.type = 'v3';
      } else if (index == 4) {
        values.type = 'v4';
      }
      if (time && time != '00:00:00') {
        let h = time.split(':');
        values.time_problem_identification = moment().set({
          hour: h[0],
          minutes: h[1],
        });
        values.time_analyze = moment().set({ hour: h[0], minutes: h[1] });
        values.time_solution_development = moment().set({
          hour: h[0],
          minutes: h[1],
        });
        values.time_test = moment().set({ hour: h[0], minutes: h[1] });
      }

      delete values.notes;
      delete values.forms;
      delete values.files;
      delete values.arraypr;
      delete values.observers;

      action
        .execute('post', `${APIEndpoints.TICKET}/${id_ticket}`, '', values)
        .then((res) => {
          Store.ticket.config[0].analyze_percent = res.data.analyze_percent;
          Store.ticket.config[0].problem_identification =
            res.data.problem_identification;
          Store.ticket.config[0].solution_development =
            res.data.solution_development;
          Store.ticket.config[0].solution_development_percent =
            res.data.solution_development_percent;
          Store.ticket.config[0].test_percent = res.data.test_percent;
          Store.ticket.time_analyze = res.data.time_analyze;
          Store.ticket.config[0].problem_identification_percent =
            res.data.problem_identification_percent;
          Store.ticket.time_problem_identification =
            res.data.time_problem_identification;
          Store.ticket.time_solution_development =
            res.data.time_solution_development;
          Store.ticket.time_test = res.data.time_test;
          Store.ticket.config[0].time_total_request = res.data.time_total_request;
          Store.ticket.config[0].dt_start_sheet = res.data.dt_start_sheet;
          Store.ticket.config[0].dt_stop_sheet = res.data.dt_stop_sheet;
          Store.ticket.config[0].enable_time_sheet = res.data.enable_time_sheet;
          Store.ticket.config[0].time_sheet = res.data.time_sheet;
          Store.ticket.config[0].progress = res.data.progress;
          Store.ticket.config[0].fk_id_tech = res.data.fk_id_tech;
          Store.ticket.notes = res.data.notes;
          Store.ticket.status = res.data.status;

          Store.toggleAlert(
            true,
            language.DATA_SENT_SUCCESSFULLY,
            'success'
          );
          if (values.type == 'v4' && values.progress == 100) {
            setModalResolution(true);
          }
        })
        .catch((error) => {
          console.error({error});
          Store.toggleAlert(
            true,
            language.THERE_WAS_AN_ERROR_SAVING_THE_FIELDS,
            'error'
          );
        });
    };

    Store.socket.on(ticket.config[0].real_id, (data) => {
      if (data.integration == true) {
        Store.ticket.config[0].arraypr.id_integration = data.id_integration;
      } else {
        if (data.usernotrefresh != store.getUserLoged().id) {
          Store.ticket.config[0].analyze_percent = data.analyze_percent;
          Store.ticket.config[0].problem_identification =
            data.problem_identification;
          Store.ticket.config[0].solution_development =
            data.solution_development;
          Store.ticket.config[0].solution_development_percent =
            data.solution_development_percent;
          Store.ticket.config[0].test_percent = data.test_percent;
          Store.ticket.time_analyze = data.time_analyze;
          Store.ticket.config[0].problem_identification_percent =
            data.problem_identification_percent;
          Store.ticket.time_problem_identification =
            data.time_problem_identification;
          Store.ticket.time_solution_development =
            data.time_solution_development;
          Store.ticket.time_test = data.time_test;
          Store.ticket.config[0].time_total_request = data.time_total_request;
          Store.ticket.config[0].dt_start_sheet = data.dt_start_sheet;
          Store.ticket.config[0].dt_stop_sheet = data.dt_stop_sheet;
          Store.ticket.config[0].enable_time_sheet = data.enable_time_sheet;
          Store.ticket.config[0].time_sheet = data.time_sheet;
          Store.ticket.config[0].progress = data.progress;
          Store.ticket.config[0].fk_id_tech = data.fk_id_tech;
          // Store.ticket.config[0].real_id = data.real_id;
          Store.ticket.tech_start = data.tech_start;
          if (data.type_tickets) {
            Store.ticket.type_tickets = data.type_tickets;
          }
          Store.ticket.status = data.status;
          toggleAlert(
            true,
            `${data.usernamerefresh} ${language.UPDATED_CALL}`,
            'success'
          );
        }
      }
    });

    const openSolution = (value) => {
      if (value == true) {
        setSolutionModal(true);
      }
    };

    if (ticket.status == 'Approve') {
      toggleAlert(true, language.REQUESTED_APPROVAL, 'error');
      closeDetail(ticket.id_tickets);
    }

    useEffect(() => {
      if (Store.answer.length == 0) {
        action.execute('get', APIEndpoints.ANSWER, 'answer');
      }
      if (user.role == 'scm') {
        if (ticket.id_tickets) {
          action
            .execute('get', APIEndpoints.TICKET + '/valid/' + ticket.id_tickets)
            .then((res) => {
              if (res.data.length > 0) {
                user.role = 'client';
                user.scm = true;
                setUser_(user);
              } else {
                user.role = 'solicitant';
                //  isReadonly
                setIsReadonly(true);
                setUser_(user);
              }
            });
        }
      }

      if (ticket.id_type == 6) {
        if (['tech', 'solicitant'].includes(user.role)) {
          action
            .execute('get', APIEndpoints.TICKET + '/validcrisis/' + ticket.id_tickets)
            .then((result) => {
              if (result.data?.hasGroupCrisis) {
                setIsReadonly(false);
                setGroupCrisisEdit(true);
                user.role = 'tech'
              }
              else {
                setIsReadonly(true);
              }
            });
        }
        else if (user.role != 'client') {
          setIsReadonly(true);
        }
      }

      if (ticket.id_tickets) {
        refComments.forEach((e) => {
          e.handleValue('', 'description');
        });
      }
    }, [ticket.id_tickets]);

    const getStatus = (ticket) => {
      const status = {};
      status['In Progress'] = language.TICKET_STATUS.IN_PROGRESS;
      status['Resolved'] = language.TICKET_STATUS.RESOLVED;
      status['Reopened'] = language.TICKET_STATUS.REOPENED;
      status['Approve'] = language.TICKET_STATUS.IN_APPROVAL;
      status['Approved'] = language.TICKET_STATUS.APPROVE;
      status['Open'] = language.TICKET_STATUS.OPEN;
      status['Closed'] = language.TICKET_STATUS.CLOSED;
      status['Cancelled'] = language.TICKET_STATUS.CANCELLED;

      if (
        ticket.config[0].stop_ticket > 1 &&
        (ticket.status === 'In Progress' || ticket.status === 'Reopened')
      ) {
        return language.WAITING;
      }
      return status[ticket.status] || ticket.status;
    };

    const getWaiting = (ticket) => {
      const [{ stop_ticket, nameprovider }] = ticket.config;
      if (ticket.status === 'Em Atendimento') {
        return '';
      }
      const waiting =
        statusAwaiting.find((waiting) => waiting.id_status === stop_ticket)
          ?.status || '';
      const provider = !!nameprovider ? `- ${nameprovider}` : ''
      const timePauseRestart = ticket?.time_pause_restart ? ` - ${moment(ticket.time_pause_restart).format('DD/MM/YYYY HH:mm')}` : '';
      return waiting + provider + timePauseRestart;
    };

    useEffect(() => {
      if (
        ticket.status === 'Closed' ||
        ticket.status === 'Resolved' ||
        ticket.status === 'Cancelled'
      ) {
        setIsReadonly(true);
      }
      if (
        ticket.status === 'Open' ||
        ticket.status === 'Reopened' ||
        ticket.status === 'In Progress'
      ) {
        setIsReadonly(false);
        setCanAddTask(true);
      } else {
        setCanAddTask(false);
      }
    }, [ticket.id_tickets, ticket.status]);

    const onNotesTicketTabs = (id_ticket, _values, url, type_tickets) => {
      const [{ stop_ticket, nameprovider }] = ticket.config;
      const waiting = statusAwaiting.find(
        (waiting) => waiting.status.toLocaleLowerCase() === 'em atendimento'
      );

      if (
        ticketsManagement.filter(
          (item) => item.tasks[0].status == 'PENDENTE APROVAÇÃO'
        ).length > 0
      ) {
        if (parseInt(percentTasksFinish) < 100) {
          toggleAlert(
            true,
            language.NOT_ALLOWED_EVOLVE_CALL_WITH_PENDING_APPROVAL,
            'error'
          );
          return;
        }
      }

      if (
        params[0].ticket_closed_tasks == true &&
        ticketsManagement.filter((item) => item.tasks[0].status == 'REJEITADO')
          .length == 0
      ) {
        if (parseInt(percentTasksFinish) < 100) {
          toggleAlert(true, language.MUST_COMPLETE_ALL_ACTIVITIES, 'error');
          return;
        }
      }

      if (
        ticket.config[0].stop_ticket > 0 &&
        waiting.id_status !== ticket.config[0].stop_ticket
      ) {
        handleAwaitingTicket({ id_awaiting: waiting.id_status }, ticket);
      }

      let camposobrigatorios = false;
      let campossize = false;
      let fields = [];

      $('#formavaliation textarea').each(function () {
        $(this).attr('value', $(this).val());

        $(this).html($(this).val());
        fields.push({ name: $(this).attr('name'), value: $(this).val() });
      });

      $('#formavaliation input').each(function () {
        if ($(this).attr('type') == 'radio') {
          if (
            $('input[name=' + $(this).attr('name') + ']:checked').val() ==
            $(this).val()
          ) {
            $(this).attr('checked', true);
            let value = undefined;
            try {
              value = $(this).parent().children()[1].textContent;
            } catch (e) { }
            fields.push({
              name: $(this).attr('name'),
              value: value || $(this).val(),
            });
          } else {
            $(this).attr('checked', false);
          }
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            let value = undefined;
            try {
              value = $(this).parent().children()[1].textContent;
            } catch (e) { }
            fields.push({
              name: $(this).attr('name'),
              value: value || $(this).val(),
            });
          } else {
            $(this).attr('checked', false);
          }
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          $(this).attr('value', $(this).val());
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });
          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${min || 0
                } á ${siz || 0} ${language.CHARACTERS}</label>`
              );

              campossize = true;
            }
          }
          let ip = $(this);
          $('#ID1 label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length == 0) {
                  if (ip.attr('value').length == 0) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          });
        }
      });

      $('#formavaliation select').each(function () {
        $('#' + $(this).children('option:selected').attr('id')).attr(
          'selected',
          'selected'
        );

        fields.push({
          name: $(this).attr('name') || $(this).attr('id'),
          value: $(this).find(':selected').text(),
        });
      });

      let html = $('#formavaliation').html();
      //fields
      _values.htmlavaliation = html;
      let id = 0;
      if (id > 0) {
        _values.html = $('#ID1').html();
        _values.id_template_ticket = id;
        _values.html = '';
        _values.id_template_ticket = 0;
      }
      if (campossize == false) {
        if (camposobrigatorios == false) {
          _values.fields = fields;
          onNotesTicketTab(id_ticket, _values, url, type_tickets);
        } else {
          toggleAlert(true, language.FILL_REQUIRED, 'error');
        }
      } else {
        toggleAlert(true, language.CHECK_FIELD_SIZES, 'error');
      }
    };

    if (
      ticket.config[0].observers.filter((b) => b.fk_id_user == user.id).length >
      0
    ) {
      user.role = 'solicitant';
    }

    const relationObserver = (array, id_tickets, deleted, usermail) => {
      if (array.length > 20) {
        setLoad(false);
        store.toggleAlert(
          true,
          language.OPERATION_NOT_PERFORMED_MAX_20_USERS,
          'error'
        );
        action
          .execute('get', APIEndpoints.TICKET + '/' + id_tickets, 'ticket')
          .then((g) => {
            setLoad(true);
          });
      } else {
        store.toggleAlert(
          true,
          language.OBSERVER_ADDED_SUCCESSFULLY,
          'success'
        );
        action
          .execute('post', APIEndpoints.TICKET + '/relationObserver', '', {
            users: array,
            usersmail: usermail,
            id_tickets: id_tickets,
            delete: deleted,
          })
          .then((h) => {
            Store.ticket.config[0].observers = h.data.observers;
          });
      }
    };

    const handleCloseHistory = React.useCallback(() => {
      setOpenHistory(false);
      setTicketHistory(null);
      handleDetail(ticket.id_tickets);
    }, []);

    const handleOpenHistory = React.useCallback((ticketHistory) => {
      setOpenHistory(true);
      setTicketHistory(ticketHistory);
    }, []);

    const ondeletefile_ = (f) => {
      if (user.id == f.id_user) {
        toggleAlert(
          true,
          language.ONLY_RESPONSIBLE_FOR_FILE_CAN_DELETE,
          'error'
        );
      } else {
        ondeletefile(f);
      }
    };

    const updateTemp = (idHtml, ticket, id_template, v, status) => {
      let camposobrigatorios = false;

      if (v.time != null || status == 'closed') {
        let values = {
          html: '',
          ticket: ticket,
          id_template_ticket: id_template,
          time: v.time,
          notes: v.notes,
          closed: status == 'closed' ? true : false,
        };
        onupdateTicket(values);
      } else {
        alert('Time null');
      }
    };

    const handleClick = (event, t) => {
      setAnchoEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchoEl(null);
    };
    let open = !!anchorEl;

    const calcModalProblemResolvend = (ticket) => {
      var hour = 0;
      var minute = 0;
      var second = 0;

      if (ticket.type_tickets === 'Problem') {
        try {
          let data = null;

          if (ticket != undefined) {
            var time1 = ticket.time_analyze;
            var time2 = ticket.time_solution_development;
            var time3 = ticket.time_problem_identification;
            var time4 = ticket.time_test;

            var splitTime1 = time1.split(':');
            var splitTime2 = time2.split(':');
            var splitTime3 = time3.split(':');
            var splitTime4 = time4.split(':');

            hour =
              parseInt(splitTime1[0]) +
              parseInt(splitTime2[0]) +
              parseInt(splitTime3[0]) +
              parseInt(splitTime4[0]);
            minute =
              parseInt(splitTime1[1]) +
              parseInt(splitTime2[1]) +
              parseInt(splitTime3[1]) +
              parseInt(splitTime4[1]);
            hour = hour + minute / 60;
            minute = minute % 60;
            second =
              parseInt(splitTime1[2]) +
              parseInt(splitTime2[2]) +
              parseInt(splitTime3[2]) +
              parseInt(splitTime4[2]);
            minute = minute + second / 60;
            second = second % 60;

            data = parseInt(hour) + ':' + parseInt(minute) + ':' + second;
          }
          return data;
        } catch (e) {
          return null;
        }
      } else {
        try {
          (ticket.config[0].forms || []).map((obj) => {
            hour = hour + parseInt(obj.tempo.split(':')[0]);
            minute = minute + parseInt(obj.tempo.split(':')[1]);
          });
          hour = hour + minute / 60;
          minute = minute % 60;

          return (
            parseInt(hour).toString().padStart(2, '0') +
            ':' +
            parseInt(minute).toString().padStart(2, '0') +
            ':' +
            '00'
          );
        } catch (e) { }
      }
    };
    const startAssessment = (ticket) => {
      window.onbeforeunload = function () {
        return false;
      };
      document.addEventListener(
        'click',
        (e) => {
          if (
            e.target.outerText == 'Finalizar' ||
            e.target.outerText == 'FullScrean'
          ) {
          } else {
            e.stopPropagation();
            e.preventDefault();
          }
        },
        true
      );
      action
        .execute('get', APIEndpoints.DEVICE + '/' + ticket.fk_id_device, '')
        .then((h) => {
          let device = h.data[0];
          action
            .execute(
              'post',
              APIEndpoints.ASSESSMENT,
              'assessment',
              {
                device: ticket.fk_id_device,
                tickets: undefined,
                contract: ticket.config[0].fk_id_contract,
              },
              null,
              'Waiting for remote connection'
            )
            .then((b) => {
              setdevices(device);
              setOpenas(true);
              this.redirect2();
            });
        });
    };

    const startAssessment_ = (ticket) => {
      action
        .execute('get', APIEndpoints.DEVICE + '/' + ticket.fk_id_device, '')
        .then((h) => {
          let device = h.data[0];
          action
            .execute(
              'post',
              APIEndpoints.ASSESSMENT,
              'assessment',
              {
                device: ticket.fk_id_device,
                tickets: undefined,
                contract: ticket.config[0].fk_id_contract,
              },
              null,
              'Waiting for remote connection'
            )
            .then((b) => {
              setdevices(device);
              setOpenas(true);
            });
        });
    };

    const finishAssessment = (a, c, s, d, g) => {
      window.onbeforeunload = function () {
        return true;
      };

      g.disconnect();
      let { assessment } = store;
      action
        .execute(
          'post',
          APIEndpoints.ASSESSMENT,
          'assessment',
          { tickets: [], path: assessment[0].path, note: '' },
          null,
          'Finished the remote connection'
        )
        .then((e) => {
          redirect('/dash/ticketmanagement', {
            abas,
            id_tickets: ticket.id_tickets,
            real_id: ticket.config[0].real_id,
          });
        });
    };

    const finishAssessmentTer = (a, c, s, d, g) => {
      let { assessment } = store;
      action
        .execute(
          'post',
          APIEndpoints.ASSESSMENT,
          'assessment',
          { tickets: [], path: assessment[0].path, note: '' },
          null,
          'Finished the remote connection'
        )
        .then((e) => {
          setOpenas(false);
        });
    };
    const onReopenSave = (v, ticket) => {
      onChangeStatusJust(ticket.id_tickets, 'reopen', v.justify, true);
    };
    const onCancelTicket = (v, ticket) => {
      onChangeStatusJust(ticket.id_tickets, 'cancel', v.justify, true);
      setTimeout(() => {
        setReloadTicket(!reloadTicket);
      }, 1000);
    };

    const removeFileTask = (file) => {
      const data = {
        fk_id_task: file.id_tasks,
        fk_id_tickets: ticket.id_tickets,
        namefile: file.namefile
      }
      action.execute('post', APIEndpoints.TASKS + '/file/' + file.id_tasks_files, '', data).then(res => {
        setReloadTicket(!reloadTicket);
      })

    }

    const sendConnectTickets = (values) => {
      let tempTickets = { id_tickets_con: values.association };

      if (values.association.length != 0) {
        sendConnectTicketsNew(tempTickets, ticket.id_tickets);
      }
    };

    const onFormSubmitTab_ = () => {
      const fields = [];
      let campossize = false;
      let camposobrigatorios = false;
      let camposobrigatoriosradio = false;
      let camposobrigatorioscheck = false;

      if ($('#template').html() != undefined) {
        $('#template textarea').each(function () {
          const textarea = $(this);
          textarea.attr('value', textarea.val());
          textarea.html(textarea.val());

          fields.push({
            name: textarea.attr('name'),
            value: textarea.val(),
          });

          const label = $(this).parent().find('label');
          if (label.text().split('*')[1] != undefined) {
            if (label.text().split('*')[1].length >= 0) {
              if (!textarea.val()) {
                camposobrigatorios = true;
                textarea.css('border-color', 'red');
              } else {
                textarea.css('border-color', 'var(--primary)');
              }
            }
          }
        });

        $('#template input[type!=hidden]').each(function () {
          if ($(this).attr('type') == 'radio') {
            // $(this).removeAttr('checked');

            if ($(this).is(':checked')) {
              $(this).attr('checked', true);
              fields.push({
                name: $(this).attr('name'),
                value: $(this).attr('value'),
              });
            }

            let ip = $(this);

            $('#template label').each(function () {
              var for_ = '';
              try {
                for_ = $(this).attr('for');
              } catch (e) { }

              if (for_ === ip.attr('name')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    camposobrigatoriosradio = true;
                    $('[name=' + for_ + ']').each(function () {
                      try {
                        if ($(this).attr('checked')) {
                          camposobrigatoriosradio = false;
                        }
                      } catch (e) { }
                    });

                    $(this).css('color', camposobrigatoriosradio ? 'red' : 'var(--primary)');
                  }
                }
              }
            });
          } else if ($(this).attr('type') == 'checkbox') {
            if (
              $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
              $(this).attr('id')
            ) {
              $(this).attr('checked', true);
              fields.push({
                name: $(this).attr('name'),
                value: $(this).attr('value'),
              });
            } else {
              $(this).removeAttr('checked');
            }
            let ip = $(this);
            $('#template  label').each(function () {
              var for_ = ' ';
              try {
                for_ = $(this).attr('for');
              } catch (e) { }

              if (ip.attr('name').indexOf(for_) > -1) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    camposobrigatorioscheck = true;
                    $('#template  input').each(function () {
                      try {
                        if ($(this).attr('name').indexOf(for_) > -1) {
                          try {
                            if ($(this).attr('checked')) {
                              camposobrigatorioscheck = false;
                            }
                          } catch (e) { }
                        }
                      } catch (e) { }
                    });
                    $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                  }
                }
              }
            });
          } else if ($(this).attr('type') == 'datetime-local') {
            $(this).attr('value', $(this).val());
            const userLogin = Store.getUserLoged();
            const timezone = userLogin?.timezone?.value;

            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: moment($(this).val()).tz(timezone).utc().toISOString(),
            });
            let ip = $(this);
            $('#template label').each(function () {
              if ($(this).attr('for') === ip.attr('id')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    if (ip.attr('value').length == 0) {
                      ip.css('border-color', 'red');
                      camposobrigatorios = true;
                    } else {
                      ip.css('border-color', 'var(--primary)');
                    }
                  }
                }
              }
            });
          } else {
            $(this).attr('value', $(this).val());
            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: $(this).attr('value'),
            });
            let siz = $(this).attr('max');
            let min = $(this).attr('min');
            if (siz) {
              if (
                parseInt($(this).val().length) > parseInt(siz) ||
                parseInt($(this).val().length) < parseInt(min)
              ) {
                $(this).css('border-color', 'red');
                $(this).before(
                  `<label  id="formtemplabel__" style='color:red'>  ${min || 0
                  } á ${siz || 0} caracteres</label>`
                );
                campossize = true;
              }
            }

            let ip = $(this);
            $('#template label').each(function () {
              if ($(this).attr('for') === ip.attr('id')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    if (ip.attr('value').length == 0 || ip.attr('value') == `(${language.EMPTY})`) {
                      ip.css('border-color', 'red');
                      ip.parent().find('fieldset').css('border-color', 'red');
                      camposobrigatorios = true;
                    } else {
                      ip.css('border-color', 'var(--primary)');
                      ip.parent().find('fieldset').css('border-color', 'var(--primary)');
                    }
                  }
                }
              }
            });
          }
        });

        $('#template select').each(function () {
            const isMultiple = $(this).attr('multiple') !== undefined; // Verifica se é um select múltiplo
            const selectedValues = isMultiple ? $(this).val() : $(this).val() || ""; // Obtém o valor como array ou string

            const label = $(this).parent().find('label');
            if (label.text().split('*')[1] != undefined) {
                if (label.text().split('*')[1].length >= 0) {
                    if (isMultiple ? selectedValues.length === 0 : !selectedValues) { // Verifica se está vazio dependendo do tipo
                        camposobrigatorios = true;
                        $(this).css('border-color', 'red');
                    } else {
                        $(this).css('border-color', 'var(--primary)');
                    }
                }
            }

            fields.push({
                name: $(this).attr('name') || $(this).attr('id'),
                value: selectedValues // Armazena o valor como array ou string
            });
        });

        $('#template label').each(function () {
          try {
            if ($(this).attr('for').indexOf('textarea') > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  // alert($('#'+$(this).attr('for')).text())

                  let node_ = $(this)
                    .parent()
                    .parent()
                    .nextAll()
                    .slice(2, 3)
                    .children()[0];
                  if (
                    (node_ &&
                      node_.firstChild.tagName == 'P' &&
                      node_.firstChild.textContent.length == 0) ||
                    (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                  ) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          } catch (e) { }
        });
      }

      if (
        camposobrigatorios == false &&
        campossize == false &&
        camposobrigatorioscheck == false &&
        camposobrigatoriosradio == false
      ) {
        const ticketUpdated = { ...ticket };
        ticketUpdated.fields = fields;
        try {
          ticketUpdated.template = ticket.config[0]?.forms[0]?.html;
        } catch { }

        // createRandomToken($('#template').html());
        try {
          onUpdateTemplateHtml(ticketUpdated);
          store.toggleAlert(true, language.UPDATED_FIELDS, 'success');

          action
            .execute(
              'get',
              APIEndpoints.TICKET + '/' + ticket.id_tickets,
              'ticket'
            )
            .then((res) => {
              action.execute(
                'get',
                APIEndpoints.TICKET + '/template/html/' + ticket.id_tickets,
                ''
              ).then((e) => {
                try {
                  setHtmlValues(JSON.parse(e.data.html_values));
                  setHtmlFormAvaliationValues(JSON.parse(e.data.form_avaliation_values || '[]'));
                } catch { }
              });
            });
        } catch {
          store.toggleAlert(true, language.THERE_WAS_AN_ERROR_SAVING_THE_FIELDS, 'error');
        }
      } else {
        store.toggleAlert(true, language.FILL_IN_ALL_FIELDS, 'error');
      }
    }

    let forms = null;
    let buttonCloseTemplate = null;
    if (ticket.config != undefined) {
      if (ticket.config[0].forms != undefined) {
        let c = 0;
        let idddd = 0;
        forms = (
          ticket.config[0].forms.filter(
            (f) => f.html != null && f.html != ''
          ) || []
        ).map((obj) => {
          c = c + 1;
          let id = 'ID' + c;
          buttonCloseTemplate = '';
          idddd = obj.id_template_ticket;

          const isRoleToEdit = user.role === 'tech' ||
            user.role === 'scm' || user.scm ||
            user.role === 'admin' || user.role === 'client';

          const isFormEditableForUser = ticket.status == 'Open' ||
            ticket.status == 'Reopened' ||
            ticket.status == 'In Progress';

          return (
            <div className="contain">
              <div></div>
              <div className="itemTicketDetail">
                {isRoleToEdit && isFormEditableForUser ? (
                  <>
                    <div id="template">
                      {formshtml(applyTimezoneOnHTML(decryp(obj.html), htmlValues), htmlValues, true)}
                    </div>
                    <Button
                      icon="fas fa-save"
                      variant="normal"
                      color="success"
                      fluid
                      onClick={() => onFormSubmitTab_()}
                      loading={store.$btn_loading('post' + '-' + APIEndpoints.TICKET)}
                      style={{ height: 40 }}
                      label={language.SAVE}
                    />
                  </>
                ) : (
                  <div id="template">
                    {formshtml(applyTimezoneOnHTML(decryp(obj.html), htmlValues), htmlValues, false, true)}
                  </div>
                )}
              </div>
              <div style={{ width: '200px;' }}></div>

              <div style={{ marginTop: '80px' }}></div>
              <div>
                {obj.user_up != null ? (
                  <div>
                    <div>
                      <i>{language.LAST_UPDATE}:</i>
                    </div>
                    <div>
                      {' '}
                      <i>
                        {' '}
                        {language.USER}: {obj.user_up}
                      </i>
                    </div>
                    <div>
                      {' '}
                      <i>{moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')}</i>
                    </div>
                    <div>
                      {' '}
                      <i>{language.TIME_SPENT}</i>
                    </div>
                    <div>
                      {' '}
                      <i> {obj.tempo} </i>
                    </div>
                  </div>
                ) : (
                  (saveTemplate = false)
                )}
              </div>
              <hr></hr>
            </div>
          );
        });
      } else {
      }
    }

    const getBackStatus = (status) => {
      if (status == 'AGUARDANDO') {
        return 'rgb(240 31 31 / 19%)';
      }
      if (status == 'INICIADO') {
        return '#f0971f30';
      }

      if (status == 'ANDAMENTO') {
        return '#f0971f30';
      } else {
        return 'rgb(52, 172, 160, 0.35)';
      }
    };

    const getColorStatus = (status) => {
      if (status == 'AGUARDANDO') {
        return 'rgb(240 31 31)';
      }
      if (status == 'INICIADO') {
        return 'rgb(240, 151, 31)';
      }

      if (status == 'ANDAMENTO') {
        return 'rgb(240, 151, 31)';
      } else {
        return 'rgba(52, 172, 160)';
      }
    };

    const getAssociateTickets = async (filter = '') => {
      action
        .execute('get', `${APIEndpoints.TICKET}/contract/${ticket.id_tickets}/${filter}`, '')
        .then((res) => {
          const tickets = (res.data || []).map((e) => ({
            value: e.id_tickets,
            label:
              '#' +
              e.realid +
              '  ' +
              language.SOLICITANT +
              ': ' +
              e.namesol +
              ' / ' +
              language.SUBJECT +
              ': ' +
              e.subject,
          }));
          setTicketsConnect(tickets);
        })
        .catch((error) => {
          console.error({ error });
          setTicketsConnect([]);
        });
    };

    const openAssociateOpen = async (ticket) => {
      if (onActiveRefresh) {
        set
        onActiveRefresh();
      }

      await getAssociateTickets();
      setOpenAssociate(true);
    };

    const handleFilterAssociationgTicket = (filter) => {
      getAssociateTickets(filter);
    };

    let formAssociateTicket = [
      {
        span: 12,
        name: 'association',
        title: language.ASSOCIATING_TICKET_CLOSE,
        type: 'listCheckbox',
        handleFilter: handleFilterAssociationgTicket,
        options: ticketsConnect,
        selectAll: false,
      },
    ];

    let formLocationTicket = [
      {
        span: 12,
        name: 'fk_id_location',
        label: language.LOCATION_TITLE,
        type: 'select',
        options: locationOptions,
      },
    ];

    const handleSubmitLocation = (values, ticket) => {
      action.execute(
        'post',
        `${APIEndpoints.TICKET}/connect/${ticket.id_tickets}/location`,
        '',
        values
      ).then((h) => {
        Store.toggleAlert(
          true,
          language.ALERT_UPDATE_SUCCESS,
          'success'
        );

        action
          .execute(
            'get',
            APIEndpoints.TICKET + '/' + ticket.id_tickets,
            'ticket'
          )
          .then((success) => {
            setReloadTicket(true);
            setReloadTicket(false);
            setLoad(true);
            setModalLocation(false);
          }, (error) => {
            setModalLocation(false);
          });
      }, (error) => {
        Store.toggleAlert(
          true,
          language.ERROR_REQUEST,
          'error'
        );
      });
    }

    let url = window.location.href;

    const cancelButton =
      user.role == 'solicitant' &&
        ticket.status != 'Resolved' &&
        user.role == 'tech' &&
        ticket.status === 'Open' &&
        params &&
        params[0].integration == true ? (
        <Button
          icon="fas fa-trash-restore-alt"
          variant="normal"
          color="success"
          label={language.CANCEL}
          size={0.9}
          zoom={0.9}
          style={{ fontSize: 14 }}
          onClick={() => setcancelModal(true)}
        />
      ) : null;
    let closedButton =
      ticket.status === 'Resolved' &&
        ticket.user_solicit[0].id_user == user.id ? (
        <Button
          icon="fas fa-times-circle"
          variant="normal"
          color="success"
          label={language.TICKET_STATUS.CLOSEDDETAIL}
          size={0.9}
          zoom={0.9}
          style={{ fontSize: 14, marginLeft: 10 }}
          onClick={() =>
            onChangeStatus_Tab(ticket.id_tickets, { action: 'closed' })
          }
        />
      ) : null;
    let reopenButton =
      ticket.status === 'Resolved' &&
        (params[0].integration == true
          ? user.role == 'client' || user.role == 'tech'
          : user.role == 'client' ||
          ticket.user_solicit[0].id_user == user.id) ? (
        <Button
          icon="fas fa-lock-open"
          variant="normal"
          color="success"
          label={language.REOPEN_TICKET}
          size={0.9}
          zoom={0.9}
          style={{ fontSize: 14, marginLeft: 5 }}
          onClick={() => setOpenReopen(true)}
        />
      ) : null;

    let recorrenBtn = '';
    if (ticket.recurrence > 1) {
      recorrenBtn =
        (store.getUserLoged().role != 'solicitant') ? (
          <Button
            icon=""
            variant="normal"
            color="success"
            label={language.RECURRENCE_STOP}
            size={0.9}
            zoom={0.9}
            onClick={() => updateRecorrence(ticket)}
            style={{ fontSize: 14, marginLeft: 5 }}
          />
        ) : null;
    }

    try {
      ticket.real_id = ticket.config[0].real_id;
    } catch (e) { }

    let gmudm =
      store.getUserLoged().role != 'solicitant' &&
        !isReadonly &&
        (ticket.status == 'Open' ||
          ticket.status == 'Reopened' ||
          ticket.status == 'In Progress') ? (
        <>
          <div className="d-flex align-items-center">
            <Button
              icon="fas fa-times"
              variant="circle"
              color="danger"
              label={language.CANCEL_CALL}
              size={0.9}
              zoom={0.9}
              style={{ fontSize: 14, marginLeft: 5 }}
              onClick={() => setcancelModal(true)}
            />
            {!hasTasksPending && (
              <div className="d-flex align-items-center ">
                <button
                  className='migrate-button'
                  onClick={(event) => setAnchorElMigrate(event.currentTarget)}
                  style={{
                    padding: '5px 10px 5px 10px',
                    marginLeft: 5,
                    background: '#33aba0',
                    borderRadius: 4,
                    fontSize: 12,
                    fontWeight: 600,
                    color: '#ffffff'
                  }}
                >
                  <div style={{ display: 'flex', fontFamily: 'Open Sans', color: '#fdfdfdf2' }}>
                    <ArrowDownward style={{ fontSize: 16, marginTop: 3, marginRight: 2 }} />
                    {language.ACTIONS_TICKET}
                  </div>
                </button>
              </div>
            )}
          </div>
          <Menu
            id="customized-menu"
            anchorEl={anchorElMigrate}
            keepMounted
            open={Boolean(anchorElMigrate)}
            onClose={() => setAnchorElMigrate(null)}
          >
            <MenuItem onClick={() => {
              let { toggleAlert, language } = store;
              store.spinner = true;
              action
              .execute('post', `${APIEndpoints.TICKET}/reprocess-sla/${ticket.id_tickets}`, '')
              .then((res) => {
                store.spinner = false;
                toggleAlert(true, language.ALERT_UPDATE_SUCCESS, 'success');
                setTimeout(() => handleDetail(ticket.id_tickets), 1000);
              })
              .catch((error) => {
                let messageError = language.ERROR_REQUEST;
                if (error.data?.id_error === '545d3afb-f64c-450f-bcea-4ce35d3a247c') {
                  messageError = language.STATUS_NOT_ALLOWED;
                }
                store.spinner = false;
                toggleAlert(true, messageError, 'error');
              });
            }}>
              {language.REPROCESS_SLA}
            </MenuItem>
            <MenuItem onClick={() => redirect2('/dash/gp', ticket)}>
              {language.MIGRATE_TO_PROJECT}
            </MenuItem>
            <MenuItem onClick={() =>
              redirect('/dash/changemanagement', {
                ticket: {
                  ...ticket,
                  name_company: ticket.company[0].name_company,
                  name_solicit: ticket.user_solicit[0].name_person,
                  fk_id_solicit: ticket.user_solicit[0].id_user,
                  fk_id_contract: ticket.config[0].fk_id_contract,
                  real_id: ticket.config[0].real_id,
                  subject: ticket.subject,
                },
              })
            }>
              {language.MIGRATE_TO_GMUD}
            </MenuItem>
            <MenuItem onClick={() => setOpenMigrateOS(true)}>
              {language.MIGRATE_TO_OS}
            </MenuItem>
            {(ticket.type_tickets === 'Incident' &&
              !isReadonly &&
              (ticket.status === 'In Progress' || ticket.status === 'Reopened') &&
              user.role != 'solicitant') && !hasTasksPending ? (
              <MenuItem onClick={() => onChangeStatus_Tab(
                ticket.id_tickets,
                { action: 'migrate' },
                ticket)}
              >
                {language.MIGRATE_TO_PROBLEM}
              </MenuItem>) : null}
            <MenuItem
              onClick={() =>
                ticket.config[0].arraypr.sprint == null ? getSprintLow() : null}
            >
              {ticket.config[0].arraypr.sprint == null
                ? language.ADD_SPRINT
                : ticket.config[0].arraypr.sprint}
            </MenuItem>
            {
              (
                ['Open', 'In Progress', 'Reopened'].includes(ticket.status) &&
                [1, 4].includes(ticket.id_type) &&
                user.role != 'solicitant' &&
                !isReadonly
              )
              && (
                <MenuItem onClick={() => handleOpenMigrateCrisis()}>
                  {language.MIGRATE_TO_CRISIS}
                </MenuItem>
              )
            }
          </Menu>
        </>
      ) : null;

    let avali = null;

    if (
      params[0].evaluate_ticket == true &&
      user.id == ticket.fk_id_user &&
      (ticket.status == 'Resolved' || ticket.status == 'Closed')
    ) {
      avali = (
        <Button
          icon="fas  fa-user-tag"
          variant="normal"
          color="success"
          label={language.EVALUATE_CALL}
          size={0.9}
          zoom={0.9}
          style={{ fontSize: 14, marginLeft: 5 }}
          onClick={() =>
            window.open(ticket.config[0].avaliation_token, '_blank')
          }
        ></Button>
      );
    }

    const buttons = (delay, setDelay) => (
      <div className="d-flex align-items-center">
        {(user.role != 'solicitant' &&
          !isReadonly &&
          ticket.status === 'In Progress' &&
          ticket.config[0].arraypr.sub_tickets == true) && !hasTasksPending ? (
          ticket.config[0].real_id.split('.').length < 4 ? (
            <Button
              variant="normal"
              label={language.SUB_CALL}
              color="warning"
              onClick={() => setSubTicket(true)}
              style={{ marginRight: '10px' }}
            />
          ) : null
        ) : null}

        <Button
          variant="normal"
          icon={<KnowledgeBase style={{ fill: 'white', width: 20 }} />}
          label={language.KNOWLEDGE_BASE}
          color="warning"
          onClick={() => openSolution(true)}
        />
        {avali}
        {recorrenBtn}
        {buttonCloseTemplate}
        {gmudm}
        {cancelButton}
        {closedButton}
        {reopenButton}
        {(ticket.status === 'Open' &&
          user.role != 'solicitant' &&
          !isReadonly) && !hasTasksPending ? (
          <Button
            icon="fas fa-play"
            variant="normal"
            color="success"
            label={language.START_ATTENDANCE}
            size={0.9}
            zoom={0.9}
            style={{ fontSize: 14, marginLeft: 5 }}
            onClick={() => {
              if (delay == false) {
                setDelay(true);
                setTimeout(() => {
                  setDelay(false);
                }, 6000);
                onChangeStatus_Tab(
                  ticket.id_tickets,
                  { action: 'resolve' },
                  ticket
                );
              }
            }}
          />
        ) : (ticket.status === 'In Progress' || ticket.status === 'Reopened') &&
          !isReadonly &&
          ticket.type_tickets === 'Problem' &&
          ticket.time_analyze != null &&
          ticket.time_solution_development != null &&
          ticket.time_problem_identification != null &&
          ticket.time_test ? (
          <Button
            icon="fas fa-stop"
            variant="normal"
            color="success"
            label={language.END_CALL}
            size={0.9}
            zoom={0.9}
            style={{ fontSize: 14, marginLeft: 5 }}
            onClick={() => setModalResolution(true)}
          />
        ) : ticket.status === 'In Progress' &&
          !isReadonly &&
          saveTemplate === true &&
          forms.length > 0 ? (
          <Button
            icon="fas fa-stop"
            variant="normal"
            color="success"
            size={0.9}
            zoom={0.9}
            style={{ fontSize: 14, marginLeft: 5 }}
            label={language.END_CALL}
            onClick={this.handleModal}
          />
        ) : (
          ''
        )}

        {user.role != 'solicitant' &&
          !isReadonly &&
          (ticket.status === 'Reopened' ||
            ticket.status === 'In Progress' ||
            ticket.status === 'Job') &&
          (user.perfil.gsd_categorization == null ||
            user.perfil.gsd_categorization == true) ? (
          <Button
            icon="fas fa-edit"
            variant="normal"
            color="success"
            size={0.9}
            zoom={0.9}
            style={{ fontSize: 14, marginLeft: 5 }}
            label={language.CATEGORIZE}
            onClick={() => setCategorize(true)}
          />
        ) : null}
        {params[0].time_sheet == true &&
          ticket.config[0].enable_time_sheet == true &&
          (ticket.status === 'In Progress' ||
            ticket.status === 'Open' ||
            ticket.status === 'Reopened') &&
          ticket.config[0].fk_id_tech == Store.getUserLoged().id ? (
          <Button
            icon="fas fa-stop"
            variant="normal"
            color="danger"
            label={language.STOP_ATTENDANCE}
            size={0.9}
            zoom={0.9}
            style={{ fontSize: 14, marginLeft: 5 }}
            onClick={() => {
              if (delay == false) {
                setDelay(true);
                setTimeout(() => {
                  setDelay(false);
                }, 4000);
                onNotesTicketTabs(
                  ticket.id_tickets,
                  {
                    progress: ticket.config[0].progress || 0,
                    time: document.getElementById('timesheet_ticketdetail')
                      .innerHTML,
                    pause: true,
                    notes: language.ATTENDANCE_STOPPED,
                  },
                  url,
                  ticket.type_tickets
                );
                onChangeStatus_Tab(
                  ticket.id_tickets,
                  { action: 'resolve', stop_call: true },
                  ticket
                );
              }
            }}
          />
        ) : null}

        {params[0].time_sheet == true &&
          !isReadonly &&
          user.role != 'solicitant' &&
          !isReadonly &&
          params &&
          (((ticket.config[0].enable_time_sheet == false ||
            ticket.config[0].enable_time_sheet == null) &&
            (ticket.config[0].progress > 0 ||
              ticket.config[0].problem_identification_percent > 0 ||
              ticket.status == 'In Progress' ||
              ticket.status == 'Reopened')) ||
            (ticket.config[0].enable_time_sheet == true &&
              ticket.config[0].fk_id_tech != Store.getUserLoged().id)) ? (
          <Button
            icon="fas fa-play"
            variant="normal"
            color="danger"
            label={language.RESUME_TICKET}
            size={0.9}
            zoom={0.9}
            style={{ fontSize: 14, marginLeft: 5 }}
            onClick={() => {
              if (delay == false) {
                setDelay(true);
                setTimeout(() => {
                  setDelay(false);
                }, 4000);
                onChangeStatus_Tab(
                  ticket.id_tickets,
                  { action: 'resolve' },
                  ticket
                );
              }
            }}
          />
        ) : (
          ''
        )}
      </div>
    );

    let techgroups = [];
    let usersTicket = [];
    let values = {
      fk_id_teck_new: 'fk_id_teck_new',
      id_tickets: ticket.id_tickets,
    };
    let values_ = {
      cancel: true,
      id_tickets: ticket.id_tickets,
    };

    useEffect(() => {
      getIsAnAssociatedTicket(ticket.id_tickets);

      setTimeout(() => {
        var items = (ticket.config[0].filesrequest || [])
          .map((f) => ({
            name:
              f.file +
              ' - ' +
              moment(f.dt_cad).format('DD/MM/YYYY HH:mm') +
              ' - ' +
              ticket.user_solicit[0].name_person,
            size: f.size,
            type: f.type,
            nn: f.file,
            id_tickets_file: f.id_request_file,
            fk_id_request: f.id_request,
            user_cad: f.dt_cad,
            id_user: ticket.fk_id_user,
          }))
          .concat(
            (ticket.config[0].files || []).map((f) => ({
              name:
                f.file +
                ' - ' +
                moment(f.dt_cad).format('DD/MM/YYYY HH:mm') +
                ' - ' +
                f.user_cad,
              size: f.size,
              type: f.type,
              nn: f.file,
              id_tickets_file: f.id_tickets_file,
              id_tickets: f.id_tickets,
              user_cad: f.user_cad,
              id_user: f.id_user,
              fk_id_pai: f.fk_id_pai,
            }))
          );

        (ticket.config[0].files || []).map((f) => { });

        setLocalFiles(items);
      }, 3000);
    }, [ticket.id_tickets]);
    let recurrenceList = [
      '',
      language.RECURRENCE_TYPE.NO_RECURRENCE,
      language.RECURRENCE_TYPE.DAILY,
      language.RECURRENCE_TYPE.WEEKLY,
      language.RECURRENCE_TYPE.MONTHLY,
      language.RECURRENCE_TYPE.QUARTERLY,
      language.RECURRENCE_TYPE.SEMESTER,
      language.RECURRENCE_TYPE.YEARLY,
      language.SCHEDULING_SINGLE,
      language.FORTNIGHTLY,
    ];
    const [ticketsManagement, setTicketsManagement] = useState([]);
    const [percentTasksFinish, setPercentTasksFinish] = useState(null);
    const [editTask, setEditTask] = useState(false);
    const [ticketTask, setTicketTask] = useState(null);
    const [openModalDeleteTask, setOpenModalDeleteTask] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(false);
    const [reloadTicket, setReloadTicket] = useState(false);
    let refAliceCarousel = useRef(null);
    const [ticketInfo, setTicketInfo] = useState(
      ticket
        ? {
          idTicket: {
            id: ticket.id_tickets,
            value: `${ticket.real_id} - ${ticket.subject}`,
          },
        }
        : null
    );

    const [openStatusChange, setOpenStatusChange] = useState(false);
    const [statusSelected, setStatusSelected] = useState();
    const [ticketDetails, setTicketDetails] = useState(null);

    const handleSelect = (value) => {
      setStatusSelected(value);
    };

    useEffect(() => {
      const taskU = {
        idTicket: {
          id: ticket.id_tickets,
          value: `${ticket.real_id} - ${ticket.subject}`,
        },
      };
      setTicketTask(taskU);
    }, []);
    let reload = false;

    useEffect(() => {
      action
        .execute('get', `${APIEndpoints.TASKS}/ticket/${ticket.id_tickets}`, '')
        .then((res) => {
          if (res.data.length > 0) {
            const totalTasks = res.data.length;
            const totalFinishTasks = res.data.filter(
              (d) => d.tasks[0].date_end !== null
            ).length;

            const percent = (totalFinishTasks * 100) / totalTasks;

            setPercentTasksFinish(percent);
            let has_pending_tasks = false
            const newTickets = (res.data || []).map((ticket, index) => {
              const existOpenPredecessors =
                ticket.predecessors.length &&
                !['CONCLUIDO', 'CANCELADO', 'FALHA', 'APROVADO'].includes(
                  ticket.predecessors[0].status
                );
              if (['PENDENTE APROVAÇÃO'].includes(ticket.tasks[0].status) && params[0].block_ticket_with_tasks_pendent == true) {
                has_pending_tasks = true
              }
              return {
                ...ticket,
                existOpenPredecessors,
              };
            });
            setHasTasksPending(has_pending_tasks)
            setTicketsManagement(newTickets);
          } else {
            setTicketsManagement([]);
          }
        })
        .catch((error) => {
          console.error({ error });
          setTicketsManagement([]);
        });
    }, [reloadTicket]);
    let [valiationOptions, setValiationOptions] = useState([]);
    const [anchorElTask, setAnchorElTask] = React.useState(null);
    const openTaskMenu = Boolean(anchorElTask);

    const handleClickTask = (event) => {
      setAnchorElTask(event.currentTarget);
    };

    const handleCloseTask = () => {
      setAnchorElTask(null);
    };

    const classes = useStyles();

    function handleEditTask(task) {
      setEditTask(true);
      const taskU = {
        predecessors: task,
        tasksInfo: task.tasks[0],
        title: task.tasks[0].name,
        idTicket: {
          id: ticket.id_tickets,
          value: ticket.subject,
        },
      };
      setTicketTask(taskU);
      setTimeout(() => { }, 1000);
    }

    function handleOpenStatus(task) {
      setFilesTask([]);
      setOpenStatusChange(true);
      setTicketDetails(task);
      setStatusSelected(task.status);
    }

    const submitStatus = () => {
      let fields = [];

      $('#formatasktemplate textarea').each(function () {
        $(this).attr('value', $(this).val());

        $(this).html($(this).val());
        fields.push({ name: $(this).attr('name'), value: $(this).val() });
      });

      $('#formatasktemplate input').each(function () {
        if ($(this).attr('type') == 'radio') {
          if (
            $('input[name=' + $(this).attr('name') + ']:checked').val() ==
            $(this).val()
          ) {
            $(this).attr('checked', true);
            let value = undefined;
            try {
              value = $(this).parent().children()[1].textContent;
            } catch (e) { }
            fields.push({
              name: $(this).attr('name'),
              value: value || $(this).val(),
            });
          } else {
            $(this).attr('checked', false);
          }
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({ name: $(this).attr('name'), value: $(this).val() });
          } else {
            $(this).attr('checked', false);
          }
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          $(this).attr('value', $(this).val());
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });

          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${min || 0
                } á ${siz || 0} caracteres</label>`
              );

              campossize = true;
            }
          }
          let ip = $(this);
          $('#ID1 label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length == 0) {
                  if (ip.attr('value').length == 0) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          });
        }
      });

      $('#formatasktemplate select').each(function () {
        $('#' + $(this).children('option:selected').attr('id')).attr(
          'selected',
          'selected'
        );

        fields.push({
          name: $(this).attr('name') || $(this).attr('id'),
          value: $(this).find(':selected').text(),
        });
      });
      let html = $('#formatasktemplate').html();
      let filesSend = [];
      let cont = 0;

      if (filesTask.length > 0) {
        Array.from(filesTask).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;

            if (filesTask.length == cont) {
              let values = {
                status: statusSelected,
                html: html,
                fields: fields,
                files: filesSend,
              };
              action
                .execute('put', `${APIEndpoints.TASKS}/status/${ticketDetails.id_tasks}`, '', values)
                .then((res) => {
                  setOpenStatusChange(false);
                  action
                    .execute(
                      'get',
                      APIEndpoints.TICKET + '/' + ticket.id_tickets,
                      'ticket'
                    )
                    .then((g) => {
                      setReloadTicket(true);
                      setReloadTicket(false);
                      setLoad(true);
                    })
                    .catch((err) => console.error(err));
                })
                .catch((err) => console.error(err));
            }
          });
        });
      } else {
        if (filesTask.length == 0) {
          let values = {
            status: statusSelected,
            html: html,
            fields: fields,
            files: [],
          };
          action
            .execute('put', `${APIEndpoints.TASKS}/status/${ticketDetails.id_tasks}`, '', values)
            .then((e) => {
              setOpenStatusChange(false);
              //setReloadTicket(!reloadTicket);
              action
                .execute(
                  'get',
                  APIEndpoints.TICKET + '/' + ticket.id_tickets,
                  'ticket'
                )
                .then((g) => {
                  setReloadTicket(true);
                  setReloadTicket(false);
                  setLoad(true);
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        }
      }
    };

    function handleDeleteTask() {
      action
        .execute('delete', `${APIEndpoints.TASKS}/${taskToDelete.tasks[0].id_tasks}`, '')
        .then((e) => {
          setOpenModalDeleteTask(false);
          setReloadTicket(!reloadTicket);
        })
        .catch((error) => {
          console.error({ error });
        });
    }

    const optionStatus = [
      { value: 'AGUARDANDO', label: language.TASK_STATUS.WAITING },
      { value: 'INICIADO', label: language.TASK_STATUS.STARTED },
      { value: 'ANDAMENTO', label: language.TASK_STATUS.IN_PROGRESS },
      { value: 'CONCLUIDO', label: language.TASK_STATUS.DONE },
      { value: 'CANCELADO', label: language.TASK_STATUS.CANCELED },
      { value: 'FALHA', label: language.FAILURE },
    ];

    function openModalToDelete(task) {
      setOpenModalDeleteTask(true);
      setTaskToDelete(task);
    }

    function relaodTask() {
      setEditTask(false);
      setTicketTask(null);
      setReloadTicket(!reloadTicket);
    }

    useEffect(() => {
      relaodTask();
    }, [ticket.id_tickets]);

    useEffect(() => {
      action
        .execute(
          'get',
          APIEndpoints.TECH_EVALUATION_REGISTRATION,
          'tech_evaluation_registration'
        )
        .then((e) => {
          setValiationOptions(
            (e.data || []).map((t) => ({
              value: t.id_tech_evaluation_registration,
              label: t.status,
            }))
          );
        });
    }, []);

    const saveFormTech = () => {
      let camposobrigatorios = false;
      let camposobrigatoriosradio = false;
      let camposobrigatorioscheck = false;
      let campossize = false;
      let fields = [];
      let _values = {};
      $('#formavaliation').html();
      if ($('#formavaliation').html() != undefined) {
        $('#formavaliation textarea').each(function () {
          $(this).attr('value', $(this).val());

          $(this).html($(this).val());
          fields.push({ name: $(this).attr('name'), value: $(this).val() });
        });

        $('#formavaliation input').each(function () {
          if ($(this).attr('type') == 'radio') {
            if (
              $('input[name=' + $(this).attr('name') + ']:checked').val() ==
              $(this).val()
            ) {
              $(this).attr('checked', true);
              let value = undefined;
              try {
                value = $(this).parent().children()[1].textContent;
              } catch (e) { }
              fields.push({
                name: $(this).attr('name'),
                value: value || $(this).val(),
              });
            } else {
              $(this).removeAttr('checked');
            }
            let ip = $(this);

            $('#formavaliation  label').each(function () {
              var for_ = '';
              try {
                for_ = $(this).attr('for');
              } catch (e) { }

              if (for_ === ip.attr('name')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    camposobrigatoriosradio = true;
                    $('[name=' + for_ + ']').each(function () {
                      try {
                        if ($(this).attr('checked')) {
                          camposobrigatoriosradio = false;
                        }
                      } catch (e) { }
                    });

                    $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                  }
                }
              }
            });
          } else if ($(this).attr('type') == 'checkbox') {
            if (
              $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
              $(this).attr('id')
            ) {
              $(this).attr('checked', true);
              fields.push({
                name: $(this).attr('name'),
                value: $(this).attr('value'),
              });
            } else {
              $(this).removeAttr('checked');
            }
            let ip = $(this);
            $('#formavaliation  label').each(function () {
              var for_ = ' ';
              try {
                for_ = $(this).attr('for');
              } catch (e) { }

              if (ip.attr('name').indexOf(for_) > -1) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    camposobrigatorioscheck = true;
                    $('#formavaliation  input').each(function () {
                      try {
                        if ($(this).attr('name').indexOf(for_) > -1) {
                          try {
                            if ($(this).attr('checked')) {
                              camposobrigatorioscheck = false;
                            }
                          } catch (e) { }
                        }
                      } catch (e) { }
                    });

                    $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                  }
                }
              }
            });
          } else if ($(this).attr('type') == 'datetime-local') {
            const userLogin = Store.getUserLoged();
            const timezone = userLogin?.timezone?.value;

            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: moment($(this).val()).tz(timezone).utc().toISOString(),
            });
            $(this).attr('value', $(this).val());
            let ip = $(this);
            $('#template  label').each(function () {
              if ($(this).attr('for') === ip.attr('id')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    if (ip.attr('value').length == 0) {
                      ip.css('border-color', 'red');
                      camposobrigatorios = true;
                    } else {
                      ip.css('border-color', 'var(--primary)');
                    }
                  }
                }
              }
            });
          } else {
            $(this).attr('value', $(this).val());
            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: $(this).attr('value'),
            });
            let siz = $(this).attr('max');
            let min = $(this).attr('min');
            if (siz) {
              if (
                parseInt($(this).val().length) > parseInt(siz) ||
                parseInt($(this).val().length) < parseInt(min)
              ) {
                $(this).css('border-color', 'red');
                $(this).before(
                  `<label  id="formtemplabel__" style='color:red'>  ${min || 0
                  } á ${siz || 0} caracteres</label>`
                );

                campossize = true;
              }
            }

            let ip = $(this);
            $('#formavaliation  label').each(function () {
              if ($(this).attr('for') === ip.attr('id')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    if (ip.attr('value').length == 0 || ip.attr('value') == `(${language.EMPTY})`) {
                      ip.css('border-color', 'red');
                      camposobrigatorios = true;
                    } else {
                      ip.css('border-color', 'var(--primary)');
                    }
                  }
                }
              }
            });
          }
        });

        $('#formavaliation select').each(function () {
          const opt = $('#' + $(this).children('option:selected').attr('id')).attr(
            'selected',
            'selected'
          );

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).find(':selected').text(),
          });

          if (!$(this).find(':selected').text()) {
            opt.parent().css('border-color', 'red');
            camposobrigatorios = true;
          } else {
            opt.parent().css('border-color', 'var(--primary)');
          }
        });

        $('#formavaliation label').each(function () {
          try {
            if ($(this).attr('for').indexOf('textarea') > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  // alert($('#'+$(this).attr('for')).text())

                  let node_ = $(this)
                    .parent()
                    .parent()
                    .nextAll()
                    .slice(2, 3)
                    .children()[0];
                  if (
                    (node_ &&
                      node_.firstChild.tagName == 'P' &&
                      node_.firstChild.textContent.length == 0) ||
                    (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                  ) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          } catch (e) { }
        });
      }

      let html = formavaliation.html; // ticket.config[0].arraypr.form_avaliation;
      //fields
      _values.htmlavaliation = html;
      let id = 0;
      if (id > 0) {
        _values.html = $('#ID1').html();
        _values.id_template_ticket = id;
      } else {
        _values.html = '';
        _values.id_template_ticket = 0;
      }
      if (campossize == false) {
        if (
          camposobrigatorios == false &&
          camposobrigatorioscheck == false &&
          camposobrigatoriosradio == false
        ) {
          _values.fields = fields;
          _values.id_tickets = ticket.id_tickets;
          _values.action = 'saveform';
          _values.template = formavaliation.html;
          action
            .execute(
              'post',
              APIEndpoints.TICKET + '/' + ticket.id_tickets,
              'ticket',
              _values
            )
            .then((g) => { });
            //  onNotesTicketTab(id_ticket, _values, url, type_tickets);
          return true;
        } else {
          toggleAlert(true, language.FILL_REQUIRED, 'error');
          return false;
        }
      } else {
        toggleAlert(true, language.CHECK_FIELD_SIZES, 'error');
        return false;
      }
    };
    let observation = ticket.user_sponsor[0];

    const saveObservation = (values) => {
      values.action = 'description';
      values.id_tickets = ticket.id_tickets;
      action
        .execute(
          'post',
          APIEndpoints.TICKET + '/' + ticket.id_tickets,
          '',
          values
        )
        .then((res) => {
          action
            .execute(
              'get',
              APIEndpoints.TICKET + '/' + ticket.id_tickets,
              'ticket'
            )
            .then((g) => {
              toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');

              //  setLoad(true);
            });
        });
    };

    const handleOpenMigrateCrisis = () => {
      setOpenMigrateCrisis(true);
      setCategorize(true);
    }

    const convertHour = (min) => {
      const hours = Math.floor(min / 60);
      min = min % 60;
      const textHour = `0000${hours}`.slice(-4);
      const textMin = `00${min}`.slice(-2);
      return `${textHour}:${textMin}`;
    };

    function formatDecimalToMinutes(value) {
      if (value === '0.00')
        return '';

      let totalSeconds = value * 60;

      const days = Math.floor(totalSeconds / (24 * 3600));
      totalSeconds -= days * 24 * 3600;
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= hours * 3600;
      const minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= minutes * 60;
      const seconds = Math.floor(totalSeconds);

      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      const formattedTime = `${formattedHours}:${formattedMinutes}`
      if (days > 0) {
        return `${days}D ${formattedTime}`;
      } else {
        return formattedTime;
      }
    }

    let [delay, setDelay] = useState(false);
    const [maxHeight, setMaxHeight] = useState('');

    useEffect(() => {
      function updateStyle() {
        const windowWidth = window.innerWidth;

        if (windowWidth >= 1500) {
          setMaxHeight('calc(100vh - 200px)');
        } else if (windowWidth >= 1400) {
          setMaxHeight('calc(100vh - 160px)');
        } else if (windowWidth >= 1300) {
          setMaxHeight('calc(100vh - 120px)');
        } else if (windowWidth >= 1200) {
          setMaxHeight('calc(100vh - 80px)');
        } else if (windowWidth >= 1100) {
          setMaxHeight('calc(100vh - 20px)');
        } else if (windowWidth >= 1000) {
          setMaxHeight('calc(100vh + 40px)');
        } else if (windowWidth >= 900) {
          setMaxHeight('calc(100vh + 120px)');
        } else if (windowWidth >= 721) {
          setMaxHeight('calc(100vh + 220px)');
        } else {
          setMaxHeight('calc(100vh - 160px)');
        }
      }
      updateStyle();
      window.addEventListener('resize', updateStyle);
    }, []);

    const handleSubmitConfirmCrisis = (values, ticket) => {
      //
      let url = APIEndpoints.TICKET;
      url += values.confirm_crisis
        ? '/confirm-crisis'
        : '/revoke-crisis-confirmation';
      url += `/${ticket.id_tickets}`;

      let { spinner, toggleAlert, language } = store;

      spinner = true;
      action
        .execute('post', url, '')
        .then(() => {
          setModalConfirmCrisis(false);
          spinner = false;
          toggleAlert(true, language.ALERT_UPDATE_SUCCESS, 'success');
          setConfirmCrisis(values.confirm_crisis);
        })
        .catch((error) => {
          let messageError = language.ERROR_REQUEST;
          if (error.data?.id_error === 'af0df5e1-fe0e-44a7-bc3a-db987496a13b') {
            messageError = language.CRISIS_IS_CONFIRMED;
          } else if (
            error.data?.id_error === 'b4835efe-2520-4293-8473-b93822170c8e'
          ) {
            messageError = language.CRISIS_IS_UNCONFIRMED;
          }
          setModalConfirmCrisis(false);
          spinner = false;
          toggleAlert(true, messageError, 'error');
        });
    };

    const formatTicketData = (ticket) => {
      const config = ticket.config && ticket.config[0];
      const arraypr = config ? config.arraypr : null;
      const slaNameParam = arraypr ? arraypr.sla_name_param : null;
      const slaTicket = ticket.sla_ticket;
      const slaDateHrParam = arraypr ? arraypr.sla_date_hr_param : null;
      const slaTask = config ? config.sla_task : null;

      if (slaNameParam) {
        let formattedSlaTicket = '';

        if (slaTicket && slaTicket.length > 0) {
          if (slaTicket.indexOf('-') === 4) {
            formattedSlaTicket = moment(slaTicket).format('DD/MM/YYYY HH:mm:ss');
          } else if (slaTicket.indexOf('-') === 2) {
            formattedSlaTicket = slaTicket;
          } else {
            formattedSlaTicket = ' ';
          }
        } else {
          formattedSlaTicket = slaDateHrParam || ' ';
        }

        return <div>{slaNameParam + " - " + formattedSlaTicket}</div>;
      } else {
        return <div>{slaTask ? slaTask + `h` : ''}</div>;
      }
    }

    const getSlaDisplay = (ticket) => {
      let slaDisplay = []
      slaDisplay.push(formatTicketData(ticket));

      if (params[0].sla_params === true && ticket.config[0].arraypr.sla_date_br_param)
        slaDisplay.push(<Tag label={language.SLA_BROKEN_OUT} color={'var(--danger)'} style={{ fontSize: 'inherit', padding: '10px 5px' }} />)

      return slaDisplay;
    }

    const isDescriptionEditableForUser = ticket.status == 'Open' ||
      ticket.status == 'Reopened' ||
      ticket.status == 'In Progress';
    
    const userLogin = Store.getUserLoged();
    const timezone = userLogin?.timezone?.value;

    return (
      <PageLayout
        title={
          ticket.config[0].real_id +
          ' ' +
          ticket.subject +
          '  ' +
          (ticket.config[0].arraypr.id_integration !== 'undefined' &&
            ticket.config[0].arraypr.id_integration != null
            ? ticket.config[0].arraypr.id_integration
            : '')
        }
        customCss={
          detail
            ? [
              {
                position: 'fixed',
                zIndex: 1000,
                top:
                  window.screen.width >= 1920
                    ? 118
                    : window.screen.width >= 1366
                      ? 113
                      : 115,
                width:
                  window.screen.width >= 1920
                    ? '93%'
                    : window.screen.width >= 1366
                      ? '92%'
                      : '91%',
                marginLeft: 2,
              },
            ]
            : undefined
        }
        titleRoute=""
        back={!noBack}
        rightElements={[]}
      >
        {detail != true && (
          <ControllerTicket
            controlButtonTheme={controlButtonTheme}
            closeDetail={closeDetail}
            abas={abas}
            setLocalFiles={setLocalFiles}
            openTicket={openTicket}
            handleDetail={handleDetail}
          />
        )}
        {openas == true ? (
          <div>
            {ticket.config[0].connection != 'rdp' ? (
              <AssessmentTerminal
                language={language}
                store={store}
                ticketsSelected={ticket}
                finishAssessment={() => finishAssessmentTer()}
                connection={ticket.config[0].connection}
              />
            ) : (
              <GuacamoleView
                language={language}
                user={null}
                password={null}
                domain={null}
                path={store.assessment && store.assessment[0].path}
                finishAssessment={finishAssessment}
                device={devices}
              >
                {' '}
              </GuacamoleView>
            )}
          </div>
        ) : null}
        {openas == false ? (
          <div
            id="TicketDetail"
            style={{
              marginTop: detail ? 50 : 0,
              maxHeight: maxHeight,
              overflowY: 'auto',
            }}
          >
            <Header
              btnFilter={[
                language.DETAILS,
                language.HISTORIC_CALL,
                language.HISTORIC_SLA,
                language.SUB_CALLS,
                language.DEVICE_CONF,
                language.DESIGNATION_HISTORY,
                language.RELATIONS,
              ]}
              onChange={(e) => setPage(e + 1)}
            />
            <Card className="content">
              <div className="d-flex align-items-center justify-content-between w-100 mb-4">
                <h3 className="title-aba">
                </h3>

                {buttons(delay, setDelay)}
              </div>
              <Grid container spacing={1} className="block-1">
                {/*---------------------------------COL-1------------------------------------------*/}
                <Grid item xs={4} className="col-b1 col-1-b1">
                  <div className="content-medium">
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <h3 className="title">
                          {Store.getUserLoged().role != 'solicitant' ? (
                            <Tooltip
                              interactive
                              html={
                                <div
                                  style={{ width: 350 }}
                                  dangerouslySetInnerHTML={{
                                    __html: ticket.config[0].information,
                                  }}
                                />
                              }
                              arrow={true}
                              position="left"
                              theme="light"
                            >
                              <BusinessCenterIcon
                                className="icon-title"
                                style={{ color: 'var(--warning)' }}
                              />
                            </Tooltip>
                          ) : (
                            <BusinessCenterIcon
                              className="icon-title"
                              style={{ color: 'var(--warning)' }}
                            />
                          )}
                          {ticket.company[0].name_company_master}
                        </h3>
                        <div className="ml-4">
                          {companyInformations?.map((e, i) => (
                            <div key={i} className="content-items">
                              <span className="label-item">{e.label}</span>
                              <span className="value-item">
                                {typeof e.value === 'string' ? (
                                  <span dangerouslySetInnerHTML={{ __html: e.value }} />
                                ) : (
                                  <>{e.value}</>
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="content-more-info ml-4">
                          <span>
                            {
                              <NumberFormat
                                format="(##) #####-####"
                                value={ticket.user_solicit[0].phone}
                                displayType={'text'}
                              />
                            }
                          </span>
                          <span>
                            {language.TELEPHONE_EXTENSION}:{' '}
                            {ticket.user_solicit[0].ramal}
                          </span>
                          <span>
                            {language.POSITION}: {nameOccupation}
                          </span>

                          <div
                            className="container-sponsor-name"
                            style={{ height: 50, position: 'relative' }}
                          >
                            {[
                              {
                                label: language.MANAGE,
                                value: ticket.user_sponsor[0].name_sponsor,
                              },
                              {
                                label: language.COST_CENTER,
                                value: ticket.user_sponsor[0].cost_center,
                              },
                            ].map((e, i) => (
                              <div key={i} className="content-items">
                                <span className="label-item">{e.label}</span>
                                <span className="value-item">{e.value}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                {/*---------------------------------COL-2 - PROBLEMA------------------------------------------*/}
                <Grid item xs={4} className="col-b1 col-2-b1">
                  <div className="content-medium">
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <h3 className="title">
                          {ticket.type_tickets == 'Solicitation' ? (
                            <i
                              className="fas fa-user-plus"
                              style={{ color: 'var(--primary)', fontSize: 18 }}
                            />
                          ) : ticket.type_tickets == 'Problem' ? (
                            <i
                              className="fas fa-exclamation-triangle"
                              style={{ color: ' var(--danger)', fontSize: 18 }}
                            />
                          ) : ticket.type_tickets == 'Incident' ? (
                            <i
                              className="fas fa-bell"
                              style={{ color: 'var(--warning)', fontSize: 18 }}
                            />
                          ) : ticket.type_tickets == 'Event' ? (
                            <i
                              className="fas fa-clipboard"
                              style={{ color: 'var(--warning)', fontSize: 18 }}
                            />
                          ) : ticket.type_tickets == 'Crisis' ? 
                                ticket.documented_crisis ? (
                            <i
                              className="fas fa-arrow-down"
                              style={{ color: 'var(--primary)', fontSize: 18 }}
                            />
                          ) : (
                            <i
                              className="fas fa-arrow-down"
                              style={{ color: 'var(--warning)', fontSize: 18 }}
                            />
                          ) :
                            <i
                              className="fas fa-exclamation-circle"
                              style={{ color: 'var(--danger)', fontSize: 18 }}
                            />}
                          {type_(ticket, language)}
                        </h3>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }} className="ml-4">
                          {[
                            {
                              label: language.PRIORITY,
                              value: (
                                <div className="d-flex align-items-center"
                                  style={{
                                    fontWeight: 600,
                                    color:
                                      params[0].critical_ticket_color ?
                                        arrayColors[
                                        ticket.config[0].arraypr.fk_id_priority ==
                                          (!!priorities.length
                                            ? priorities[0].id_table
                                            : 0)
                                          ? 0
                                          : ticket.config[0].arraypr
                                            .fk_id_priority ==
                                            (!!priorities.length
                                              ? priorities[1].id_table
                                              : 0)
                                            ? 1
                                            : ticket.config[0].arraypr
                                              .fk_id_priority ==
                                              (!!priorities.length
                                                ? priorities[2].id_table
                                                : 0)
                                              ? 2
                                              : 3
                                        ]

                                        : 'primary'
                                  }}
                                >
                                  {
                                    arrayPriority[
                                    ticket.config[0].arraypr.fk_id_priority ==
                                      (!!priorities.length
                                        ? priorities[0].id_table
                                        : 0)
                                      ? 0
                                      : ticket.config[0].arraypr
                                        .fk_id_priority ==
                                        (!!priorities.length
                                          ? priorities[1].id_table
                                          : 0)
                                        ? 1
                                        : ticket.config[0].arraypr
                                          .fk_id_priority ==
                                          (!!priorities.length
                                            ? priorities[2].id_table
                                            : 0)
                                          ? 2
                                          : 3
                                    ]
                                  }
                                  {(store.getUserLoged().role == 'solicitant' ||
                                    isReadonly) || hasTasksPending ? null : (
                                    <MenuTooltip
                                      icon="fas fa-pencil-alt"
                                      propsButton={{
                                        style: {
                                          fontSize: 12,
                                          transform: 'rotate(0deg)',
                                          width: 35,
                                          height: 35,
                                        },
                                      }}
                                      options={[
                                        {
                                          value: !!priorities.length
                                            ? priorities[0].id_table
                                            : 0,
                                          icon: 'fas fa-angle-down',
                                          label: language.TICKET_PRIORITY.SMALL,
                                          color: 'var(--primary)',
                                        },
                                        {
                                          value: !!priorities.length
                                            ? priorities[1].id_table
                                            : 0,
                                          icon: 'fas fa-angle-right',
                                          label:
                                            language.TICKET_PRIORITY.MEDIUM,
                                          color: 'var(--warning)',
                                        },
                                        {
                                          value: !!priorities.length
                                            ? priorities[2].id_table
                                            : 0,
                                          icon: 'fas fa-angle-up',
                                          label: language.TICKET_PRIORITY.HIGH,
                                          color: 'var(--danger)',
                                        },
                                        {
                                          value: !!priorities.length
                                            ? priorities[3].id_table
                                            : 0,
                                          icon: 'fas fa-angle-up',
                                          label:
                                            language.TICKET_PRIORITY.CRITICAL,
                                          color: 'var(--danger)',
                                        },
                                      ]}
                                      onChange={(e) => {
                                        onChangeTicket({
                                          id_tickets: ticket.id_tickets,
                                          fk_id_priority: e.value,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                              ),
                            },
                            {
                              label: language.URGENCY,
                              value: (
                                <div className="d-flex align-items-center"
                                  style={{
                                    fontWeight: 600,
                                    color:
                                      params[0].critical_ticket_color ?
                                        arrayColors[
                                        ticket.config[0].arraypr.fk_id_urgency ==
                                          (!!urgencies.length
                                            ? urgencies[0].id_table
                                            : 0)
                                          ? 0
                                          : ticket.config[0].arraypr
                                            .fk_id_urgency ==
                                            (!!urgencies.length
                                              ? urgencies[1].id_table
                                              : 0)
                                            ? 1
                                            : ticket.config[0].arraypr
                                              .fk_id_urgency ==
                                              (!!urgencies.length
                                                ? urgencies[2].id_table
                                                : 0)
                                              ? 2
                                              : 3
                                        ]

                                        : 'primary'
                                  }}
                                >
                                  {
                                    arrayPriority[
                                    ticket.config[0].arraypr.fk_id_urgency ==
                                      (!!urgencies.length
                                        ? urgencies[0].id_table
                                        : 0)
                                      ? 0
                                      : ticket.config[0].arraypr
                                        .fk_id_urgency ==
                                        (!!urgencies.length
                                          ? urgencies[1].id_table
                                          : 0)
                                        ? 1
                                        : ticket.config[0].arraypr
                                          .fk_id_urgency ==
                                          (!!urgencies.length
                                            ? urgencies[2].id_table
                                            : 0)
                                          ? 2
                                          : 3
                                    ]
                                  }
                                  {(store.getUserLoged().role == 'solicitant' ||
                                    isReadonly) || hasTasksPending ? null : (
                                    <MenuTooltip
                                      icon="fas fa-pencil-alt"
                                      propsButton={{
                                        style: {
                                          fontSize: 12,
                                          transform: 'rotate(0deg)',
                                          width: 35,
                                          height: 35,
                                        },
                                      }}
                                      options={[
                                        {
                                          value: !!urgencies.length
                                            ? urgencies[0].id_table
                                            : 0,
                                          icon: 'fas fa-angle-down',
                                          label: language.TICKET_PRIORITY.SMALL,
                                          color: 'var(--primary)',
                                        },
                                        {
                                          value: !!urgencies.length
                                            ? urgencies[1].id_table
                                            : 0,
                                          icon: 'fas fa-angle-right',
                                          label:
                                            language.TICKET_PRIORITY.MEDIUM,
                                          color: 'var(--warning)',
                                        },
                                        {
                                          value: !!urgencies.length
                                            ? urgencies[2].id_table
                                            : 0,
                                          icon: 'fas fa-angle-up',
                                          label: language.TICKET_PRIORITY.HIGH,
                                          color: 'var(--danger)',
                                        },
                                        {
                                          value: !!urgencies.length
                                            ? urgencies[3].id_table
                                            : 0,
                                          icon: 'fas fa-angle-up',
                                          label:
                                            language.TICKET_PRIORITY.CRITICAL,
                                          color: 'var(--danger)',
                                        },
                                      ]}
                                      onChange={(e) => {
                                        onChangeTicket({
                                          id_tickets: ticket.id_tickets,
                                          fk_id_urgency: e.value,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                              ),
                            },
                            {
                              label: language.IMPACT,
                              value: (
                                <div className="d-flex align-items-center"
                                  style={{
                                    fontWeight: 600,
                                    marginTop: 7,
                                    color:
                                      params[0].critical_ticket_color ?
                                        arrayColors[
                                        ticket.config[0].arraypr.fk_id_impact ==
                                          (!!impactos.length
                                            ? impactos[0].id_table
                                            : 0)
                                          ? 0
                                          : ticket.config[0].arraypr
                                            .fk_id_impact ==
                                            (!!impactos.length
                                              ? impactos[1].id_table
                                              : 0)
                                            ? 1
                                            : ticket.config[0].arraypr
                                              .fk_id_impact ==
                                              (!!impactos.length
                                                ? impactos[2].id_table
                                                : 0)
                                              ? 2
                                              : 3
                                        ]

                                        : 'primary'
                                  }}
                                >
                                  {
                                    arrayPriority[
                                    ticket.config[0].arraypr.fk_id_impact ==
                                      (!!impactos.length
                                        ? impactos[0].id_table
                                        : 0)
                                      ? 0
                                      : ticket.config[0].arraypr
                                        .fk_id_impact ==
                                        (!!impactos.length
                                          ? impactos[1].id_table
                                          : 0)
                                        ? 1
                                        : ticket.config[0].arraypr
                                          .fk_id_impact ==
                                          (!!impactos.length
                                            ? impactos[2].id_table
                                            : 0)
                                          ? 2
                                          : 3
                                    ]
                                  }
                                </div>
                              ),
                            },
                            {
                              label: language.COMPLEXITY,
                              value: (
                                 <div className="d-flex align-items-center"
                                  style={{
                                    fontWeight: 600,
                                    marginTop: 7,
                                    color:
                                      params[0].critical_ticket_color ?
                                        arrayColors[
                                        ticket.fk_id_complexity ==
                                          (!!complexities.length
                                            ? complexities[0].id_table
                                            : 0)
                                          ? 0
                                          : ticket.fk_id_complexity ==
                                            (!!complexities.length
                                              ? complexities[1].id_table
                                              : 0)
                                            ? 1
                                            : ticket.fk_id_complexity ==
                                              (!!complexities.length
                                                ? complexities[2].id_table
                                                : 0)
                                              ? 2
                                              : 3
                                        ]

                                        : 'primary'
                                  }}
                                 >
                                   {
                                    arrayPriority[
                                    ticket.fk_id_complexity ==
                                      (!!complexities.length
                                        ? complexities[0].id_table
                                        : 0)
                                      ? 0
                                      : ticket.fk_id_complexity ==
                                        (!!complexities.length
                                          ? complexities[1].id_table
                                          : 0)
                                        ? 1
                                        : 2
                                    ]
                                  }
                                   {(store.getUserLoged().role == 'solicitant' ||
                                     isReadonly) || hasTasksPending ? null : (
                                     <MenuTooltip
                                       icon="fas fa-pencil-alt"
                                       propsButton={{
                                         style: {
                                           fontSize: 12,
                                           transform: 'rotate(0deg)',
                                           width: 35,
                                           height: 35,
                                         },
                                       }}
                                       options={[
                                          {
                                            value: !!complexities.length
                                              ? complexities[0].id_table
                                              : 0,
                                            icon: 'fas fa-angle-down',
                                            label: language.TICKET_PRIORITY.SMALL,
                                            color: 'var(--primary)',
                                          },
                                          {
                                            value: !!complexities.length
                                              ? complexities[1].id_table
                                              : 0,
                                            icon: 'fas fa-angle-right',
                                            label:
                                              language.TICKET_PRIORITY.MEDIUM,
                                            color: 'var(--warning)',
                                          },
                                          {
                                            value: !!complexities.length
                                              ? complexities[2].id_table
                                              : 0,
                                            icon: 'fas fa-angle-up',
                                            label: language.TICKET_PRIORITY.HIGH,
                                            color: 'var(--danger)',
                                          },
                                        ]}
                                        onChange={(e) => {
                                          onChangeTicket({
                                            id_tickets: ticket.id_tickets,
                                            fk_id_complexity: e.value,
                                          });
                                        }}
                                     />
                                   )}
                                 </div>
                               ),
                            },
                          ].map((e, i) => (
                            <div
                              key={i}
                              style={{ width: '50%' }}
                              className="content-items"
                            >
                              <span className="label-item">{e.label}</span>
                              <span className="value-item">{e.value}</span>
                            </div>
                          ))}
                        </div>

                        <div style={{ marginTop: '20px' }} className="ml-4">
                          {[
                            {
                              label: language.SERVICE_TIME,
                              value: ticket.time_resolution,
                            },
                            {
                              label:
                                ticket.config[0].progress == 100
                                  ? ''
                                  : `${language.PROGRESS}:`,
                              value: (
                                <div className="d-flex align-items-center w-100">
                                  <Progress
                                    hiddenText
                                    current={ticket.config[0].progress}
                                    style={{ height: 7, width: '100%' }}
                                    background="var(--danger)"
                                  />
                                  <span
                                    className="ml-1"
                                    style={{ fontSize: 10, width: 38 }}
                                  >
                                    {ticket.config[0].progress} %
                                  </span>
                                </div>
                              ),
                            },
                            {
                              label:
                                ticket.config[0].arraypr.correlation_id != null
                                  ? language.ID_CORRELATION
                                  : '',
                              value: ticket.config[0].arraypr.correlation_id,
                            },
                            {
                              label:
                                ticket.config[0].arraypr.correlation_display !== null &&
                                ticket.config[0].arraypr.correlation_display !== "undefined" &&
                                ticket.config[0].arraypr.correlation_display !== "" ?
                                  language.ID_DISPLAY :
                                  '',
                              value:
                                ticket.config[0].arraypr.correlation_display !== null &&
                                ticket.config[0].arraypr.correlation_display !== "undefined" &&
                                ticket.config[0].arraypr.correlation_display !== "" ?
                                  ticket.config[0].arraypr.correlation_display :
                                  null,
                            },
                            {
                              label:
                                language.AUTOMATION,
                              value:
                                ticket.is_automation ? language.YES : language.NOT,
                            },
                            {
                              label:
                                params[0].technical_evaluation_registration ==
                                  true
                                  ? language.CALL_EVALUATION
                                  : '',
                              value:
                                params[0].technical_evaluation_registration ==
                                  true
                                  ? ticket.tech_evaluation_registration_status
                                  : '',
                            },
                          ].map((e, i) => (
                            <div key={i} className="content-items">
                              <span className="label-item">{e.label}</span>
                              <span className="value-item">{e.value}</span>
                            </div>
                          ))}
                        </div>
                      </Grid>
                      <Grid item xs={4} className="content-info">
                        {ticket.config[0].dt_cad && (
                          <div className="container-dt-cad mt-2">
                            <div className="d-flex align-items-center justify-content-end">
                              <i class="fas fa-calendar-day" />
                              <span id="dt_cad_title">
                                {language.REGISTRATION_DATE}
                              </span>
                            </div>

                            <span id="dt_cad">
                              {moment(ticket.config[0].dt_cad).format(
                                'DD/MM/YYYY'
                              )}
                            </span>
                            <span id="hour_cad">
                              {moment(ticket.config[0].dt_cad).format(
                                'HH:mm:ss'
                              )}
                            </span>
                          </div>
                        )}
                        <div className="container-dt-cad mt-2">
                          <div className="d-flex align-items-center justify-content-end">
                            <i class="fas fa-calendar-day" />
                            <span id="dt_cad_title">
                              {ticket.dt_approver_request
                                ? language.APPROVAL_DATE
                                : language.CATEGORIZATION_DATE}
                            </span>
                          </div>

                          <span id="dt_cad">
                            {moment(
                              ticket.dt_approver_request || ticket.dt_cad
                            ).format('DD/MM/YYYY')}
                          </span>
                          <span id="hour_cad">
                            {moment(
                              ticket.dt_approver_request || ticket.dt_cad
                            ).format('HH:mm:ss')}
                          </span>
                        </div>
                        {!isReadonly &&
                          Store.getUserLoged().integration != true ? (
                          <div className="container-dt-cad mt-2">
                            <div className="d-flex align-items-center justify-content-end">
                              <i class="fas fa-calendar-day" />
                              <span id="dt_cad_title">
                                {language.TICKET_TIME}
                              </span>
                            </div>
                            <span
                              className="d-flex align-items-right justify-content-end"
                              style={{ fontSize: '15px' }}
                              id="dt_cad"
                            > {params[0].sla_params === true && ticket.config[0].arraypr.sla_date_hr_param
                              ?
                              ticket.sla_ticket && ticket.sla_ticket.length > 0 ?
                                (
                                  ticket.sla_ticket && ticket.sla_ticket.indexOf('-') == 4 ? (
                                    moment(ticket.sla_ticket).format('DD/MM/YYYY HH:mm:ss')
                                  ) : ticket.sla_ticket && ticket.sla_ticket.indexOf('-') == 2 ? (
                                    ticket.sla_ticket
                                  ) : (
                                    ' '
                                  )
                                )
                                : ticket.config[0].arraypr.sla_date_hr_param
                              :
                              <Sla
                                not
                                statusAwaiting={
                                  statusAwaiting.find(
                                    (status) =>
                                      status.id_status_awaiting ===
                                      ticket.config[0].stop_ticket
                                  )
                                    ? statusAwaiting.find(
                                      (status) =>
                                        status.id_status_awaiting ===
                                        ticket.config[0].stop_ticket
                                    )
                                    : undefined
                                }
                                statusAwaitingObj={statusAwaiting}
                                t={ticket}
                                stopSlaParams={stopSlaParams}
                                horas={undefined}
                                req={[
                                  {
                                    dt_up: ticket.config[0].dt_cad,
                                    dt_cad: ticket.config[0].dt_up,
                                    id_request: ticket.config[0].id_request,
                                  },
                                ]}
                                language={language}
                              />
                              }
                            </span>
                          </div>
                        ) : null}

                        {ticket.status == 'Resolved' ||
                          ticket.status == 'Closed' ? (
                          <div className="container-in-call">
                            <Tooltip
                              interactive
                              html={
                                language.TOTAL_TICKET_TIME_BETWEEN_OPENING_AND_RESOLUTION
                              }
                              arrow={true}
                              position="left"
                              theme="light"
                            >
                              {' '}
                              <span
                                style={{
                                  fontSize: '13px',
                                  color: 'var(--success)',
                                  fontWeight: 600,
                                }}
                              >
                                {' '}
                                {language.TOTAL_TICKET_TIME}
                              </span>
                            </Tooltip>
                            <span>{ticket.config[0].time_total}</span>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {(ticket.status == 'Resolved' || ticket.status == 'Closed') && ticket.type_tickets == 'Crisis' && (
                          <div className="container-in-call">
                            <Tooltip
                              interactive
                              html={ language.TOTAL_TICKET_TIME_BETWEEN_OPENING_AND_RESOLUTION }
                              arrow={true}
                              position="left"
                              theme="light"
                            >
                              {' '}
                              <span
                                style={{
                                  fontSize: '13px',
                                  color: 'var(--success)',
                                  fontWeight: 600,
                                }}
                              >
                                {' '}
                                {language.DOWNTIME}
                              </span>
                            </Tooltip>                            
                            <span>{formatDecimalToMinutes(ticket.diff_minutes_crisis)}</span>                            
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={8}>
                        {ticket.recurrence > 1 ? (
                          <div className="ml-4">
                            {[
                              {
                                label: language.START_RECURRENCE,
                                value:
                                  moment(ticket.dt_recurrence).format('DD/MM/YY') +
                                  ' ' +
                                  moment(ticket.time_recurrence).format('HH:mm'),
                                text: recurrenceList[ticket.recurrence],
                              },
                            ].map((e, i) => (
                              <div key={i} className="content-items">
                                <span className="label-item">{e.label}</span>
                                <span className="value-item">{e.value}</span>
                                <span className="label-item">{e.text}</span>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                {/*---------------------------------COL-3 - DISPOSITIVO------------------------------------------*/}
                <Grid item xs={4} className="col-b1 col-3-b1">
                  <div className="content-medium">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <h3 className="title">
                          <Tooltip
                            interactive
                            html={
                              <div style={{ width: 350 }}>
                                <GC
                                  valueProps={{
                                    className: 'd-flex align-items-center',
                                  }}
                                  items={[
                                    {
                                      label: language.MODEL,
                                      value: ticket.config[0].td_model,
                                    },
                                    {
                                      label: language.VENDOR,
                                      value: ticket.config[0].td_vendor,
                                    },
                                    {
                                      label: language.WARRANTY,
                                      value: ticket.config[0].td_warranty,
                                    },
                                    {
                                      label: language.INVOICE,
                                      value: ticket.config[0].td_invoice,
                                    },
                                    {
                                      label: language.SERIAL,
                                      value: ticket.config[0].td_serial_number,
                                    },
                                    {
                                      label: language.LICENSE,
                                      value: ticket.config[0].td_serial_os,
                                    },
                                    {
                                      label: language.IP,
                                      value: ticket.config[0].td_ip,
                                    },
                                  ]}
                                />
                              </div>
                            }
                            arrow={true}
                            position="left"
                            theme="light"
                          >
                            <DevicesIcon
                              className="icon-title"
                              style={{ color: 'var(--warning)' }}
                            />
                          </Tooltip>
                          {language.REQUESTERSDASH}
                        </h3>

                        <div className="ml-4">
                          {[
                            {
                              label: language.CATEGORY,
                              value: ticket.name_cat,
                            },
                            {
                              label: language.SERVICE_CATALOG,
                              value: ticket.name_service,
                            },
                            {
                              label: language.THIRD_LEVEL_CATEGORY,
                              value: ticket.config[0].name_catalog_task,
                            },
                            {
                              label: language.SLA,
                              value: getSlaDisplay(ticket)
                            },
                          ].map((e, i) => (
                            <div key={i} className="content-items">
                              <span className="label-item">{e.label}</span>
                              <span className="value-item">{e.value}</span>
                            </div>
                          ))}
                          {

                          }
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              {page == 1 ? (
                <React.Fragment>
                  <Grid container spacing={1} className="block-1 mt-2">
                    {/*---------------------------------COL-1 - DESCRIÇÃO------------------------------------------*/}

                    <Grid
                      item
                      xs={8}
                      className="col-b1 col-1-b1"
                      style={{ maxHeight: 560 }}
                    >
                      <div
                        className="content-medium"
                        style={{ height: '100%' }}
                      >
                        <h3 className="title title-description">
                          <span className="">
                            <FormatAlignLeftIcon
                              className="icon-title"
                              style={{ color: 'var(--warning)' }}
                            />
                            {language.DESCRIPTION}
                          </span>
                          {isRoleToEditDescription && isDescriptionEditableForUser &&
                            params[0].enable_description_editing && (
                              <a onClick={() => setShowEditDescription(!showEditDescription)}>
                                <EditIcon />
                              </a>
                            )}
                        </h3>
                        <div
                          id="desccc"
                          className="ml-4 content-desc detail-description"
                          style={{ height: 'calc(100% - 40px)' }}
                        >
                          <DescTicket
                            id_tickets={ticket.id_tickets}
                            historico={historico}
                            APIEndpoints={APIEndpoints}
                            tokenApi={Store.getToken()}
                            isIntegration={params[0].integration}
                            showEditDescription={showEditDescription}
                            onSave={() => setShowEditDescription(false)}
                          ></DescTicket>
                        </div>
                      </div>
                    </Grid>
                    {/*---------------------------------COL-2 - RESPONSÁVEL------------------------------------------*/}
                    <Grid item xs={4} className="col-b1 col-2-b1">
                      <div className="content-medium">
                        <h3 className="title">
                          <PersonIcon
                            className="icon-title"
                            style={{ color: 'var(--warning)' }}
                          />
                          {language.RESPONSIBLE}
                        </h3>

                        <div style={{ display: 'flex' }} className="ml-4">
                          {[
                            {
                              label: <div>{language.TECH_GROUP}</div>,
                              value: (
                                <div>
                                  {ticket.config[0].grouptech != ''
                                    ? ticket.config[0].grouptech
                                    : null}
                                  {(!isReadonly && user.role != 'solicitant') && !hasTasksPending ? (
                                    <Button
                                      variant="circle"
                                      icon="fas fa-pencil-alt"
                                      color="success"
                                      outlined
                                      label={language.CHANGE_TECH_GROUP}
                                      onClick={() => setModalgroup(true)}
                                      style={{ border: 'none', fontSize: 14 }}
                                    />
                                  ) : null}
                                </div>
                              ),
                            },
                            {
                              label: <div>{language.QUANTITY}</div>,

                              value: (
                                <div>
                                  <i
                                    class="fas fa-sync"
                                    style={{
                                      marginLeft: '4px',
                                      fontSize: '10px',
                                      color: '#33aba0',
                                    }}
                                    aria-hidden="true"
                                  >
                                    <b
                                      style={{
                                        marginLeft: '2px',
                                        fontSize: '12px',
                                      }}
                                    >
                                      {' '}
                                      {ticket.config[0].countgroup}
                                    </b>
                                  </i>
                                </div>
                              ),
                            },
                          ].map((e, i) => (
                            <div
                              key={i}
                              style={{ width: '32%' }}
                              className="content-items"
                            >
                              <span className="label-item">{e.label}</span>
                              <span className="value-item">{e.value}</span>
                            </div>
                          ))}
                        </div>
                        <div style={{ display: 'flex' }} className="ml-4">
                          {[
                            {
                              label: language.TECH,
                              value: (
                                <div>
                                  {ticket.tech_start || ''}
                                  {(!isReadonly && user.role != 'solicitant') && !hasTasksPending ? (
                                    <Button
                                      variant="circle"
                                      icon="fas fa-pencil-alt"
                                      color="success"
                                      outlined
                                      label={language.CHANGE_TECH}
                                      onClick={() => setModaltech(true)}
                                      style={{ border: 'none', fontSize: 14 }}
                                    />
                                  ) : null}
                                </div>
                              ),
                            },
                            {
                              label: language.REQUESTING_TECHNICIAN,
                              value: (
                                <div>
                                  {ticket.config[0].real_id.indexOf('.') > 1 &&
                                    ticket.config[0].arraypr.name_create}
                                </div>
                              ),
                            },
                          ].map((e, i) => (
                            <div
                              key={i}
                              style={{ width: '32%' }}
                              className="content-items"
                            >
                              <span className="label-item">{e.label}</span>
                              <span className="value-item">{e.value}</span>
                            </div>
                          ))}
                        </div>

                        <div className="ml-4">
                          {[
                            {
                              label: language.STATE,
                              value: getStatus(ticket),
                            },
                            {
                              label:
                                ['Closed', 'Resolved'].includes(
                                  ticket.status
                                ) == true
                                  ? ''
                                  : language.REASON_STATUS,
                              value: (
                                <div>
                                  {['Reopened', 'In Progress', 'Open'].includes(
                                    ticket.status
                                  ) == true
                                    ? translateFromPtBR(getWaiting(ticket), language)
                                    : ''}
                                  {getStatus(ticket) !== 'Job' && (user.role != 'solicitant' && !isReadonly) && !hasTasksPending ? (
                                    <Button
                                      variant="circle"
                                      icon="fas fa-pencil-alt"
                                      color="success"
                                      outlined
                                      onClick={(evt) => {
                                        setModalWaitingFunc(true);
                                      }}
                                      style={{ border: 'none', fontSize: 14 }}
                                    />
                                  ) : null}
                                </div>
                              ),
                            },
                            {
                              label: '',
                              value: (
                                <span style={{ marginTop: '10px' }}>
                                  {language.OBSERVER}
                                  {(ticket.config[0].observers.filter(
                                    (b) => b.fk_id_user == user.id
                                  ).length == 0 && !isReadonly) && !hasTasksPending ? (
                                    <Button
                                      variant="circle"
                                      icon="fas fa-pencil-alt"
                                      color="success"
                                      outlined
                                      onClick={(evt) => {
                                        setmodalOb(true);
                                      }}
                                      style={{ border: 'none', fontSize: 14 }}
                                    />
                                  ) : null}
                                  {['Open', 'Reopened', 'In Progress'].includes(
                                    ticket.status
                                  ) == true && load == true ? (
                                    <div>
                                      {(ticket.config[0].observers || []).map(
                                        (g) => (
                                          <div>
                                            {g.name}{' '}
                                            <i
                                              style={{
                                                color: 'red',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() =>
                                                relationObserver(
                                                  [g.fk_id_user],
                                                  ticket.id_tickets,
                                                  true
                                                )
                                              }
                                              class="far fa-trash-alt"
                                            ></i>{' '}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : null}
                                </span>
                              ),
                            },
                            {
                              label: '',
                              value:
                                localFiles != undefined ? (
                                  <PerfectScrollbar
                                    className="scrollVisible"
                                    style={{
                                      height:
                                        localFiles.length &&
                                          window.screen.height > 1366
                                          ? 50 + localFiles.length * 10
                                          : localFiles.length &&
                                            window.screen.height <= 1366
                                            ? 80 + localFiles.length * 10
                                            : 43,
                                    }}
                                    option={{
                                      suppressScrollX: true,
                                    }}
                                  >
                                    <div
                                      className="mt-3 content-attach"
                                      style={{ zoom: 0.8 }}
                                      zoom={0.8}
                                    >
                                      {' '}
                                      {localFiles.length > 0 ? (
                                        <CommonForm
                                          values={{
                                            files: localFiles,
                                          }}
                                          clean
                                          fields={[
                                            {
                                              type: 'file',
                                              name: 'files',
                                              label: language.ATTACH,

                                              btnProps: {
                                                disabled:
                                                  [
                                                    'Closed',
                                                    'Resolved',
                                                  ].includes(ticket.status) ==
                                                  true || hasTasksPending,
                                              },
                                              disabled:
                                                ['Closed', 'Resolved'].includes(
                                                  ticket.status
                                                ) == true || hasTasksPending,
                                              removeFile: (f) =>
                                                f.fk_id_request
                                                  ? ondeletefile(
                                                    f,
                                                    f,
                                                    'request'
                                                  )
                                                  : ondeletefile(
                                                    f,
                                                    ticket,
                                                    'ticket'
                                                  ),
                                              onClick: (f) =>
                                                f.fk_id_request
                                                  ? ondonwFile(
                                                    f.id_tickets_file,
                                                    f.nn,
                                                    'request'
                                                  )
                                                  : ondonwFile(
                                                    f.fk_id_pai ||
                                                    f.id_tickets_file,
                                                    f.nn,
                                                    'ticket'
                                                  ),
                                            },
                                          ]}
                                          onChangeField={(f, v) => {
                                            UpdateFiles(v, ticket);
                                            setLocalFiles(v);
                                          }}
                                        />
                                      ) : (
                                        <CommonForm
                                          fields={[
                                            {
                                              type: 'file',
                                              name: 'teste',
                                              label: language.ATTACH,

                                              btnProps: {
                                                disabled:
                                                  [
                                                    'Closed',
                                                    'Resolved',
                                                  ].includes(ticket.status) ==
                                                  true || hasTasksPending,
                                              },
                                              disabled:
                                                ['Closed', 'Resolved'].includes(
                                                  ticket.status
                                                ) == true || hasTasksPending,
                                              removeFile: (f) =>
                                                f.fk_id_request
                                                  ? ondeletefile(
                                                    f,
                                                    f,
                                                    'request'
                                                  )
                                                  : ondeletefile(
                                                    f,
                                                    ticket,
                                                    'ticket'
                                                  ),
                                              onClick: (f) =>
                                                f.fk_id_request
                                                  ? ondonwFile(
                                                    f.id_tickets_file,
                                                    f.nn,
                                                    'request'
                                                  )
                                                  : ondonwFile(
                                                    f.id_tickets_file,
                                                    f.nn,
                                                    'ticket'
                                                  ),
                                            },
                                          ]}
                                          onChangeField={(f, v) => {
                                            UpdateFiles(v, ticket);
                                            setLocalFiles(v);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </PerfectScrollbar>
                                ) : (
                                  <div style={{ textAlign: 'center' }}>
                                    {' '}
                                    <CircularProgress color="secondary" />
                                  </div>
                                ),
                            },
                          ].map((e, i) => (
                            <div key={i} className="content-items">
                              <span className="label-item">{e.label}</span>
                              <span className="value-item">{e.value}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Dialog
                    onClose={() => setOpenStatusChange(false)}
                    open={openStatusChange}
                    maxWidth="lg"
                    title={
                      'Alterar Status tarefa' &&
                      ticketDetails &&
                      ticketDetails.name
                    }
                  >
                    {ticketDetails ? (
                      <div className="template template-dinamic">
                        <p
                          style={{
                            color: '#888080',
                            pointerEvents:
                              ticketDetails.status == 'CONCLUIDO' ||
                                ticketDetails.status == 'REJEITADO' ||
                                ticketDetails.status == 'APROVADO' ||
                                ticket.status == 'Resolved' ||
                                ticket.status == 'Closed'
                                ? 'none'
                                : 'auto',
                          }}
                          id={'formatasktemplate'}
                        >
                          {ticketDetails.approved != true && ticketDetails.html
                            ? formshtml(decryp(ticketDetails.html), htmlValues)
                            : ''}
                        </p>
                      </div>
                    ) : null}
                    <br></br>
                    {ticketDetails && ticketDetails.status == 'REJEITADO' ? (
                      <div>
                        <label style={{ fontWeight: 'bold' }}>
                          {language.REASON_FOR_REJECTION}
                        </label>
                        <div
                          style={{ marginTop: 10 }}
                          dangerouslySetInnerHTML={{
                            __html: ticketDetails.reason,
                          }}
                        ></div>
                        <div>
                          <CommonTable
                            paper={false}
                            mini
                            index={'ticket-detail-history'}
                            bar={false}
                            orderColumn={false}
                            col={[
                              {
                                key: 'aprover',
                                label: language.USER,
                              },
                              {
                                key: 'description',
                                label: language.DESCRIPTION,
                              },
                              {
                                key: 'dt_cad',
                                label: language.DATE,
                              },
                            ]}
                            data={(ticketDetails.reason_ || []).map((i) => ({
                              aprover: i.name_user,
                              description: (
                                <p
                                  style={{ color: '#888080' }}
                                  dangerouslySetInnerHTML={{
                                    __html: i.reason,
                                  }}
                                />
                              ),

                              dt_cad: moment(i.dt_cad).format('DD-MM-YY HH:mm'),
                            }))}
                          />
                          <div></div>
                        </div>
                      </div>
                    ) : null}
                    <br></br>
                    {ticketDetails &&
                      (ticketDetails.status == 'CONCLUIDO' || ticketDetails.status == 'APROVADO') &&
                      ticketDetails.approved == true ? (
                      <div>
                        <CommonTable
                          paper={false}
                          mini
                          index={'ticket-detail-history'}
                          bar={false}
                          orderColumn={false}
                          col={[
                            {
                              key: 'aprover',
                              label: language.APPROVER,
                            },
                            {
                              key: 'description',
                              label: language.DESCRIPTION,
                            },
                            {
                              key: 'dt_cad',
                              label: language.DATE,
                            },
                          ]}
                          data={(ticketDetails.aprovacoes || []).map((i) => ({
                            aprover: i.name_user,
                            description: (
                              <p
                                style={{ color: '#888080' }}
                                dangerouslySetInnerHTML={{
                                  __html: i.reason,
                                }}
                              />
                            ),
                            dt_cad: moment(i.dt_cad).format('DD-MM-YY HH:mm'),
                          }))}
                        />
                        <div></div>
                      </div>
                    ) : null}
                    <br></br>
                    {ticketDetails &&
                      ticketDetails.status !== 'CONCLUIDO' &&
                      ticketDetails.status !== 'REJEITADO' &&
                      ticketDetails.status !== 'APROVADO' &&
                      ticket.status !== 'Resolved' &&
                      ticket.status !== 'Closed' ? (
                      <span>
                        <CommonForm
                          fields={[{
                            col: 12,
                            name: 'status',
                            label: language.STATE,
                            options: optionStatus,
                            type: 'select'
                          }]}
                          values={{ status: statusSelected }}
                          onChangeField={(f, v) => {
                            handleSelect(v)
                          }}
                        />
                        <br />
                        <div style={{ maxWidth: '100px', marginTop: 20 }}>
                          <AttachFile2
                            label={language.ATTACH}
                            subLabel={language.DRAG_THE_FILE}
                            onClick={(v) => ondonwFile_task(v)}
                            onRemove={(f) => setFilesTask(f)}
                            files={filesTask}
                            onDrop={onDrop}
                          />
                        </div>
                        {user.role !== 'solicitant' &&
                          ticket.status !== 'Closed' &&
                          ticket.status !== 'Resolved' ? (
                          <ButtonUI
                            style={{ float: 'right', marginTop: '10px' }}
                            onClick={submitStatus}
                            variant="contained"
                            color="success"
                          >
                            {language.SAVE}
                          </ButtonUI>
                        ) : (
                          <li></li>
                        )}
                      </span>
                    ) : null}
                  </Dialog>

                  <Divider />

                  <Grid container spacing={1} id="block-2">
                    <Grid item xs={6}>
                      <div className="mb-2 d-flex align-items-center">
                        <i
                          className="fas fa-comment-dots mr-2"
                          style={{ fontSize: 14, color: 'var(--warning)' }}
                        />
                        <h3 className="title-aba">{language.CHAT}</h3>
                      </div>
                      <TicketComment
                        isReadonly={isReadonly}
                        language={language}
                        changecomponent={params[0].integration}
                        ticket={ticket}
                        onCommentTicket={(id_ticket, values) =>
                          onCommentTicket(id_ticket, values, 'solicitant')
                        }
                        statusTicket={ticket.status}
                        maxHeightChat={250}
                        setRefComments={setRefComments}
                        type_ticket={[null, 'solicitant']}
                        hasTasksPending={hasTasksPending}
                        handleDetail={handleDetail}
                      />
                    </Grid>
                    {params[0].tech_notes_disable == true
                      ? user.role != 'solicitant' &&
                      store.getUserLoged().id !== ticket.fk_id_user && (
                        <Grid item xs={6}>
                          <div className="mb-2 d-flex align-items-center">
                            <i
                              className="fas fa-comments mr-2"
                              style={{
                                fontSize: 14,
                                color: 'var(--warning)',
                              }}
                            />
                            <h3 className="title-aba">
                              {language.TECHNICAL_NOTES}
                            </h3>
                          </div>
                          <TicketComment
                            isReadonly={isReadonly}
                            language={language}
                            ticket={ticket}
                            statusTicket={ticket.status}
                            onCommentTicket={(id_ticket, values) =>
                              onCommentTicket(id_ticket, values, 'tech')
                            }
                            filter={{
                              fk_id_group_users: ticket.fk_id_group_tech,
                            }}
                            setRefComments={setRefComments}
                            maxHeightChat={250}
                            type_ticket={['tech']}
                            hasTasksPending={hasTasksPending}
                            changecomponent={params[0].integration}
                          />
                        </Grid>
                      )
                      : user.role != 'solicitant' && (
                        <Grid item xs={6}>
                          <div className="mb-2 d-flex align-items-center">
                            <i
                              className="fas fa-comments mr-2"
                              style={{
                                fontSize: 14,
                                color: 'var(--warning)',
                              }}
                            />
                            <h3 className="title-aba">
                              {language.TECHNICAL_NOTES}
                            </h3>
                          </div>
                          <TicketComment
                            isReadonly={isReadonly}
                            language={language}
                            ticket={ticket}
                            onCommentTicket={(id_ticket, values) =>
                              onCommentTicket(id_ticket, values, 'tech')
                            }
                            filter={{
                              fk_id_group_users: ticket.fk_id_group_tech,
                            }}
                            setRefComments={setRefComments}
                            statusTicket={ticket.status}
                            maxHeightChat={250}
                            type_ticket={['tech']}
                            hasTasksPending={hasTasksPending}
                            changecomponent={params[0].integration}
                          />
                        </Grid>
                      )}
                  </Grid>
                  <hr />

                  {ticketsManagement && (
                    <Grid
                      container
                      spacing={1}
                      id="block-2"
                      style={{ marginTop: 22, marginBottom: 10 }}
                    >
                      <Grid item xs={12}>
                        <div className="mb-2 d-flex align-items-center">
                          <h3 className="title-aba mb-4">
                            {language.TICKET_TASKS}
                          </h3>
                        </div>
                      </Grid>
                      <Grid container item xs={12}>
                        {ticketsManagement.length > 0 ? (
                          <Grid
                            xs={6}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <BorderLinearProgress
                              variant="determinate"
                              value={percentTasksFinish}
                            />
                            <span style={{ marginLeft: 10 }}>
                              {parseFloat(percentTasksFinish).toFixed(2)} %
                              concluído
                            </span>
                          </Grid>
                        ) : (
                          <Grid xs={6}></Grid>
                        )}
                        <Grid xs={6} style={{ float: 'right' }}>
                          <div style={{ float: 'right' }}>
                            {(store.getUserLoged().role !== 'solicitant' &&
                              ticket.status !== 'Closed' &&
                              ticket.status != 'Resolved' &&
                              ticket.status != 'Reproved' &&
                              ticket.status != 'Aprove' &&
                              ticket.status != 'Cancelled') && !hasTasksPending ? (
                              <Button
                                variant="normal"
                                color="success"
                                label={language.TASK_FLOW}
                                icon="fas fa-pencil-alt"
                                onClick={() => setopentask(true)}
                              />
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>

                      {ticketTask &&
                        canAddTask &&
                        ticketTask.predecessors &&
                        window.location.href.indexOf('tivit') == -1 &&
                        !isReadonly && (
                          <NewTask
                            ticketDetails={ticketTask}
                            reload={relaodTask}
                            openDefault={editTask}
                            language={language}
                          />
                        )}

                      <Grid
                        xs={12}
                        style={{
                          overflowX: 'scroll',
                          display: 'flex',
                        }}
                      >
                        {((ticketsManagement && ticketsManagement) || []).map(
                          (tdetail, index) => (
                            <Grid
                              xs={3}
                              spacing={1}
                              key={Math.random()}
                              style={{
                                marginRight: 10,
                                maxHeight: 320,
                                height: 320,
                                minWidth: 275,
                                overflowY: 'auto',
                                pointerEvents:
                                  index == 0 ||
                                    ticketsManagement[index]
                                      .existOpenPredecessors == false
                                    ? 'auto'
                                    : 'none',
                                opacity:
                                  ticketsManagement[index]
                                    .existOpenPredecessors == false
                                    ? '1'
                                    : '0.3',
                              }}
                            >
                              <Paper
                                className={classes.itemTask}
                                style={{
                                  borderColor: randomcolor({
                                    luminosity: 'dark',
                                  }),
                                  minHeight: 320,
                                  minWidth: 275,
                                }}
                              >
                                <Typography
                                  className={classes.textDescription}
                                  style={{ marginTop: 17, fontSize: 12 }}
                                >
                                  {language.ID}
                                </Typography>
                                <div
                                  style={{
                                    color: '#B5B5B5',
                                    fontWeight: 400,
                                    fontSize: 12,
                                  }}
                                >
                                  {taskLabelId(
                                    tdetail.tasks[0].id_tasks,
                                    ticket.type_tickets
                                  )}
                                </div>
                                <br />
                                <Divider />

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Typography
                                    style={{ color: '#B5B5B5', fontSize: 12 }}
                                  >
                                    {tdetail.tasks[0].name}
                                  </Typography>
                                </div>
                                {ticket.status == 'In Progress' ||
                                  ticket.status == 'Reopened' ||
                                  ticket.status == 'Resolved' ||
                                  ticket.status == 'Closed' ? (
                                  //  || ticket.status == 'Resolved' ||
                                  //  ticket.status == 'Closed'
                                  <ButtonUI
                                    onClick={() =>
                                      tdetail.tasks[0].status !=
                                        'PENDENTE APROVAÇÃO' &&
                                        !tdetail.existOpenPredecessors
                                        ? handleOpenStatus(tdetail.tasks[0])
                                        : tdetail.tasks[0].status ==
                                          'PENDENTE APROVAÇÃO'
                                          ? redirect('/dash/taskapprove')
                                          : null
                                    }
                                    style={{
                                      borderRadius: 10,
                                      background: getBackStatus(
                                        tdetail.tasks[0].status
                                      ),
                                      color: getColorStatus(
                                        tdetail.tasks[0].status
                                      ),
                                    }}
                                  >
                                    {/*status da atividade*/}
                                    {tdetail.tasks[0].status ==
                                      'PENDENTE APROVAÇÃO' ? (
                                      'PENDENTE APROVAÇÃO'
                                    ) : tdetail.tasks[0].status ==
                                      'REJEITADO' ? (
                                      'REJEITADO'
                                    ) : tdetail.existOpenPredecessors ? (
                                      <span>
                                        {language.WAITING_FOR_PREDECESSORS}
                                      </span>
                                    ) : (
                                      <span>
                                        {tdetail.tasks[0].status ==
                                          'CONCLUIDO' &&
                                          tdetail.tasks[0].approved == true
                                          ? 'CONCLUIDO'
                                          : tdetail.tasks[0].status}
                                      </span>
                                    )}
                                  </ButtonUI>
                                ) : (
                                  <ButtonUI
                                    style={{
                                      borderRadius: 10,
                                      background: getBackStatus(
                                        tdetail.tasks[0].status
                                      ),
                                      color: getColorStatus(
                                        tdetail.tasks[0].status
                                      ),
                                    }}
                                  >
                                    {tdetail.tasks[0].status ==
                                      'PENDENTE APROVAÇÃO' ? (
                                      language.TASK_STATUS.PENDING
                                    ) : tdetail.tasks[0].status ==
                                      'REJEITADO' ? (
                                      language.TASK_STATUS.REJECTED
                                    ) : tdetail.existOpenPredecessors ? (
                                      <span>
                                        {language.WAITING_FOR_PREDECESSORS}
                                      </span>
                                    ) : (
                                      <span>
                                        {tdetail.tasks[0].status ==
                                          'CONCLUIDO' &&
                                          tdetail.tasks[0].approved == true
                                          ? language.TASK_STATUS.DONE
                                          : tdetail.tasks[0].status}
                                      </span>
                                    )}
                                  </ButtonUI>
                                )}
                                <br />

                                <Divider />

                                <Typography
                                  className={classes.textDescription}
                                  style={{ marginTop: 17, fontSize: 12 }}
                                >
                                  {language.DESCRIPTION}
                                </Typography>
                                <div
                                  style={{
                                    color: '#B5B5B5',
                                    fontWeight: 400,
                                    fontSize: 12,
                                  }}
                                >
                                  {tdetail.tasks[0].description.length > 200
                                    ? tdetail.tasks[0].description
                                      .replace(/<[^>]*>?/gm, '')
                                      .toString()
                                      .substring(0, 200) + '...'
                                    : tdetail.tasks[0].description.replace(
                                      /<[^>]*>?/gm,
                                      ''
                                    )}
                                </div>
                                <br />

                                <Divider />
                                <Typography
                                  className={classes.textDescription}
                                  style={{ marginTop: 17, fontSize: 12 }}
                                >
                                  {tdetail.tasks[0].fk_id_template_task > 0
                                    ? language.RESPONSIBLE_GROUP
                                    : language.RESPONSIBLES}
                                </Typography>
                                <div style={{ display: 'flex', fontSize: 12 }}>
                                  {tdetail.tasks[0].fk_id_template_task > 0
                                    ? tdetail.tasks[0].grupo_tech
                                    : (tdetail.responsible || []).map(
                                      (user) => (
                                        <Avatar
                                          style={{ marginLeft: 10 }}
                                          alt={user.name}
                                          src={`${APIEndpoints.REQUESTS}/public/users/${user.id_user}/profileLogo.png`}
                                        />
                                      )
                                    )}
                                </div>
                                <br />
                                {tdetail.tasks[0].tech && (
                                  <>
                                    <Divider />
                                    <Typography
                                      className={classes.textDescription}
                                      style={{ marginTop: 17, fontSize: 12 }}
                                    >
                                      {language.DESIGNATED_ANALYST}
                                    </Typography>
                                    <div
                                      style={{ display: 'flex', fontSize: 12 }}
                                    >
                                      {tdetail.tasks[0].tech}
                                    </div>
                                    <br />
                                  </>
                                )}
                                {tdetail.tasks[0].deadline && (
                                  <>
                                    <Divider />
                                    <Typography
                                      className={classes.textDescription}
                                      style={{ marginTop: 17, fontSize: 12 }}
                                    >
                                      {language.TERM}
                                    </Typography>
                                    <div
                                      style={{ display: 'flex', fontSize: 12 }}
                                    >
                                      {moment(tdetail.tasks[0].deadline).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                    </div>
                                    <br />
                                  </>
                                )}
                                {tdetail.tasks[0].files && (
                                  <>
                                    <Divider />
                                    <Typography
                                      className={classes.textDescription}
                                      style={{ marginTop: 17, fontSize: 12 }}
                                    >
                                      {language.FILES}
                                    </Typography>
                                    <div
                                      style={{ fontSize: 12 }}
                                    >
                                      {(tdetail.tasks[0].files || []).map(
                                        (item) => (
                                          <div
                                            style={{
                                              display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #c2bbbb', marginBottom: 10
                                            }}>
                                            <b
                                              onClick={() =>
                                                ondonwFile_task(
                                                  item.id_tasks_files,
                                                  item.namefile
                                                )
                                              }
                                              style={{
                                                cursor: 'pointer',
                                                marginRight: '15px',
                                              }}
                                            >
                                              {item.namefile}
                                            </b>
                                            <i className='fas fa-trash' style={{ cursor: 'pointer' }} onClick={() => removeFileTask(item)}></i>
                                          </div>

                                        )
                                      )}
                                    </div>
                                    <br />
                                  </>
                                )}
                                <Divider />
                                <Typography style={{ fontSize: 12 }}>
                                  {`${language.START_OF_ACTIVITY}: `}
                                  {moment(tdetail.tasks[0].dt_start).format(
                                    'DD/MM/YYYY'
                                  )}{' '}
                                </Typography>
                              </Paper>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Dialog
                    open={openModalDeleteTask}
                    title={language.CONFIRM_DELETE}
                  >
                    {taskToDelete && taskToDelete.tasks[0].name}

                    <RaisedButton
                      circleButton
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13, width: 21, height: 21 }}
                      onClick={() => handleDeleteTask()}
                    />
                  </Dialog>

                  <hr />

                  {/*----------T1 equivale à tela 202 & T2 = tela 204--------------*/}
                  {store.getUserLoged().role != ' ' ? (
                    forms.length > 0 ? (
                      <Grid item xs={12}>
                        <Card className="content">
                          {' '}
                          <div className="template template-dinamic  ">
                            {' '}
                            {forms}
                          </div>
                        </Card>
                      </Grid>
                    ) : null
                  ) : null}

                  {ticket.type_tickets != 'Problem' ? (
                    <div>
                      <T1
                        isReadonly={isReadonly}
                        language={language}
                        ticket={ticket}
                        onNotesTicket={onNotesTicketTabs}
                        statusTicket={ticket.status}
                        url={url}
                        desc={desc}
                        setDesc={setDesc}
                        user={user_ || user}
                        toggleAlert={toggleAlert}
                        type_tickets={ticket.type_tickets}
                        localFiles={localFiles}
                        ondeletefile={ondeletefile}
                        ondonwFile={ondonwFile}
                        UpdateFiles={UpdateFiles}
                        setLocalFiles={setLocalFiles}
                        answer={answer}
                        params={params}
                        action={action}
                        APIEndpoints={APIEndpoints}
                        setPage={setPage}
                        percentTasksFinish={percentTasksFinish}
                        hasTasksPending={hasTasksPending}
                      />

                      {formavaliation.html !== undefined ? (
                        <Grid item xs={12}>
                          <Card className="content">
                            <div className="mb-2 d-flex align-items-center">
                              <h3 className="title-aba mb-4">
                                {language.TECHNICAL_FORM}
                              </h3>
                            </div>
                            <div className="template template-dinamic">
                              <p
                                id={'formavaliation'}
                                style={{
                                  color: '#888080',
                                  pointerEvents:
                                    ticket.status == 'In Progress'
                                      ? 'auto'
                                      : 'none',
                                }}
                              >
                                {' '}
                                {ticket.config[0].arraypr.form_avaliation !==
                                  null ? (
                                  <p style={{ color: '#888080' }}>
                                    {formshtml(applyTimezoneOnHTML(decryp(
                                      ticket.config[0].arraypr.form_avaliation
                                    )), htmlFormAvaliationValues)}
                                  </p>
                                ) : formavaliation.html ? (
                                  formshtml(decryp(formavaliation.html), htmlFormAvaliationValues)
                                ) : null}
                              </p>
                            </div>
                            {ticket.status == 'In Progress' && (
                              <Button
                                icon="fas fa-save"
                                variant="normal"
                                color="success"
                                label={language.SAVE_FORM}
                                onClick={() => saveFormTech()}
                              />
                            )}
                          </Card>
                        </Grid>
                      ) : null}
                    </div>
                  ) : (
                    <React.Fragment>
                      <span>
                        {' '}
                        {hasTasksPending == false && (
                          <CommonForm
                            fields={[
                              {
                                col: 12,
                                label: language.OBSERVATIONS,
                                name: 'observation',
                                type: 'textEditor',
                              },
                            ]}
                            values={observation}
                            onSubmit={(c) => saveObservation(c)}
                          />
                        )}
                      </span>
                      <Grid container spacing={1} id="block-3" class="space-elements">
                        <_T2
                          index={1}
                          language={language}
                          toggleAlert={toggleAlert}
                          values={ticket}
                          action={action}
                          APIEndpoints={APIEndpoints}
                          item={ticket}
                          field={'problem_identification'}
                          ticket={ticket}
                          answer={answer}
                          params={params}
                          onNotesTicket={onNotesTicket_1}
                          label={language.INITIAL_CAUSE_ANALYSIS}
                        />
                        <_T2
                          index={2}
                          label={language.CORRECTION}
                          language={language}
                          values={ticket}
                          toggleAlert={toggleAlert}
                          answer={answer}
                          params={params}
                          action={action}
                          APIEndpoints={APIEndpoints}
                          field={'analyze'}
                          disabled={true}
                          ticket={ticket}
                          onNotesTicket={onNotesTicket_1}
                        />
                        <_T2
                          index={3}
                          label={language.RESOLUTION}
                          language={language}
                          answer={answer}
                          params={params}
                          action={action}
                          toggleAlert={toggleAlert}
                          APIEndpoints={APIEndpoints}
                          values={ticket}
                          field={'solution_development'}
                          disabled={true}
                          ticket={ticket}
                          onNotesTicket={onNotesTicket_1}
                        />
                        <_T2
                          index={4}
                          label={language.EFFECTIVENESS_ANALYSIS}
                          language={language}
                          answer={answer}
                          params={params}
                          toggleAlert={toggleAlert}
                          values={ticket}
                          action={action}
                          APIEndpoints={APIEndpoints}
                          field={'test'}
                          ticket={ticket}
                          onNotesTicket={onNotesTicket_1}
                        />
                      </Grid>
                    </React.Fragment>
                  )}

                  <div className="mt-5 _table">
                    {params[0].notes_tickets_as_table ? (
                      <div>
                        <h3 className="title-aba mb-4">{language.HISTORIC}</h3>
                        <CommonTable
                          paper={false}
                          mini
                          title={language.HISTORIC}
                          index={'ticket-detail-history'}
                          bar={false}
                          orderColumn={false}
                          col={[
                            {
                              key: 'user',
                              label: language.USER
                            },
                            {
                              key: 'date',
                              label: language.DATE
                            },
                            {
                              key: 'action',
                              label: language.ACTION
                            },
                          ]}
                          data={ticketNotes.map(n => ({
                            user: n.user_name,
                            date: moment(n.dt_cad).format('DD/MM/YYYY HH:mm:ss'),
                            action: (<p dangerouslySetInnerHTML={{ __html: n.data }}></p>)
                          })
                          )}
                        />
                      </div>
                    ) : (

                      <CommonTable
                        paper={false}
                        mini
                        index={'ticket-detail-history'}
                        bar={false}
                        orderColumn={false}
                        col={[
                          {
                            key: 'description',
                            label: (
                              <div className="d-flex align-items-center">
                                <h3
                                  className="title-aba mr-3"
                                  style={{ color: 'var(--success)' }}
                                >
                                  {language.HISTORIC}
                                </h3>
                                <div
                                  style={{ zoom: 0.8, width: 300 }}
                                  zoom={0.8}
                                  className="mb-1"
                                >
                                  <CommonForm
                                    individual
                                    fields={[
                                      {
                                        type: 'search',
                                        startIcon: 'fas fa-search',
                                        name: 'search',
                                        placeholder: language.SEARCH + '...',
                                        className: 'darks',
                                      },
                                    ]}
                                    onChangeField={(f, v) => setSearchHistoric(v)}
                                  />
                                </div>
                              </div>
                            ),
                          },
                        ]}

                        data={[
                          {
                            description: (
                              <p
                                style={{ color: '#888080', fontSize: 16 }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    ticket.notes &&
                                    ticket.notes
                                      .split('<hr/></p>')
                                      .filter(Boolean)
                                      .filter(
                                        (e) =>
                                          e
                                            .toLocaleLowerCase()
                                            .indexOf(
                                              SearchHistoric.toLocaleLowerCase()
                                            ) > -1
                                      )
                                      .join('<hr/></p>'),
                                }}
                              />
                            ),
                          }]}
                      />
                    )}
                  </div>
                  <hr />
                </React.Fragment>
              ) : null}

              {page == 2 ? (
                <div>
                  <h3 className="title-aba mb-4">{language.HISTORIC_CALL}</h3>

                  <Historic
                    action={action}
                    APIEndpoints={APIEndpoints}
                    handleOpenHistory={handleOpenHistory}
                    fk_id_user={ticket.fk_id_user}
                    fk_id_device={ticket.fk_id_device}
                  ></Historic>
                </div>
              ) : null}

              {page == 3 ? (
                <div>
                  <h3 className="title-aba mb-4">{language.HISTORIC_SLA}</h3>
                  <CommonTable
                    paper={false}
                    mini
                    index={'ticket-detail-history'}
                    bar={false}
                    orderColumn={false}
                    col={[
                      {
                        key: 'sh_active',
                        label: language.CMDB.ASSET,
                      },
                      {
                        key: 'sla_name',
                        label: language.SLA_NAME,
                      },
                      {
                        key: 'sh_condition',
                        label: language.CONDITION,
                      },
                      {
                        key: 'dt_cad',
                        label: language.ENTRY_DATE,
                      },
                      {
                        key: 'sh_stopped',
                        label: language.STOP_DATE,
                      },
                      {
                        key: 'sh_next',
                        label: language.EXPIRY_DATE,
                      },
                      {
                        key: 'sh_ended',
                        label: language.COMPLETION_DATE,
                      },
                      {
                        key: 'sh_broken',
                        label: language.SLA_BROKEN_OUT,
                      },
                      {
                        key: 'sh_pause_duration',
                        label: language.PAUSED_TIME,
                      },
                      {
                        key: 'sh_time_active',
                        label: language.ACTIVE_TIME,
                      },
                      {
                        key: 'group_tech_broken_sla',
                        label: language.GROUP_BROKEN,
                      },
                      {
                        key: 'progress',
                        label: language.PROGRESS_
                      },
                      {
                        key: 'action',
                        label: language.ACTION,
                      },
                    ]}
                    data={(sla || []).map((g) => ({
                      sh_active: g.sh_active ? language.YES : language.NOT,
                      sla_name: g.sla_name,
                      sh_condition: typesla(
                        g.sh_condition,
                        ticket.status,
                        language
                      ),
                      dt_cad: moment(g.dt_cad).format('DD/MM/YYYY HH:mm:ss'),
                      sh_stopped: g.sh_stopped
                        ? moment(g.sh_stopped).format('DD/MM/YYYY HH:mm:ss')
                        : '',
                      sh_next: moment(g.sh_next).format('DD/MM/YYYY HH:mm:ss'),
                      sh_ended: g.sh_ended
                        ? moment(g.sh_ended).format('DD/MM/YYYY HH:mm:ss')
                        : '',
                      sh_broken: g.sh_broken ? language.YES : language.NOT,
                      sh_pause_duration: g.sh_pause_duration ? formatDecimalToMinutes(g.sh_pause_duration) : '',
                      sh_time_active: g.sh_time_active ? formatDecimalToMinutes(g.sh_time_active) : '',
                      group_tech_broken_sla: g.group_tech?.name ?? '',
                      progress: <>
                        <div className="d-flex align-items-center" style={{ width: 120 }}>
                          <Progress
                            current={g.progress || 0}
                            background="var(--success)"
                            hiddenText
                            style={{ width: '100%', height: 7 }}
                          />
                          <span className="ml-2">{g.progress || 0}%</span>
                        </div>
                      </>
                      ,
                      action: (
                        <Fragment>
                          {g.has_problem_steps &&
                            <Chip
                              className="mx-2 muit-chip"
                              label={
                                <div className="d-flex align-items-center justify-content-between tw-gap-3">
                                  <Button
                                    size={0.7}
                                    variant="circle"
                                    icon="far fa-eye"
                                    color="primary"
                                    label={language.VIEW}
                                    style={{ fontSize: 13 }}
                                    onClick={() => setOpenSlaSeries(g)}
                                  />
                                </div>
                              }
                            />
                          }
                        </Fragment>
                      ),
                    }))}
                  />
                </div>
              ) : null}

              {page == 4 ? (
                <div>
                  <h3 className="title-aba mb-4">{language.SUB_CALLS}</h3>

                  <HistoricSub
                    action={action}
                    APIEndpoints={APIEndpoints}
                    handleOpenHistory={handleOpenHistory}
                    fk_id_user={ticket.fk_id_user}
                    fk_id_device={ticket.fk_id_device}
                    real_id={ticket.config[0].real_id}
                    paramSub={ticket.config[0].arraypr.sub_tickets}
                  ></HistoricSub>
                </div>
              ) : null}
              {page == 5 && (
                <CIPage
                  action={action}
                  APIEndpoints={APIEndpoints}
                  language={language}
                  ticket={ticket}
                  hasTasksPending={hasTasksPending}
                />
              )}

              {page == 6 && (
                <div>
                  <h3 className="title-aba mb-4">{language.DESIGNATION_HISTORY}</h3>
                  <CommonTable
                    paper={false}
                    mini
                    index={'ticket-detail-history'}
                    bar={false}
                    orderColumn={false}
                    col={[
                      {
                        key: 'id',
                        label: 'ID',
                      },
                      {
                        key: 'type',
                        label: language.TYPE,
                      },
                      {
                        key: 'date',
                        label: language.DATE,
                      },
                      {
                        key: 'name',
                        label: language.NAME,
                      },
                      {
                        key: 'by',
                        label: language.BY,
                      },
                    ]}
                    data={(historyDesignation || []).map((g) => ({
                      id: g.id,
                      type: g.tipo,
                      date: g.data ? moment(g.data).format('DD/MM/YYYY HH:mm:ss') : '',
                      name: g.nome,
                      by: g.por
                    }))}
                  />
                </div>
              )}

              {page == 7 && (
                <RelationshipTicketsPage
                  action={action}
                  APIEndpoints={APIEndpoints}
                  language={language}
                  ticket={ticket}
                  hasTasksPending={hasTasksPending}
                />
              )}

              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <i
                    className="fas fa-eye"
                    style={{ color: 'var(--primary)' }}
                  />
                  <span
                    style={{ fontSize: 15, color: 'var(--primary)' }}
                    className="ml-2"
                  >
                    {language.VISUALIZE}
                  </span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <i
                    className="fas fa-exchange-alt"
                    style={{ color: 'var(--warning)' }}
                  />
                  <span
                    style={{ fontSize: 15, color: 'var(--primary)' }}
                    className="ml-2"
                  >
                    {language.MIGRATE_GP}
                  </span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <i
                    className="fas fa-exchange-alt"
                    style={{ color: 'var(--primary)' }}
                  />
                  <span
                    style={{ fontSize: 15, color: 'var(--primary)' }}
                    className="ml-2"
                  >
                    {language.MIGRATE_GM}
                  </span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <i
                    className="fas fa-trash"
                    style={{ color: 'var(--danger)' }}
                  />
                  <span
                    style={{ fontSize: 15, color: 'var(--primary)' }}
                    className="ml-2"
                  >
                    {language.DELETE}
                  </span>
                </MenuItem>
              </Menu>

              <Dialog
                title={language.WANT_TO_CLOSE_TICKET_WHEN_MIGRATING_TO_OS}
                open={openMigrateOS}
                maxWidth="xs"
                onClose={() => setOpenMigrateOS(false)}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    padding: '10',
                  }}
                >
                  <Button
                    icon="fas fa-times"
                    variant="normal"
                    color="success"
                    label={language.CLOSE_TICKET}
                    size={0.9}
                    zoom={0.9}
                    style={{ fontSize: 14, marginLeft: 5 }}
                    onClick={() =>
                      redirect('/dash/serviceorder/new', {
                        ticket: {
                          ...ticket,
                          sol: ticket.user_solicit[0].name_person,
                          close_ticket: true,
                          fk_id_tech: ticket.config[0].fk_id_tech,
                          company_name: ticket.company[0].name_company_master,
                          contract_name: ticket.config[0].contractname,
                        },
                      })
                    }
                  />
                  <Button
                    icon="fas fa-check"
                    variant="normal"
                    color="danger"
                    label={language.NOT_CLOSE_TICKET}
                    size={0.9}
                    zoom={0.9}
                    style={{ fontSize: 14, marginLeft: 5 }}
                    onClick={() =>
                      redirect('/dash/serviceorder/new', {
                        ticket: {
                          ...ticket,
                          sol: ticket.user_solicit[0].name_person,
                          close_ticket: false,
                          fk_id_tech: ticket.config[0].fk_id_tech,
                          company_name: ticket.company[0].name_company_master,
                          contract_name: ticket.config[0].contractname,
                        },
                      })
                    }
                  />
                </div>
              </Dialog>
              <div id="detailawait">
                <Dialog
                  title={language.ASSOCIATION_TICKETS}
                  open={openAssociate}
                  maxWidth="xs"
                  monitor_scroll
                  onClose={() => setOpenAssociate(false)}
                >
                  <CommonForm
                    values={[]}
                    language={language}
                    fields={formAssociateTicket}
                    onSubmit={(v) => {
                      sendConnectTickets(v);
                      setOpenAssociate(false);
                    }}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </Dialog>
              </div>
              <Dialog
                maxWidth="xs"
                title={language.WAITING}
                modal={false}
                open={modalWaiting}
                onClose={() => {
                  setModalWaiting(false);
                }}
              >
                <div style={{ minHeight: 300 }}>
                  <CommonForm
                    values={values}
                    language={language}
                    onChangeField={(f, v) => {
                      if (f.name == 'id_waiting') {
                        const statusConfig = statusAwaiting.find((status) => status.id_status === v);
                        setFornecedor(statusConfig.select_provider);
                        setStatusConfig(statusConfig);
                        setRegisterTimePauseValue(null);
                      } else if (f.name === 'fk_id_company_provider') {
                        setFornecedor(v);
                      } else if (f.name === 'time_pause_restart') {
                        setRegisterTimePauseValue(v)
                      }
                    }}
                    fields={[
                      {
                        col: 12,
                        name: 'id_waiting',
                        label: language.WAITING,
                        type: 'select',
                        options: (statusAwaiting || []).map((status) => ({
                          value: status.id_status,
                          label: translateFromPtBR(status.status, language),
                        })),
                      },
                      {
                        col: 12,
                        type: 'select',
                        name: 'fk_id_company_provider',
                        label: language.COMPANY,
                        options: companyOptions,
                        visible: statusConfig.select_provider,
                        required: statusConfig.select_provider_required,
                      },
                      {
                        col: 12,
                        type: 'text',
                        name: 'chamado',
                        label: language.CALL,
                        visible: statusConfig.select_provider,
                        required: statusConfig.select_provider_required,
                      },
                      {
                        col: 6,
                        type: 'date',
                        name: 'time_pause_restart',
                        label: 'Data limite',
                        visible: statusConfig.time_pause_restart,
                        required: statusConfig.time_pause_restart_required ||
                          statusConfig.select_provider_required,
                      },
                      {
                        col: 6,
                        type: 'time',
                        name: 'time_pause_restart',
                        label: 'Hora limite',
                        visible: statusConfig.time_pause_restart,
                        required: statusConfig.time_pause_restart_required,
                      },
                      {
                        col: 12,
                        type: 'text',
                        name: 'reason',
                        label: language.REASON,
                        visible: params[0].enable_change_status_reason,
                        required: params[0].enable_change_status_reason || statusConfig.reason_required,
                      }
                    ]}
                    onSubmit={(v) => {
                      const dataHoraAtual = new Date();
                      if (statusConfig.time_pause_restart &&
                        (dataHoraAtual > v.time_pause_restart)) {
                        store.toggleAlert(true, language.LIMIT_DATE_TIME_PAUSE_SLA, 'error');
                      } else {
                        if (ticket.status == 'Open') {
                          onChangeStatus_Tab(
                            ticket.id_tickets,
                            { action: 'resolve' },
                            ticket
                          );
                        }
                        handleAwaitingTicket(v, ticket, _, registerTimePauseValue);
                        setModalWaiting(false);
                      }
                    }}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </div>
              </Dialog>
              <Dialog
                maxWidth="xs"
                title={language.OBSERVER}
                monitor_scroll
                modal={false}
                open={modalOb}
                onClose={() => {
                  setmodalOb(false);
                }}
              >
                <div>
                  <CommonForm
                    values={values}
                    language={language}
                    fields={[
                      {
                        name: 'id_tickets',
                        label: language.TICKET,
                        type: 'text',
                        visible: false,
                        disabled: true,
                      },
                      {
                        span: 4,
                        type: 'autocomplete',
                        name: 'fk_id_tech_new',
                        textlabel: language.TECH,
                        className: 'tech-group-ticket',
                        required: true,
                        method: 'POST',
                        route: `${APIEndpoints.USER}/filter`,
                        routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                        filterAll: { all_fk_id_company: ticket.fk_id_company },
                        fieldquery: 'search',
                        textinit:
                          ticket.tech_start != '' &&
                            ticket.tech_start != undefined
                            ? ticket.tech_start
                            : ' Observadores',
                        fieldvaluedb: 'id_user',
                        fieldlabeldb: 'name',
                        idinit: '',
                        routeAll: `${APIEndpoints.USER}/filter/all?`,
                        detail: true,
                        isMulti: true,
                        methodGetAll: 'POST',
                      },
                      {
                        name: 'fk_id_tech_new_email',
                        label: 'Email',
                        type: 'text',
                        span: 3,
                        visible: params[0].open_email_request_cc

                      },
                    ]}
                    onSubmit={(v) => {
                      relationObserver(v.fk_id_tech_new, ticket.id_tickets, false, v.fk_id_tech_new_email);
                      setmodalOb(false);
                    }}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </div>
              </Dialog>

              <Dialog
                maxWidth="xs"
                title={language.CHANGE_TECHNICIAN}
                monitor_scroll
                modal={false}
                open={modaltech}
                onClose={() => {
                  setModaltech(false);
                }}
              >
                <div>
                  <CommonForm
                    values={values}
                    language={language}
                    fields={[
                      {
                        name: 'id_tickets',
                        label: language.TICKET,
                        type: 'text',
                        visible: false,
                        disabled: true,
                      },
                      {
                        span: 4,
                        type: 'autocomplete',
                        name: 'fk_id_tech_new',
                        textlabel: language.TECH,
                        className: 'tech-group-ticket',
                        required: true,
                        method: 'POST',
                        route: `${APIEndpoints.USER}/filter`,
                        routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                        filterAll: {
                          fk_id_group_users: ticket.fk_id_group_tech,
                        },
                        fieldquery: 'search',
                        textinit:
                          ticket.tech_start != '' &&
                            ticket.tech_start != undefined
                            ? ticket.tech_start
                            : ' ',
                        fieldvaluedb: 'id_user',
                        fieldlabeldb: 'name',
                        idinit: '',
                        routeAll: `${APIEndpoints.USER}/filter/all?`,
                        detail: true,
                        methodGetAll: 'POST',
                      },
                      {
                        name: 'justify',
                        label: language.REASON,
                        type: 'textEditor',
                        required: params[0].obligation_reason_change_tech_group_tech == true ? true : false,
                      },
                    ]}
                    onSubmit={(v) => {
                      updateTicketTech(v, ticket);
                      setModaltech(false);
                    }}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </div>
              </Dialog>

              <Dialog
                maxWidth="xs"
                title={language.ADD_TO_SPRINT}
                monitor_scroll
                modal={false}
                open={sprintModal}
                onClose={() => {
                  setModalSprint(false);
                }}
              >
                <div>
                  <CommonForm
                    language={language}
                    fields={[
                      {
                        name: 'id_sprint',
                        label: language.SPRINT,
                        type: 'select',
                        options: listSprint,
                        required: true,
                      },
                    ]}
                    onSubmit={(v) => {
                      addSprint(v);
                      setModalSprint(false);
                    }}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </div>
              </Dialog>

              {opentask == true ? (
                <Dialog
                  PaperProps={{
                    style: {
                      height: '70%',
                    },
                  }}
                  open={true}
                  title={language.CREATE_TASK_FLOW}
                  maxWidth="md"
                  onClose={() => {
                    setReloadTicket(!reloadTicket);
                    setopentask(false);
                  }}
                >
                  <TaskFormTarefa
                    id_catalog_task={ticket.fk_id_catalog_task}
                    id_tickets={ticket.id_tickets}
                    language={language}
                    openTemaplateTask={[]}
                    saveRelForm={saveRelForm}
                    setReloadTicket={setReloadTicket}
                    reloadTicket={reloadTicket}
                    task_resp={true}
                  ></TaskFormTarefa>
                </Dialog>
              ) : null}

              <Dialog
                maxWidth="lg"
                PaperProps={{
                  style: {
                    height: '70%',
                  },
                }}
                title={language.CHANGE_TECH_GROUP}
                modal={false}
                open={modalgroup}
                onClose={() => {
                  setModalgroup(false);
                }}
              >
                <div>
                  <CommonForm
                    values={values}
                    language={language}
                    fields={[
                      {
                        name: 'id_tickets',
                        label: language.TICKET,
                        type: 'text',
                        disabled: true,
                        visible: false,
                      },
                      {
                        span: 4,
                        type: 'autocomplete',
                        name: 'fk_id_group_tech',
                        textlabel: language.TECH_GROUP,
                        className: 'tech-group-ticket',
                        required: true,
                        method: 'POST',
                        route: `${APIEndpoints.GROUP}/filter`,
                        fieldquery: 'search',
                        textinit:
                          !!ticket.config[0].grouptech
                            ? ticket.config[0].grouptech
                            : language.SELECT,
                        fieldvaluedb: 'id_group_users',
                        fieldlabeldb: 'name',
                        idinit: values.fk_id_group_tech,
                        routeAll: `${APIEndpoints.GROUP}/all?`,
                        detail: true,
                        methodGetAll: 'POST',
                        routeGetCount: `${APIEndpoints.GROUP}/count`,
                        filterAll: {
                          subtickets:
                            ticket.config[0].real_id.indexOf('.') > -1
                              ? true
                              : false,
                          mail_default: ticket.mail_default,
                          id_company_mail_default: ticket.fk_id_company
                        },
                      },
                      {
                        name: 'justify',
                        label: language.REASON,
                        type: 'textEditor',
                        required: params[0].obligation_reason_change_tech_group_tech == true ? true : false,
                      },
                    ]}
                    onSubmit={(v) => {
                      updateTicketTech(v, ticket);
                      setModalgroup(false);
                    }}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </div>
              </Dialog>

              <Dialog
                maxWidth="lg"
                PaperProps={{
                  style: {
                    height: '70%',
                  },
                }}
                title={language.CHANGE_LOCATION}
                modal={false}
                open={modalLocation}
                onClose={() => {
                  setModalLocation(false);
                }}
              >
                <div>
                  <CommonForm
                    values={{ fk_id_location: ticket.fk_id_location ? ticket.fk_id_location : null }}
                    language={language}
                    fields={formLocationTicket}
                    onSubmit={(v) => {
                      handleSubmitLocation(v, ticket);
                    }}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </div>
              </Dialog>

              <Dialog
                maxWidth="lg"
                PaperProps={{
                  style: {
                    height: '70%',
                  },
                }}
                title={language.EDIT_CRISIS_CONFIRMATION}
                modal={false}
                open={modalConfirmCrisis}
                onClose={() => {
                  setModalConfirmCrisis(false);
                }}
              >
                <div>
                  <CommonForm
                    values={{ confirm_crisis: !!ticket.confirm_crisis }}
                    language={language}
                    fields={[
                      {
                        span: 12,
                        name: 'confirm_crisis',
                        label: language.CONFIRM_CRISES,
                        type: 'select',
                        options: [
                          { value: true, label: language.YES },
                          { value: false, label: language.NO },
                        ],
                      },
                    ]}
                    onSubmit={(values)=> handleSubmitConfirmCrisis(values, ticket)}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </div>
              </Dialog>

              {subTicket == true ? (
                <Dialog
                  maxWidth="xs"
                  title={language.CREATE_SUB_CALL}
                  modal={false}
                  monitor_scroll
                  open={subTicket}
                  onClose={() => {
                    setSubTicket(false);
                  }}
                >
                  <div>
                    <CommonForm
                      values={values}
                      language={language}
                      fields={[
                        {
                          span: 4,
                          type: 'autocomplete',
                          name: 'fk_id_group_tech',
                          textlabel: language.TECH_GROUP,
                          className: 'tech-group-ticket',
                          required: true,
                          method: 'POST',
                          route: `${APIEndpoints.GROUP}/filter`,
                          fieldquery: 'search',
                          textinit: '',
                          fieldvaluedb: 'id_group_users',
                          fieldlabeldb: 'name',
                          idinit: '',
                          routeAll: `${APIEndpoints.GROUP}/all?`,
                          detail: true,
                          methodGetAll: 'POST',
                          routeGetCount: `${APIEndpoints.GROUP}/count`,
                          filterAll: {
                            subtickets: true,
                            notid: ticket.fk_id_group_tech,
                          },
                          filter: {
                            subtickets: true,
                            notid: ticket.fk_id_group_tech,
                          },
                        },

                        {
                          name: 'id_tickets',
                          label: language.TICKET,
                          type: 'text',
                          disabled: true,
                          visible: false,
                        },

                        {
                          name: 'justify',
                          label: language.REASON,
                          type: 'textEditor',
                          required: true,
                        },
                      ]}
                      onSubmit={(v) => {
                        saveSubTicket(v);
                      }}
                      button={{
                        md: 3,
                        offset: { md: 9 },
                        label: language.SAVE,
                        primary: true,
                        style: { margin: 5 },
                      }}
                    />
                  </div>
                </Dialog>
              ) : null}

              <Dialog
                open={solutionModal}
                fullScreen
                autoScrollBodyContent={true}
                maxWidth="md"
                onClose={() => setSolutionModal(false)}
              >
                {' '}
                <div style={{ width: '1200px' }}></div>
                <SolutionContainerTicket
                  language={language}
                  categories={categories}
                  solutions={solutions}
                  openticket={true}
                  type_solution={type_solution}
                  subcategory={ticket.fk_id_catalog_service}
                  company={ticket.fk_id_company}
                ></SolutionContainerTicket>
              </Dialog>

              <Dialog
                open={modalResolution}
                onClose={() => setModalResolution(false)}
                title={
                  ticket.type_tickets === 'Incident'
                    ? language.RESOLUTION_DETAIL
                    : language.ROOT_CAUSE_RESOLUTION
                }
                maxWidth="lg"
                monitor_scroll
              >
                <div style={{ width: '800px' }}></div>

                <CommonForm
                  language={language}
                  button={{
                    md: 3,
                    offset: { md: 9 },
                    label: language.SAVE,
                    primary: true,
                    style: { margin: 5 },
                  }}
                  onSubmit={(_values) => {
                    if (ticket.config[0].stop_ticket > 0) {
                      _values.aguardando = true;
                    }
                    onResolutionTab(ticket, _values);
                    setModalResolution(false);
                  }}
                  values={{ time_resolution: '00:00:00' }}
                  fields={[
                    {
                      col: 6,
                      name: 'fk_id_type_solution',
                      label: language.TYPE,
                      type: 'select',
                      options: [
                        { value: 1, label: language.PERMANENT },
                        { value: 2, label: language.NOT_PERMANENT },
                        { value: 3, label: language.PARTIAL },
                      ],
                      required: true,
                    },
                    {
                      col: 6,
                      name: 'cause',
                      label: language.CAUSE,
                      type: 'select',
                      options: [
                        { value: 1, label: language.PROCESS },
                        { value: 2, label: language.EQUIPMENT },
                        { value: 3, label: language.TRAINING },
                      ],
                      required: true,
                      visible:
                        general_parameters.cause_problem == true ? true : false,
                    },
                  ]}
                />
              </Dialog>

              {categorize == true ? (
                <DialogFormEdit
                  store={store}
                  onFormSubmitTab={(b) => {
                    if (openMigrateCrisis) {
                      b.fk_id_user = ticket.fk_id_user;
                      delete b.id_tickets;
                    }
                    onFormSubmitTab(b);
                    setCategorize(false);
                    if (openMigrateCrisis) {
                      setOpenMigrateCrisis(false);
                    }
                  }}
                  open={categorize}
                  APIEndpoints={APIEndpoints}
                  action={action}
                  htmlValues={htmlValues}
                  values={ticket}
                  crisisOption={openMigrateCrisis}
                  onUpdateTemplateHtml={onUpdateTemplateHtml}
                  onClose={() => setCategorize(false)}
                />
              ) : null}

              {categorizeWithSub == true ? (
                <DialogFormEdit
                  store={store}
                  onFormSubmitTab={(b) => {
                    onFormSubmitTab(b);
                    setCategorizeWithSub(false);
                    setSubTicketCreated([]);
                    setPage(4);
                  }}
                  subTicketCreated={subTicketCreated}
                  open={categorizeWithSub}
                  values={{ ...ticket, ...{ sub: true } }}
                  APIEndpoints={APIEndpoints}
                  action={action}
                  onClose={() => setCategorizeWithSub(false)}
                />
              ) : null}
              <Dialog
                open={openReopen}
                modal={true}
                title={language.REOPEN_TICKET}
                onClose={openReopen ? () => setOpenReopen(false) : null}
              >
                <CommonForm
                  values={values}
                  language={language}
                  fields={[
                    {
                      span: 2,
                      name: 'justify',
                      label: language.JUSTIFICATION,
                      type: 'textEditor',
                      require: true,
                    },
                  ]}
                  onSubmit={(v) => {
                    if (v.justify == undefined || v.justify == '') {
                      store.toggleAlert(true, language.INFORM_REASON, 'error');
                    } else {
                      onReopenSave(v, ticket);
                      setOpenReopen(false);
                    }
                  }}
                  button={{
                    md: 3,
                    offset: { md: 9 },
                    label: language.SAVE,
                    primary: true,
                    style: { margin: 5 },
                  }}
                />
              </Dialog>
              <Dialog
                open={cancelModal}
                modal={true}
                title={language.CANCEL_CALL}
              >
                <CommonForm
                  values={values_}
                  language={language}
                  fields={[
                    {
                      span: 2,
                      name: 'justify',
                      label: language.JUSTIFICATION,
                      type: 'textEditor',
                      require: true,
                    },
                  ]}
                  onSubmit={(v) => {
                    if (v.justify == undefined || v.justify == '') {
                      store.toggleAlert(true, language.INFORM_REASON, 'error');
                    } else {
                      onCancelTicket(v, ticket);
                      setcancelModal(false);
                    }
                  }}
                  button={{
                    md: 3,
                    offset: { md: 9 },
                    label: language.SAVE,
                    primary: true,
                    style: { margin: 5 },
                  }}
                />
              </Dialog>

              <Dialog
                title={language.HISTORIC_SLA_SERIES}
                open={openSlaSeries}
                maxWidth="xs"
                onClose={() => setOpenSlaSeries(null)}
              >
                <div
                  style={{
                    width: '100%',
                    //display: 'inline-flex',
                    //justifyContent: 'center',
                    padding: '10',
                  }}
                >
                  <h3 className="title-aba mb-4">{language.HISTORIC_SLA_SERIES}</h3>
                  <CommonTable
                    mini
                    bar={false}
                    paper={false}
                    index={'ticket-detail-sla-history-series'}
                    orderColumn={false}
                    pagination={false}
                    col={[
                      {
                        key: 'sla_name',
                        label: language.SLA_NAME,
                      },
                      {
                        key: 'sla_condition',
                        label: language.CONDITION,
                      },
                      {
                        key: 'sla_created_on',
                        label: language.ENTRY_DATE,
                      },
                      {
                        key: 'sla_pause_on',
                        label: language.STOP_DATE,
                      },
                      {
                        key: 'sla_due_date_on',
                        label: language.EXPIRY_DATE,
                      },
                      {
                        key: 'sla_complete_on',
                        label: language.COMPLETION_DATE,
                      },
                      {
                        key: 'sla_broken',
                        label: language.SLA_BROKEN_OUT,
                      },
                      {
                        key: 'sla_duration',
                        label: language.SLA_TIME,
                      },
                      {
                        key: 'sla_pause_duration',
                        label: language.PAUSED_TIME,
                      },
                      {
                        key: 'progress',
                        label: language.PROGRESS_,
                      }
                    ]}
                    data={(openSlaSeries?.problem_steps?.sort((a, b) => a.step_order - b.step_order) || []).map((item) =>
                    ({
                      sla_name: {
                        'STEP_ROOT_CAUSE': language.SLA_PROBLEM_STEP_ROOT_CAUSE,
                        'STEP_CORRECTION': language.SLA_PROBLEM_STEP_CORRECTION,
                        'STEP_RESOLUTION': language.SLA_PROBLEM_STEP_RESOLUTION,
                        'STEP_ANALYSIS': language.SLA_PROBLEM_STEP_ANALYSIS,
                      }[item.step_name],
                      sla_condition: item.step_order == 4 ?
                        (openSlaSeries?.problem_steps?.some(step => !step.sla_complete_on && step.step_order != 4) ?
                          typesla(item.sla_condition, '', language) :
                          typesla(item.sla_condition, ticket.status, language)
                        ) :
                        typesla(item.sla_condition, ticket.status, language),
                      sla_created_on: moment(item.sla_created_on).format('DD/MM/YYYY HH:mm:ss'),
                      sla_pause_on: item.sla_pause_on ? moment(item.sla_pause_on).format('DD/MM/YYYY HH:mm:ss') : '',
                      sla_due_date_on: moment(item.sla_due_date_on).format('DD/MM/YYYY HH:mm:ss'),
                      sla_complete_on: item.sla_complete_on ? moment(item.sla_complete_on).format('DD/MM/YYYY HH:mm:ss') : '',
                      sla_broken: item.sla_broken ? language.YES : language.NOT,
                      sla_duration: item.sla_complete_on ? formatDecimalToMinutes(item.sla_duration) : '',
                      sla_pause_duration:
                        (item.step_order != 4 ||
                          !(openSlaSeries?.problem_steps?.some(step => !step.sla_complete_on && step.step_order != 4)))
                          && item.sla_pause_duration
                          ? formatDecimalToMinutes(item.sla_pause_duration) : '',
                      progress: <>
                          <div className="d-flex align-items-center" style={{ width: 120 }}>
                            <Progress
                              current={item.progress || 0}
                              background="var(--success)"
                              hiddenText
                              style={{ width: '100%', height: 7 }}
                            />
                            <span className="ml-2">{item.progress || 0}%</span>
                          </div>
                        </>
                    })
                    )}
                  />
                </div>
              </Dialog>
            </Card>
          </div>
        ) : null}
        <Dialog
          title={''}
          open={openHistory}
          maxWidth="xl"
          onClose={handleCloseHistory}
        >
          <TicketManagement
            detail={true}
            id_ticket_detail={ticketHistory}
            isHistory
          ></TicketManagement>
        </Dialog>
      </PageLayout>
    );
  }
);
