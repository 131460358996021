import React, { useEffect, useState } from 'react'
import CommonForm from '../../common/CommonForm2'
import Constants from '../../data/Constants'
import FormRules from './FormRules'
import './index.css'
import Store from '@data/Store'
import { translateFromPtBR } from '@common/languages/Dictionary';
import Actions from '@data/Actions';

const action = new Actions();
const { language } = Store

const arraySla = ['start', 'pause', 'restart']

const targetOpt = {
  response: language.ANSWER,
  resolution: language.RESOLUTION,
  ola: language.OLA
}

const tabConditions = {
  response: [
    language.TYPE_SLA_STATUS_START,
    language.TYPE_SLA_STATUS_PAUSE,
    language.TYPE_SLA_STATUS_RESUMPTION
  ],
  resolution: [
    language.TYPE_SLA_STATUS_START,
    language.TYPE_SLA_STATUS_PAUSE,
    language.TYPE_SLA_STATUS_RESUMPTION
  ],
  ola: [
    language.TYPE_SLA_STATUS_START,
    language.TYPE_SLA_STATUS_PAUSE,
    language.TYPE_SLA_STATUS_RESUMPTION
  ]
}

const slaFixedOpt = {
  true: language.YES,
  false: language.NO
}

const convertHour = (min) => {
  const hours = Math.floor(min / 60);
  min = min % 60;
  const textHour = `0000${hours}`.slice(-4);
  const textMin = `00${min}`.slice(-2);
  return `${textHour}:${textMin}`;
};

const index = (props) => {
  const [tab, setTab] = useState(0);
  const [value, setValue] = useState([]);
  const [works, setWorks] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [countries, setCountries] = useState([]);
  const [slaProblemStepsEnabled, setSlaProblemStepsEnabled] = useState(false);

  useEffect(() => {
    if (props.value) {
      let newValue = {
        ...props.value,
        sla_duration: convertHour(props.value.sla_duration),
        sla_fixed_start: slaFixedOpt[props.value.sla_fixed_start],
        sla_problem_steps_enabled_label: slaFixedOpt[!!props.value?.sla_problem_steps_enabled],
      };
      if (props.value?.sla_problem_steps_enabled && props.value?.problem_steps) {

        const getSlaDurationByKey = (items, key) => items.find(item => item.step_name == key).step_sla_duration;

        newValue.sla_problem_step_root_cause = convertHour(getSlaDurationByKey(props.value.problem_steps, "STEP_ROOT_CAUSE"));
        newValue.sla_problem_step_correction = convertHour(getSlaDurationByKey(props.value.problem_steps, "STEP_CORRECTION"));
        newValue.sla_problem_step_resolution = convertHour(getSlaDurationByKey(props.value.problem_steps, "STEP_RESOLUTION"));
        newValue.sla_problem_step_analysis = convertHour(getSlaDurationByKey(props.value.problem_steps, "STEP_ANALYSIS"));
      }
      setValue(newValue);
    }

    action
      .execute('get', `${Constants.APIEndpoints.SLA_WORK}/workday`)
      .then((res) => {
        setWorks(res.data);
      });

    action
      .execute('get', `${Constants.APIEndpoints.COUNTRY}`)
      .then((res) => {
        setCountries(res.data);
      });
  }, [props.value]);

  useEffect(() => {
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, 1000);
  }, [tab]);

  const fields = [
    {
      col: 6,
      name: 'sla_name',
      label: language.DESCRIPTION,
      type: 'text',
      disabled: true
    },
    {
      col: 6,
      name: 'sla_target',
      label: language.TARGET,
      type: 'select',
      options: Object.keys(targetOpt).map((t) => ({
        label: targetOpt[t],
        value: t,
      })),
      disabled: true,
    },
    {
      col: 6,
      name: 'sla_duration',
      label: language.SLA_TIME,
      type: 'text',
      format: '####:##',
      disabled: true,
    },
    {
      col: 6,
      name: 'fk_id_workday',
      label: language.CALENDAR,
      type: 'select',
      options: works.map((item) => ({
        value: item.id_workday,
        label: item.wk_name,
      })),
      disabled: true,
    },
    {
      col: 6,
      name: 'fk_id_country',
      label: language.COUNTRY,
      type: 'select',
      options: countries.map((item) => ({
        value: item.id_country,
        label: translateFromPtBR(item.name, language)
      })),
      disabled: true
    },
    {
      col: 6,
      type: 'autocomplete',
      name: 'fk_id_change',
      label: `${language.CHANGE}`,
      textlabel: `${language.CHANGE}`,
      method: 'POST',
      route: `${Constants.APIEndpoints.CHANGE}/getAllChanges?`,
      textinit: value.reason || '',
      fieldquery: 'search',
      fieldvaluedb: 'id_change',
      fieldlabeldb: 'reason',
      routeAll: `${Constants.APIEndpoints.CHANGE}/getAllChanges?`,
      methodGetAll: 'POST',
      detail: true,
      loadOnFocus: true,
      disabled: true,
      showId: true,
      idFieldToShow: 'id_change',
    },

  ];

  if (value.sla_target === 'resolution') {
    fields.push({
      col: 3,
      name: 'sla_fixed_start',
      label: language.SLA_FIXED,
      type: 'text',
      disabled: true,
    });

    fields.push({
      col: 3,
      name: 'sla_problem_steps_enabled_label',
      label: language.SLA_PROBLEM_STEPS,
      type: 'text',
      disabled: true,
    });

    if (value.sla_problem_steps_enabled) {
      fields.push({
        col: 3,
        name: 'sla_problem_step_root_cause',
        label: language.SLA_PROBLEM_STEP_ROOT_CAUSE,
        type: 'text',
        format: '####:##',
        disabled: true,
      });

      fields.push({
        col: 3,
        name: 'sla_problem_step_correction',
        label: language.SLA_PROBLEM_STEP_CORRECTION,
        type: 'text',
        format: '####:##',
        disabled: true,
      });

      fields.push({
        col: 3,
        name: 'sla_problem_step_resolution',
        label: language.SLA_PROBLEM_STEP_RESOLUTION,
        type: 'text',
        format: '####:##',
        disabled: true,
      });

      fields.push({
        col: 3,
        name: 'sla_problem_step_analysis',
        label: language.SLA_PROBLEM_STEP_ANALYSIS,
        type: 'text',
        format: '####:##',
        disabled: true,
      });
    }
  }

  return (
    <>
      <CommonForm values={value} fields={fields} />
      {value && value.sla_target ? (
        <div id="slaform">
          <div className="tabs">
            <div className="tabcontent">
              {tabConditions[value.sla_target].map((item, index) => (
                <span
                  onClick={() => setTab(index)}
                  className="abacontainer"
                  style={{
                    padding: '10px',
                    borderRadius: '13px 13px 0px 0px',
                    minWidth: '130px',
                    textAlign: 'center',
                    margin: '0px 2px',
                    color: 'white',
                    cursor: 'pointer',
                    background:
                      index == tab
                        ? '#f0b002'
                        : 'transparent linear-gradient(89deg,#01c2b3,#019fc2)',
                  }}
                >
                  {item}
                </span>
              ))}
            </div>
          </div>
          <div
            class="tabbody"
            style={{
              width: '100%',
              borderColor: 'var(--success)',
              border: '1px solid var(--success)',
              borderRadius: '0px 10px 10px 10px',
              padding: '4px',
              minHeight: '50vh',
            }}
          >
            {refresh && (
              <FormRules
                tab={tab}
                type={arraySla[tab]}
                value={props.value}
                language={language}
                loadSla={props.loadSla}
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default index;
